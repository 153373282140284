export enum benefits {
    master_1 = 'Comprá en 12 cuotas en Uruguay',
    master_2 = 'Comprá en 3 cuotas en el exterior',
    master_3 = 'Gratis por un año',
    master_4 = 'Realizá aviso de viaje',

    oca_1 = 'Seguro de viaje incluido',
    oca_2 = '10 cuotas sin recargo',
    oca_3 = 'Gratis por un año',
    oca_4 = 'Realizá aviso de viaje',
}