import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from 'src/redirect-guard/redirect-guard';
import { LandingComponent } from '../app/pages/landing/landing.component';

const routes: Routes = [
  { path: '', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://oca.uy/tarjeta-de-credito/'} },
  { path: 'landing', canActivate: [RedirectGuard], component: RedirectGuard, data: { externalUrl: 'https://oca.uy/tarjeta-de-credito/'} },
  {
    path: 'solicitud',
    loadChildren: () =>
      import('../solicitud-online/solicitud-online.module').then(
        (m) => m.SolicitudOnlineModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
