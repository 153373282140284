<div class="step content_step step-active in">
  <h1 class="section-step__title animate">¿Cuál es tu estado civil?</h1>
  <form
    id="civilStatus-data"
    class="form__container"
    [formGroup]="civilStatusData"
    (ngSubmit)="saveCivilStatus(civilStatusData.value)"
  >
    <div class="row">
      <div class="col-lg-8">
        <div class="row no-gutters">
          <div class="col-lg-6" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="estado_soltero"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(civilStatus.value == userCivilStatusType.Soltero) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Soltero/a</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="estado_soltero"
                  name="civilStatus"
                  [value]="userCivilStatusType.Soltero"
                  formControlName="civilStatus"
                  (click)="hideDniSpouse()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="estado_separado"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(civilStatus.value == userCivilStatusType.Separado) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Separado/a</span>
                <input
                  type="radio"
                  (click)="hideDniSpouse()"
                  class="button-radio__input"
                  id="estado_separado"
                  name="civilStatus"
                  [value]="userCivilStatusType.Separado"
                  formControlName="civilStatus"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0">
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="estado_casado"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(f.civilStatus.value == userCivilStatusType.Casado) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Casado/a</span>
                <input
                  type="radio"
                  (click)="showDniSpouse()"
                  class="button-radio__input"
                  id="estado_casado"
                  name="civilStatus"
                  [value]="userCivilStatusType.Casado"
                  formControlName="civilStatus"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="estado_divorciado"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(f.civilStatus.value == userCivilStatusType.Divorciado) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Divorciado/a</span>
                <input
                  type="radio"
                  (click)="hideDniSpouse()"
                  class="button-radio__input"
                  id="estado_divorciado"
                  name="civilStatus"
                  [value]="userCivilStatusType.Divorciado"
                  formControlName="civilStatus"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="estado_concubino"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(f.civilStatus.value == userCivilStatusType.Concubino) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Concubino/a</span>
                <input
                  type="radio"
                  (click)="showDniSpouse()"
                  class="button-radio__input"
                  id="estado_concubino"
                  name="civilStatus"
                  [value]="userCivilStatusType.Concubino"
                  formControlName="civilStatus"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                id="datospersona_estadocivil"
                for="estado_viudo"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(civilStatus.value == userCivilStatusType.Viudo) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Viudo/a</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="estado_viudo"
                  name="civilStatus"
                  [value]="userCivilStatusType.Viudo"
                  formControlName="civilStatus"
                  (click)="hideDniSpouse()"
                />
                <span class="button-radio__circle"></span>
              </label>
              <div *ngIf="civilStatus.invalid" class="form-error">
                {{ textErrorEstadoCivil }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="dnispouse">
      <div class="col-md-6 col-xl-5 position-static">
        <!-- Opción Extra de Casado (remove 'd-none') -->
        <div id="box_datospersona_numerodocumentoconyuge" class="mt-5">
          <h1 class="section-step__title animate">
            ¿Cuál es el número de C.I. de tu cónyuge?
          </h1>

          <div class="form-group animate" style="transition-delay: 0.15s">
            <oca-form-input
              formControlName="dniSpouse"
              delay="0.20s"
              type="text"
              [error]="error"
              [maxlength]="'11'"
              [id]="'ci'"
              required
            >
              <oca-placeholder>Número de documento</oca-placeholder>
              <oca-error *ngIf="dniSpouse.invalid || (this.DocumentNumberValidation(dniSpouse.value) == false)">{{ textDNIerror }}</oca-error>
            </oca-form-input>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xl-5 position-static">
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </div>
    </div>
  </form>
</div>
