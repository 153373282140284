import { Directive, forwardRef } from '@angular/core';
import { Formatter } from '../formatters/formatter';

@Directive({
  selector: 'app-monthly-earning, [appMonthlyEarning]',
  providers: [
    {
      provide: Formatter,
      useExisting: forwardRef(() => MonthlyEarningDirective),
      multi: true,
    },
  ],
})
export class MonthlyEarningDirective implements Formatter {
  constructor() {}

  value: any;
  oldValue: any = 0;

  format(numWithOutPoint: any): string {
    let num = numWithOutPoint.toString().replaceAll('.', '');
    if (!num || num == 'NaN') return '-';
    if (num == 'Infinity') return '&#x221e;';
    num = num.toString().replace(/\$|\,/g, '');
    if (isNaN(num)) num = '0';
    num = Math.floor(num * 100 + 0.50000000001);
    let cents: any = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10) cents = '0' + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        '.' +
        num.substring(num.length - (4 * i + 3));
    return num;
  }
}
