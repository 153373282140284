<div
  class="step content_step step-active in"
  data-step="2"
  data-stepname="TipoCedula"
  data-stephash="TipoCedula"
  data-transactionstep="3"
  data-percent="20"
>
  <input type="hidden" id="datospersona_tipoci" name="datospersona_tipoci" />

  <h1 class="section-step__title animate">
    Bien hecho {{ nameLastname }}<span class="datospersona_nombre"></span>,
    <br />
    ahora necesitamos tu número de
    <br />
    cédula y tipo de documento.
  </h1>

  <div class="row">
    <div class="col-md-6 col-xl-5 position-static">
      <!-- add 'focused'when selected -->
      <form
        id="idCardType-data"
        class="form__container"
        [formGroup]="cardTypeData"
        (ngSubmit)="saveCardType(cardTypeData.value)"
      >
        <oca-form-input formControlName="dni" delay="0.15s" type="text" [error]="error" [maxlength]="'11'" [id]="'ci'">
          <oca-placeholder>Número de documento</oca-placeholder>
          <oca-error *ngIf="dni.invalid || (this.DocumentNumberValidation(dni.value) == false)">{{ textDNIerror }}</oca-error>
        </oca-form-input>

        <!-- 'focused' -->
        <div class="form-group animate" style="transition-delay: 0.25s">
          <!-- Add 'is-invalid' to show error -->
          <label for="datospersona_tipoci_tradicional" class="button-radio" [ngClass]="(f.identificationCardType.value == userIdentificationCardType.Tradicional || firstTime == true) ? 'focused' : 'noFocused'">
            <div class="button-radio__img button-radio__img--ci">
              <img
                class="doc__image"
                src="assets/images/ci-tradicional.png"
                alt=""
              />
            </div>
            <span class="button-radio__text">C.I. tradicional</span>
            <input
              type="radio"
              class="button-radio__input"
              id="cardType_traditional"
              name="identificationCardType"
              [value]="userIdentificationCardType.Tradicional"
              checked
              formControlName="identificationCardType"
            />
            <span class="button-radio__circle" (click)="clickCard(userIdentificationCardType.Tradicional)"></span>
          </label>
        </div>

        <div class="form-group animate" style="transition-delay: 0.25s" (click)="notFirstTime($event)">
          <label for="datospersona_tipoci_chip" class="button-radio" [ngClass]="(f.identificationCardType.value == userIdentificationCardType.ConChip) ? 'focused' : 'noFocused'">
            <div class="button-radio__img button-radio__img--ci" >
              <img class="doc__image" src="assets/images/ci-chip.png" alt="" />
            </div>
            <span class="button-radio__text">C.I. con chip</span>
            <input
              type="radio"
              class="button-radio__input"
              id="cardType_chip"
              name="identificationCardType"
              [value]="userIdentificationCardType.ConChip"
              formControlName="identificationCardType"
            />
            <span class="button-radio__circle" (click)="clickCard(userIdentificationCardType.ConChip)"></span>
          </label>
          <div *ngIf="f.identificationCardType.invalid" class="form-error">
              {{ textError }}
          </div>
        </div>
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </form>
    </div>
  </div>
</div>
