import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.css'],
})
export class FormButtonComponent implements OnInit {
  @Input() btnDisabled!: boolean;
  @Output() eventToSend = new EventEmitter();
  buttonName: string = 'Siguiente';
  sendRequest: boolean = false;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/confirmData') this.buttonName = 'Finalizar';
      }
    });
  }

  ngOnInit(): void {}

  send() {
    this.eventToSend.emit();
  }
}
