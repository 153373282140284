<header id="header_main" class="header header--show header--in">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <img class="header__logo" src="assets/images/logo.svg" alt="OCA" />
      </div>
    </div>
  </div>
</header>
<section
  id="section_finish"
  class="section-step section-step--finish content_step_12 section-step-active"
  data-stepname="Exito"
  data-stephash="Exito"
  data-transactionstep="11"
>
  <div class="container section-step__container">
    <div class="row">
      <div class="col-lg-8 order-2 order-lg-1 mt-2 mt-lg-0">
        <div class="step step-active in">
          <h1
            class="section-step__title animate"
            style="transition-delay: 0.15s"
          >
            ¡Tu solicitud fue realizada con éxito!
          </h1>

          <p *ngIf="hasFinancialProtection()" class="animate" style="transition-delay: 0.25s" class="align-left">
            Estamos analizando la solicitud de tu tarjeta y seguro y te enviaremos un mensaje en breve con el estado de los mismos.
          </p>
          <p *ngIf="!hasFinancialProtection()" class="animate" style="transition-delay: 0.25s" class="align-left">
            Estamos analizando la solicitud de tu tarjeta y te enviaremos un mensaje en breve con el estado de la misma.
          </p>
          <p class="animate" style="transition-delay: 0.30s; margin-top: 5px;">
            <a [href]="offerblue" target="_blank">
              <img src="/assets/images/offer-oca-blue.png" alt="Oferta OCA Blue">
            </a>
          </p>

          <div class="animate mt-4" style="transition-delay: 0.35s">
            <a href="https://www.oca.com.uy" (click)="analyticsExito()" class="btn btn-primary"
              >Ir al sitio de OCA</a
            >
          </div>
        </div>
      </div>
      <div class="col-lg-4 order-1 order-lg-2">
        <img *ngIf="!hasFinancialProtection()"
          [src]="visa ? imgVisa : imgOca"
          alt="Tarjeta OCA"
          class="oca-card animate"
          style="transition-delay: 0.45s"
        />
        <span id="insurance" *ngIf="hasFinancialProtection()">
          <img
            src="/assets/images/tarjeta-oca-success-insurance.svg"
            alt="Tarjeta OCA"
            class="oca-card animate logo"
            style="transition-delay: 0.45s"
          />
          <img
            [src]="visa ? imgVisa : imgOca"
            alt="Tarjeta OCA"
            class="oca-card animate"
            style="transition-delay: 0.45s"
          />
        </span>
      </div>
    </div>
  </div>
</section>
