import { BehaviorSubject } from 'rxjs';

export interface FormatResponse {
  visibleValue: string;
  cursorPosition: number;
}

export class Formatter {
  change?: BehaviorSubject<void>;
  format?(num: any): string;
  unformat?(value: string): string | number;
}
