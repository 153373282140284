import { Component } from '@angular/core';
import { CardDataFlag } from 'src/solicitud-online/services/api-enums.model';
import { FormHandlerService } from 'src/solicitud-online/services/form-handler.service';
import { benefits } from './enum/benefits.enum';
import { cards } from './enum/cards.enum';

@Component({
  selector: 'app-select-card-type',
  templateUrl: './select-card-type.component.html',
  styleUrls: ['./select-card-type.component.css']
})
export class SelectCardTypeComponent {
  selectedCard: string | undefined = undefined;
  isOca = false;
  isVisa = false;

  infoOca = true;
  infoVisa = true;

  warning = false;

  master_benefits = [benefits.master_1, benefits.master_2, benefits.master_3, benefits.master_4];
  oca_benefits = [benefits.oca_1, benefits.oca_2, benefits.oca_3, benefits.oca_4];

  constructor(
    private formService: FormHandlerService,
  ) { }

  selectOCA() {
    this.selectedCard = cards.oca;
    this.isOca = true;
    this.isVisa = false;
    this.warning = false;
  }
  
  selectVISA() {
    this.selectedCard = cards.visa;
    this.isVisa = true;
    this.isOca = false;
    this.warning = false;
  }

  next() {
    if (this.selectedCard === undefined) {
      this.warning = true;
      return;
    }
    this.warning = false;
    this.formService.setCardName(this.selectedCard);
    this.formService.cardType(this.selectedCard === cards.oca ? CardDataFlag.OCA : this.selectedCard === cards.visa ? CardDataFlag.VISA : undefined);
    this.formService.performSubNavigationTo('personalData');
  }

  toggleInfoOca() {
    this.infoOca = !this.infoOca;
  }

  toggleInfoVisa() {
    this.infoVisa = !this.infoVisa;
  }
}
