import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.basePath;
  constructor(private http:HttpClient) { }
  visitorId?:number;

  apiPatch(body:any) {
    return this.http.patch(this.baseUrl+`/visitors/${this.visitorId}`,body).subscribe();
  }

  apiPost(body:any) {
    return this.http.post(this.baseUrl+`/visitors`,body).subscribe({
      next: (res) => {
        let user = JSON.parse(JSON.stringify(res));
        this.visitorId = user.VisitorId;
      },
      error: (error) => {
        //error
      }
    });
  }
}
