import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'oca-placeholder',
  templateUrl: './oca-placeholder.component.html',
  styleUrls: ['./oca-placeholder.component.css']
})
export class OcaPlaceholderComponent {

  @Output() clicked:EventEmitter<void> = new EventEmitter()

  onClicked(){
    this.clicked.emit();
  }
}


