import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oca-error',
  templateUrl: './oca-error.component.html',
  styleUrls: ['./oca-error.component.css']
})
export class OcaErrorComponent {

}
