<div id="main">
  <header class="header">
    <div class="container">
      <img class="header__logo" src="assets/images/logo.svg" alt="OCA" />

      <div class="header__ctas">
        <span class="header__title">Pedila ahora:</span>
        <a href="solicitud?card=oca&origen=OcaE2E" role="button" class="btn btn-primary">OCA</a>
        <a href="solicitud?card=visa&origen=OcaE2E" role="button" class="btn btn-primary">Visa de OCA</a>
      </div>
    </div>
  </header>

  <div class="content">
    <div class="hero">
      <div class="hero__bg">
        <picture>
          <source
            srcset="assets/images/landing/hero-bg.jpg"
            media="(min-width: 992px)"
          />
          <img src="assets/images/landing/hero-bg-mobile.jpg" alt="" />
        </picture>
      </div>
      <div class="hero__content">
        <div class="container">
          <h1 class="heading-1">Solicitá<br />tu OCA</h1>
          <ul class="bullet-list">
            <li>El primer año sin costo.</li>
            <li>Acumulá metros.</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="module module--white">
      <div class="container">
        <div class="row align-items-lg-center">
          <div class="col-lg-5">
            <h2 class="heading-2 mb-4 mb-lg-0">
              Solicitá <br class="d-none d-lg-block" />online
            </h2>
          </div>
          <div class="col-lg-7">
            <div class="row text-center">
              <div class="col-6">
                <app-solicitud-btn type="oca"></app-solicitud-btn>
              </div>
              <div class="col-6">
                <app-solicitud-btn type="visa"></app-solicitud-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="module module--blue">
      <div class="container">
        <h2 class="heading-3 mb-4 mb-lg-5">
          Beneficios de <br class="d-sm-none" />tener OCA:
        </h2>

        <div class="boxes">
          <!-- begin Box -->
          <div class="box">
            <div class="box__media">
              <img
                width="70"
                src="assets/images/landing/ic-metraje.svg"
                alt=""
              />
            </div>
            <div class="box__content">
              <h3 class="box__title">Metraje</h3>
              <p>
                Cuanto más usás tu OCA, más metros ganás para canjear por
                cientos de productos y entradas de cine en Movie a la mitad de
                precio.
              </p>
              <div class="mt-3 text-right">
                <a
                  href="https://unmetronosacerca.uy/"
                  role="button"
                  class="box__cta"
                  >+ Info</a
                >
              </div>
            </div>
          </div>
          <!-- end Box -->

          <!-- begin Box -->
          <div class="box">
            <div class="box__media">
              <img width="80" src="assets/images/landing/ic-promo.svg" alt="" />
            </div>
            <div class="box__content">
              <h3 class="box__title">Promociones exclusivas</h3>
              <p>
                Disfrutá de promociones exclusivas como pagar en 3 cuotas al
                comprar en el exterior.
              </p>
            </div>
          </div>
          <!-- end Box -->

          <!-- begin Box -->
          <div class="box">
            <div class="box__media">
              <img width="78" src="assets/images/landing/ic-world.svg" alt="" />
            </div>
            <div class="box__content">
              <h3 class="box__title">Válida en todo el Mundo</h3>
              <p>
                OCA es una tarjeta internacional que podés usar para pagar Uber,
                Netflix y comprar por internet en el exterior.
              </p>
            </div>
          </div>
          <!-- end Box -->

          <!-- begin Box -->
          <div class="box">
            <div class="box__media">
              <img
                width="84"
                src="assets/images/landing/ic-services.svg"
                alt=""
              />
            </div>
            <div class="box__content">
              <h3 class="box__title">Servicios</h3>
              <p>
                Con OCA podés activar débitos automáticos de tus cuentas,
                retirar dinero en efectivo y solicitar préstamos.
              </p>
            </div>
          </div>
          <!-- end Box -->
        </div>
      </div>
    </div>

    <div class="module module--big module--white">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-xl-9">
            <h2 class="heading-3 mb-5">
              ¿Cómo <br class="d-sm-none" />solicito<br />mi OCA?
            </h2>

            <ul class="how-steps">
              <li>
                <div class="how-steps__icon">
                  <img
                    width="48"
                    class="icon-1"
                    src="assets/images/landing/ic-form.svg"
                    alt=""
                  />
                </div>
                <span class="how-steps__text"
                  >Completá el <br class="d-none d-lg-block" />formulario.</span
                >
              </li>
              <li>
                <div class="how-steps__icon">
                  <img
                    width="74"
                    class="icon-2"
                    src="assets/images/landing/ic-mail.svg"
                    alt=""
                  />
                </div>
                <span class="how-steps__text"
                  >Recibirás una <br class="d-none d-lg-block" />respuesta por
                  <br class="d-none d-lg-block d-xl-none" />mail.</span
                >
              </li>
              <li>
                <div class="how-steps__icon">
                  <img
                    width="66"
                    class="icon-3"
                    src="assets/images/landing/ic-home.svg"
                    alt=""
                  />
                </div>
                <span class="how-steps__text"
                  >Recibila <br class="d-none d-lg-block" />en tu casa.</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <span class="hand">
        <img src="assets/images/landing/hand.png" alt="" />
      </span>
    </div>
    <div class="module module--blue py-0">
      <div class="accordion-container">
        <!-- begin accordion item -->
        <div class="accordion">
          <!-- add/remove class active -->
          <div class="accordion__header" (click)="displayAccordion(0)">
            <div class="container">
              <span class="accordion__title">Requisitos</span>
              <span class="accordion__icon">
                <img src="assets/images/landing/ic-arrow.svg" alt="" />
              </span>
            </div>
          </div>
          <div
            class="accordion__content"
            id="panel0"
          >
            <div class="container">
              <div class="wrap">
                <h3 class="heading-4 mb-3">Ingresos mensuales mínimos</h3>
                <ul class="bullet-list">
                  <li>Soltero: $10.000 líquidos</li>
                  <li>Casado: $15.000 líquidos</li>
                  <li>Antigüedad laboral mínima requerida: 6 meses</li>
                </ul>
                <p>
                  Independientemente de que el cliente alcance los ingresos
                  especificados, OCA se reserva el derecho de otorgar la tarjeta
                  de crédito en función del tipo de ingreso, la certificación
                  del mismo y las referencias comerciales que pueda obtener.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- end accordion item -->

        <!-- begin accordion item -->
        <div class="accordion">
          <!-- add/remove class active -->
          <div
            class="accordion__header"
            (click)="displayAccordion(1)"
          >
            <div class="container">
              <span class="accordion__title">¿Tenés preguntas?</span>
              <span class="accordion__subtitle"
                >Conocé aquí algunas de las respuestas a las
                <br class="d-none d-lg-block" />preguntas más frecuentes:</span
              >
              <span class="accordion__icon">
                <img src="assets/images/landing/ic-arrow.svg" alt="" />
              </span>
            </div>
          </div>
          <div
            class="accordion__content"
            id="panel1"
          >
            <div class="container">
              <h3 class="heading-4 mt-5">Sobre la tarjeta de crédito OCA</h3>

              <!-- begin Sub-accordion item -->
              <div class="accordion sub-accordion" >
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(2)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Cuáles son los beneficios de OCA?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel2"
                >
                  <p>
                    Además de la comodidad de comprar sin efectivo y de poder
                    hacerlo con una variada gama de planes de pago en todo el
                    país y en 3 cuotas en el exterior, OCA ofrece más beneficios
                    a sus clientes:
                  </p>
                  <p>
                    Por cada compra acumulás Metros, que luego podrás canjear
                    por una gran variedad de productos y vales de compra.
                  </p>
                  <p>
                    Participás en promociones y sorteos que constantemente se
                    renuevan. Mirá nuestras promociones vigentes
                    <a
                      href="https://www.oca.com.uy/institucional/promociones.aspx"
                      target="_blank"
                      >aquí</a
                    >.
                  </p>
                  <p>
                    Contás con el servicio gratuito de la App OCA y Mi Cuenta
                    web, para controlar todo sobre tu tarjeta desde el celular y
                    sin moverte de donde estés!
                  </p>
                  <p>
                    Y además accedés a préstamos con tasa bonificada y retiros
                    en efectivo en todas las sucursales de OCA y en la red de
                    cajeros automáticos Banred, entre otros.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(3)"
                >
                  <a role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Cómo solicito la tarjeta OCA?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel3"
                >
                  <p>
                    La podés solicitar 100% online haciendo clic
                    <a
                      href="https://solicitatuoca.oca.com.uy/?origen=FAQ-sitioweb"
                      target="_blank"
                      >aquí</a
                    >
                    y completando tus datos. Te la aprobamos en el momento y la
                    enviamos a tu domicilio.
                  </p>
                  <p>
                    También podés solicitarla en cualquiera de nuestras
                    sucursales, llevando cédula de identidad, recibo de sueldo y
                    comprobante de domicilio.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(4)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Dónde puedo pagar el estado de cuenta?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel4"
                >
                  <p>
                    <strong
                      >Tenés varias formas de pagar tu estado de cuenta:</strong
                    >
                  </p>

                  <p class="mb-2"><strong>Online:</strong></p>
                  <ul>
                    <li>
                      - A través de tu cuenta
                      <a href="https://ocablue.com.uy" target="_blank"
                        >OCA Blue</a
                      >, ingresando a micuenta.oca.com.uy o App OCA.
                    </li>
                    <li>
                      - En los sitios web y apps de pago de bancos privados, con
                      número de cédula.
                    </li>
                    <li>
                      - En eBROU y AppBROU, ingresando a la sección “Multipagos”
                      y luego “pago de servicios”.
                    </li>
                    <li>- A través del débito automático en Banco Itaú.</li>
                    <li>- En pagos Banred web o App, con número de cédula.</li>
                  </ul>

                  <p class="mb-2"><strong> Presencial:</strong></p>
                  <ul>
                    <li>
                      - En las sucursales Redpagos de todo el país, diciendo el
                      número de cédula de identidad.
                    </li>
                    <li>
                      - En las cajas de todas las sucursales de OCA que aceptan
                      pagos o en las terminales de Pagos Banred ubicadas en
                      nuestras sucursales, con tu cédula y tu OCA o escaneando
                      el código de barras del estado de cuenta.
                    </li>
                  </ul>

                  <p>
                    Encontrá el listado de sucursales que aceptan pagos o
                    tienen terminales de Pagos Banred en:
                    <a
                      href="https://www.oca.com.uy/institucional/sucursales.aspx"
                      target="_blank"
                      >OCA Sucursales</a
                    >.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(5)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Qué costo tiene la tarjeta?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel5"
                >
                  <p>
                    La tarjeta es gratuita durante el primer año. Luego del
                    primer año pasa a tener un costo trimestral. Si querés ver
                    más información de tasas y tarifas hacé clic
                    <a
                      href="https://www.oca.com.uy/download/noviembreTASAS.pdf"
                      target="_blank"
                      >aquí</a
                    >.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(6)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    En caso de robo o extravío, ¿dónde puedo llamar?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel6"
                >
                  <p>
                    Para denuncias debés llamar al teléfono 29027676. Si llamás
                    desde el exterior recordá agregar la característica de
                    Uruguay 598. En el exterior también podrás realizar la
                    denuncia, desde E.U.A. y Canadá llamando al teléfono:
                    1-800-307-7309 y desde otro país llamando por cobrar a
                    E.U.A. al teléfono 001-636-722-7111.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(7)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Puedo hacer retiros en efectivo con mi tarjeta OCA?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel7"
                >
                  <p>
                    Podés solicitar efectivo en los locales de OCA de todo el
                    país con tu cédula y tarjeta. También podés retirar dinero
                    en efectivo en cualquier cajero BANRED en Uruguay y en la
                    red de cajeros CIRRUS en el exterior con tu número de PIN.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <h3 class="heading-4 mt-5">Sobre compras</h3>

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(8)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Dónde puedo comprar con mi tarjeta OCA?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel8"
                >
                  <p>¡En todo el mundo! Tu tarjeta OCA es internacional.</p>
                  <p>
                    Dentro de Uruguay podés usarla en más de 30.000 comercios
                    adheridos a la red de OCA y en el exterior en todos los
                    comercios adheridos a la red MasterCard.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(9)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Sirve para compras por internet?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel9"
                >
                  <p>
                    Sí. Para comprar por internet en comercios de Uruguay, tenés
                    que seleccionar el medio de pago OCA. Si el comercio es del
                    exterior, tenés que elegir el medio de pago MasterCard.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(10)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Las compras en el exterior tienen costo?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel10"
                >
                  <p>
                    Sí, OCA aplicará un cargo administrativo de 2% más IVA sobre
                    el importe de las compras que realices en el exterior. El
                    seguro de vida sobre Saldo también se calculará sobre esas
                    compras.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(11)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿En qué moneda pago las compras realizadas en el exterior?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel11"
                >
                  <p>
                    Al comprar con tu OCA recibís el estado de cuenta bimoneda.
                    Esto significa que vas a ver un total en pesos uruguayos y
                    otro en dólares. Todas las compras que realices en el
                    exterior, aparecerán reflejadas en dólares. Las compras que
                    no sean en dólares, serán convertidas a dólares USA. Al
                    pagar tu estado de cuenta podrás cancelar los montos en
                    dólares en esa misma moneda u optar por pagar todo en pesos.
                  </p>
                  <p>
                    Para tu comodidad, el monto total en pesos lo encontrarás
                    calculado en el estado de cuenta.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <h3 class="heading-4 mt-5">Sobre consultas generales</h3>

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(12)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Qué son la fecha de vencimiento y la fecha de cierre?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel12"
                >
                  <p>
                    La fecha de cierre indica el día del mes hasta el cual se
                    contabilizarán todas tus compras. Toda compra posterior a
                    esta fecha se reflejará en tu resumen del mes siguiente. En
                    OCA tenemos 4 fechas de cierre: 3, 11, 18 ó 26 de cada mes.
                    Podés consultar el calendario de cierres haciendo clic
                    <a
                      href="https://www.oca.com.uy/imagenes/Home-banner-Calendariodecierres2021.jpg"
                      target="_blank"
                      >aquí</a
                    >. Luego de transcurridas 48 hs de la fecha de cierre
                    elegida se emitirá el resumen de cuenta.
                  </p>
                  <p>
                    La fecha de vencimiento es aquella que indica hasta cuándo
                    se puede abonar el estado de cuenta.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(13)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Cómo puedo cambiar mi fecha de cierre?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel13"
                >
                  <p>
                    Para solicitar un cambio en la fecha de cierre, tenés que
                    pasar por una sucursal de OCA con tu tarjeta y cédula de
                    identidad.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(14)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Cómo veo mi estado de cuenta?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel14"
                >
                  <p>
                    Mensualmente vamos a generar tu estado de cuenta para que
                    puedas pagar por tus compras. Lo podés ver 48 hs después de
                    tu fecha de cierre a través de nuestros canales digitales:
                    Mi Cuenta y la App OCA.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(15)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Dónde puedo ver mis movimientos, pagos y fecha a pagar?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel15"
                >
                  <p>
                    Podés conocer todo sobre tu tarjeta OCA a través de nuestros
                    canales digitales: App OCA y Mi Cuenta
                    (micuenta.oca.com.uy).
                  </p>
                  <p>
                    Si todavía no estás registrado hacelo ahora haciendo clic en
                    “¿Aún no estás registrado?”.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(16)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Qué tipo de pagos existen?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel16"
                >
                  <p>
                    Podrás optar por pagar tu resumen de cuenta en su totalidad
                    (Pago Total), pagar el mínimo establecido (Pago Mínimo) o
                    pagar cualquier monto que elijas entre el total y el mínimo
                    (Pago Parcial).
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(17)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Qué es límite de crédito y cómo puedo ampliarlo?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel17"
                >
                  <p>
                    El límite de crédito es el monto de crédito otorgado para
                    compras.
                  </p>
                  <p>
                    Podés ampliarlo de forma temporaria y automática a través de
                    Mi Cuenta o la App OCA.
                  </p>
                  <p>
                    Si necesitás un aumento de límite permanente, deberás
                    dirigirte con tu recibo de sueldo a una de nuestras
                    sucursales.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <h3 class="heading-4 mt-5">Sobre la seguridad de mi tarjeta</h3>

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(18)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">¿Qué es el pin?</div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel18"
                >
                  <p>
                    El PIN es un número de Identificación Personal de 4 dígitos,
                    que te permite realizar retiros en efectivo en cajeros de
                    Banred en el Uruguay y en cajeros CIRRUS del exterior. Podés
                    solicitarlo llamando al 1730 o dirigiéndote a cualquiera de
                    nuestras sucursales y te lo enviaremos a tu casa.
                  </p>
                  <p>
                    Si olvidás el número de PIN debrás solicitarlo nuevamente,
                    dado que, por seguridad, no queda registro del mismo.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(19)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">¿Qué es el SecureCode?</div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel19"
                >
                  <p>
                    Para que tus transacciones en internet en comercios del
                    exterior sean más seguras, OCA tiene a tu disposición el
                    sistema SecureCode, un código de seguridad para protegerte
                    contra el uso no autorizado de tu tarjeta OCA. Cada tarjeta
                    de una cuenta tendrá un código de seguridad diferente.
                    Podrás obtener los códigos ingresando a Mi Cuenta en
                    www.oca.com.uy o en las terminales de autoconsulta de
                    nuestras sucursales.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(20)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Qué es la tecnología de pagos sin contacto?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel20"
                >
                  <p>
                    Nuestras tarjetas de crédito cuentan con la tecnología de
                    pagos sin contacto (o contactless), que permite realizar
                    transacciones de forma más segura, rápida y sin necesidad de
                    tocar nada. Solo tenés que acercar tu tarjeta a la terminal
                    de POS ¡y listo!
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <h3 class="heading-4 mt-5">Sobre el metraje</h3>

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(21)"
                >
                  <a role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">¿Qué es el metraje?</div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel21"
                >
                  <p>
                    El Metraje es un programa de fidelización de OCA. Cada
                    compra que hagas con tu OCA genera Metros que podés canjear
                    por cientos de productos y vales de compra.
                  </p>
                  <p>
                    Descubrí todo lo que podés canjear haciendo clic en
                    “Beneficios y Metraje” -> “Metraje”.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(22)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Dónde puedo consultar mis Metros acumulados?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel22"
                >
                  <p>
                    Podés consultar tus Metros a través de la App OCA o Mi
                    Cuenta.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(23)"
                >
                  <a role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Cómo puedo canjear mis Metros?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel23"
                >
                  <p>
                    Canjeá desde donde estés ingresando a Mi Cuenta y recibí los
                    artículos sin moverte de tu casa.
                  </p>
                  <p>
                    También podés canjear tus Metros en nuestros Showrooms
                    ubicados en Portones Shopping (Av. Italia 5775 Local 2
                    Primer Nivel) y Centro (Avda. 18 de Julio 1478).
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(24)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Acumulo Metros con compras realizadas en el exterior?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel24"
                >
                  <p>
                    Sí! Acumulás Metros con las compras realizadas en Uruguay y
                    en el mundo.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <h3 class="heading-4 mt-5">Sobre Seguros</h3>

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(25)"
                >
                  <a role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    Voy a viajar, ¿qué seguro de atención al viajero puedo
                    contratar con mi tarjeta?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel25"
                >
                  <p>
                    Te ofrecemos seguros de asistencia de Universal Assistance
                    (linkear a la web de Universal Assitance), para que cuentes
                    con la cobertura y respaldo que necesitás para mantener la
                    tranquilidad y seguridad de tu viaje, familia y equipaje.
                  </p>
                  <p>
                    Contamos con 3 productos: coberturas de US$ 12.000, US$
                    20.000 o US$ 50.000 por el período de viaje o con opción
                    anual. Solicitalo llamando al 2901 7378 de Universal
                    Assistance.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <h3 class="heading-4 mt-5">Sobre Visa de OCA</h3>

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(26)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Cuáles son los beneficios de la tarjeta Visa de OCA?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel26"
                >
                  <p>
                    Tu Visa de OCA es una tarjeta de crédito internacional.
                    Usala en Uruguay y en el mundo en comercios adheridos a la
                    red Visa.
                  </p>
                  <p>
                    Todas tus compras en Uruguay acumulan Metros, que podés
                    canjear por cientos de productos y vales de compra.
                  </p>
                  <p>
                    Accedés a retiros en efectivo en Uruguay (en sucursales OCA
                    o cajeros Banred) y en el exterior (a través de cajeros RED
                    PLUS).
                  </p>
                  <p>
                    Contás con el servicio gratuito de la App OCA y Mi Cuenta
                    web, para controlar todo sobre tu tarjeta desde el celular y
                    sin moverte de donde estés!
                  </p>
                  <p>
                    Y también tenés incluido un seguro de viajero con amplia
                    cobertura de salud, accidentes y extravío de maletas.
                  </p>
                  <br />
                  <p>
                    Encontrá más información sobre el seguro de Universal
                    Assistance en este
                    <a
                      href="https://www.oca.com.uy/download/Visa_1.pdf"
                      target="_blank"
                      >link</a
                    >.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->

              <!-- begin Sub-accordion item -->
              <div class="sub-accordion accordion">
                <!-- add/remove class active -->
                <div
                  class="sub-accordion__header"
                  (click)="displayAccordion(27)"
                >
                  <a  role="button" class="sub-accordion__close">
                    <img src="assets/images/landing/ic-close.svg" alt="Close" />
                  </a>
                  <div class="sub-accordion__title">
                    ¿Qué costo tiene la tarjeta Visa de OCA?
                  </div>
                </div>
                <div
                  class="sub-accordion__content"
                  id="panel27"
                >
                  <p>
                    Para nuevos clientes, el primer año es sin costo. Los años
                    siguientes se comenzará a cobrar un costo anual. Si querés
                    ver más información de tasas y tarifas hacé clic
                    <a
                      href="https://www.oca.com.uy/download/noviembreTASAS.pdf)"
                      target="_blank"
                      >aquí</a
                    >.
                  </p>
                  <p>
                    El costo anual de tu Visa de OCA se carga junto con el
                    primer cierre.
                  </p>
                </div>
              </div>
              <!-- end Sub-accordion item -->
            </div>
          </div>
        </div>
        <!-- end accordion item -->
      </div>
    </div>
  </div>

  <!-- footer -->
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-7 col-md-6 col-lg-9 footer__left">
          <img class="logo" src="assets/images/logo-white.svg" alt="OCA" />
          <div class="row row align-items-lg-end">
            <div class="col-lg-6">
              <div class="address">
                <p><strong>Casa Central</strong></p>
                <p>Colonia 1426. Montevideo, Uruguay.</p>
                <p>
                  <a href="https://www.oca.com.uy/" target="_blank"
                    >oca.com.uy</a
                  >
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="shortcuts d-none d-lg-block">
                <p>
                  <a
                    href="https://micuentanuevo.oca.com.uy/trx/login"
                    target="_blank"
                    >Mi cuenta</a
                  >
                </p>
                <p>
                  <a href="https://unmetronosacerca.uy/" target="_blank"
                    >Metraje</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-5 offset-md-2 col-md-4 offset-lg-0 col-lg-3 footer__right"
        >
          <ul class="social">
            <li>
              <a href="https://www.instagram.com/oca.uruguay" target="_blank">
                <img
                  width="40"
                  src="assets/images/icons/ic-ig.svg"
                  alt="Instagram"
                />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/uruguay.oca" target="_blank">
                <img
                  width="42"
                  src="assets/images/icons/ic-fb.svg"
                  alt="Facebook"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCr6azecWK5HLlW0l8SsR7dA"
                target="_blank"
              >
                <img
                  width="46"
                  src="assets/images/icons/ic-yt.svg"
                  alt="YouTube"
                />
              </a>
            </li>
          </ul>
          <div class="copy text-lg-right">
            <p>
              <a
                href="https://www.oca.com.uy/download/contratoOCA.pdf"
                target="_blank"
                >Términos y condiciones</a
              >
            </p>
            <p>
              <a
                href="https://www.oca.com.uy/download/cartillaOCA.pdf"
                target="_blank"
                >Cartilla OCA</a
              >
            </p>
          </div>
          <div class="shortcuts d-lg-none mt-4">
            <p>
              <a
                href="https://micuentanuevo.oca.com.uy/trx/login"
                target="_blank"
                >Mi cuenta</a
              >
            </p>
            <p>
              <a href="https://unmetronosacerca.uy/" target="_blank">Metraje</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <div class="mobile-ctas">
    <div class="container">
      <span class="mobile-ctas__title">Pedila ahora:</span>
      <div class="btn-wrap">
        <a
          href="solicitud?card=oca&origen=OcaE2E"
          role="button"
          class="btn btn-secondary"
          >OCA</a
        >
        <a
          href="solicitud?card=visa&origen=OcaE2E"
          role="button"
          class="btn btn-secondary"
          >Visa de OCA</a
        >
      </div>
    </div>
  </div>
</div>
