import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '../shared/shared.module';
import { SolicitudOnlineModule } from '../solicitud-online/solicitud-online.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SolicitudBtnComponent } from './components/solicitud-btn/solicitud-btn.component';
import { ApiModule, Configuration, ConfigurationParameters } from 'src/swagger-client';
import { environment } from 'src/environments/environment';
import { RedirectGuard } from 'src/redirect-guard/redirect-guard';


export function apiConfiguration(): Configuration {
  const configurationParameters: ConfigurationParameters = {
    basePath: environment.basePath,
    withCredentials: false,
  };
  return new Configuration(configurationParameters);
}


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    SolicitudBtnComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    MatProgressBarModule,
    SharedModule,
    HttpClientModule,
    ApiModule.forRoot(apiConfiguration),
    SolicitudOnlineModule,
    BrowserAnimationsModule,
    
  ],
  providers: [
    RedirectGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
