import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressDataService } from '../../services/address-data.service';
import { cardRequest } from '../../models/user';
import { FormHandlerService } from '../../services/form-handler.service';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { FormatterService } from 'src/solicitud-online/services/formatter.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-office-retirement-other-address',
  templateUrl: './office-retirement-other-address.component.html',
  styleUrls: ['./office-retirement-other-address.component.css'],
})
export class OfficeRetirementOtherAddressComponent implements OnInit {
  constructor(
    private addressService: AddressDataService,
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService,
    private formatterService: FormatterService,
  ) {}

  departaments: string[] = [];
  departamentKey: any;
  localities_departaments: Map<String, string[]> = new Map<string, string[]>();
  localities: any[] = [];
  localityFlag: boolean = false;
  departmentFlag: boolean = false;
  directionFlag: boolean = false;
  doorNumberFlag: boolean = false;
  apartmentFlag: boolean = false;
  towerFlag: boolean = false;
  squareFlag: boolean = false;
  plotFlag: boolean = false;
  extraDataFlag: boolean = false;
  otherOfficeRetirement!: FormGroup;
  textErrorDireccion: string = '';
  textErrorLocalidad: string = '';
  textErrorDepartamento: string = '';
  textErrorDoorNumber: string = '';
  error: boolean = false;
  errorDoorNumber: boolean = false;
  isMontevideo?: boolean;

  ngOnInit(): void {
    this.departaments = this.addressService.getDepartaments();
    this.localities_departaments = this.addressService.getLocalities();
    this.localityFlag = false;
    this.departmentFlag = false;
    this.directionFlag = false;
    this.doorNumberFlag = false;
    this.apartmentFlag = false;
    this.towerFlag = false;
    this.squareFlag = false;
    this.plotFlag = false;
    this.extraDataFlag = false;
    this.otherOfficeRetirement = new FormGroup({
      departament: new FormControl('', Validators.required),
      locality: new FormControl('', Validators.required),
      retirement_direction: new FormControl('', Validators.required),
      retirement_door_number: new FormControl('', Validators.required),
      retirement_apartment: new FormControl(''),
      retirement_tower: new FormControl(''),
      retirement_square: new FormControl(''),
      retirement_plot: new FormControl(''),
      retirement_extra_data: new FormControl(''),
    });
    this.formService.getLocalStorageData();
    if (
      this.formService.cardDeliveryMethod.deliveryAddress?.department != null
    ) {
      let dataRequest = this.formService.getDeliveryMethod();
      var { deliveryAddress } = dataRequest;
      this.otherOfficeRetirement.patchValue({
        departament: deliveryAddress?.department,
        locality: deliveryAddress?.locality,
        retirement_direction: deliveryAddress?.address,
        retirement_door_number: deliveryAddress?.doorNumber,
        retirement_apartment: deliveryAddress?.apartment,
        retirement_tower: deliveryAddress?.tower,
        retirement_square: deliveryAddress?.square,
        retirement_plot: deliveryAddress?.plot,
        retirement_extra_data: deliveryAddress?.extraData,
      });
      this.directionFlag = true;
      this.localityFlag = true;
      this.departmentFlag = deliveryAddress?.department !== '';
      this.isMontevideo = deliveryAddress?.department === 'Montevideo';
      this.doorNumberFlag = true;
      this.apartmentFlag = true;
      this.towerFlag = true;
      this.squareFlag = true;
      this.plotFlag = true;
      this.extraDataFlag = true;
      this.departamentKey = deliveryAddress?.department;
      this.localities.push(
        this.localities_departaments.get(this.departamentKey)
      );
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.EnvioDeTarjeta)
  }

  onSelect(departamentName: any) {
    this.localities = [];
    this.departamentKey = departamentName.target.value;
    this.localityFlag = true;
    this.localities.push(this.localities_departaments.get(this.departamentKey));
    this.isMontevideo = this.departamentKey === 'Montevideo';
    this.departmentFlag = true;
  }

  localityOnSelect(): void {
    this.directionFlag = true;
    this.doorNumberFlag = true;
    this.apartmentFlag = true;
    this.towerFlag = true;
    this.squareFlag = true;
    this.plotFlag = true;
    this.extraDataFlag = true;
  }

  saveOtherDirection(form: any) {
    if (this.otherOfficeRetirement.valid) {
      this.checkForm(form);
      var addressData: cardRequest = this.getAddressData(form);
      this.formService.otherDirectionRetirement(addressData);
      this.apiService.apiPatch(this.buildBodyOfficeRetirementOther());
    } else {
      if(this.directionFlag == true && this.f.retirement_direction.invalid) {
        this.textErrorDireccion = "Debe ingresar la dirección";
        this.error = true;
      }
      if(this.doorNumberFlag == true && this.f.retirement_door_number.invalid) {
        this.textErrorDoorNumber = "Debe ingresar el número de puerta";
        this.errorDoorNumber = true;
      }
      if(this.localityFlag == true && this.f.locality.invalid) {
        this.textErrorLocalidad = "Debe ingresar la localidad";
      }
      if(this.f.departament.invalid) {
        this.textErrorDepartamento = "Debe ingresar el departamento";
      }
    }
  }

  private getAddressData(form: any): cardRequest {
    let userData: any = {
      departament: form.departament,
      locality: form.locality,
      retirement_direction: this.formatterService.extractAlphanumeric(form.retirement_direction),
      retirement_door_number: form.retirement_door_number,
      retirement_apartment: form.retirement_apartment,
      retirement_tower: this.formatterService.extractAlphanumeric(form.retirement_tower),
      retirement_square: this.formatterService.extractAlphanumeric(form.retirement_square),
      retirement_plot: this.formatterService.extractAlphanumeric(form.retirement_plot),
      retirement_extra_data: this.formatterService.extractAlphanumeric(form.retirement_extra_data),
    };
    return userData;
  }

  public get f(){
    return this.otherOfficeRetirement.controls;
  }

  buildBodyOfficeRetirementOther() {
    return {
      CurrentScreen: "PANTALLA-8",
      IsEndFlow: false
    }
  }

  private checkForm(form: any) {
    if (this.isMontevideo) {
      form.retirement_square = null;
      form.retirement_plot = null;
    }
  }
}
