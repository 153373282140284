/**
 * Specification for OCA Card E2E
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Datos de la tarjeta solicitada.
 */
export interface CardData { 
    /**
     * Bandera. Representa si es OCA o Visa. Valores posibles:  * 2 = OCA  * 3 = VISA 
     */
    flag?: string;
}