<div class="step content_step step-active in">
  <h1 class="section-step__title animate">
    ¿Cómo querés recibir tu tarjeta?
    <p class="mt-1" style="color: #3b5a77;">
      La tarjeta solo se entregará al titular
    </p>
  </h1>
  <div class="row">
    <div class="col-md-6 col-xl-5 position-static">
      <form
        class="form__container"
        id="recibeCard-data"
        [formGroup]="recibeCardData"
        (ngSubmit)="saveRecibeType(recibeCardData.value)"
      >
        <span class="day-pickup">
          Retirala en el día
        </span>
        <!-- 'focused' -->
        <div class="form-group animate" style="transition-delay: 0.15s">
          <label
            for="entrega_personalmente"
            class="button-radio button-radio--size-2 mt-lg-3"
            [ngClass]="(f.typeOfRecibe.value == deliveryMehod.RetirarPersonalmente) ? 'focused' : 'noFocused'"
            style="margin-top: 0 !important;"
          >
            <div class="button-radio__img">
              <img src="assets/images/icons/retiro-personal.svg" alt="" />
            </div>
            <span class="button-radio__text">Retirar personalmente</span>
            <input
              type="radio"
              class="button-radio__input fix-position"
              id="typeOfRecibe"
              formControlName="typeOfRecibe"
              name="typeOfRecibe"
              [value]="deliveryMehod.RetirarPersonalmente"
            />
            <span class="button-radio__circle"></span>
          </label>
        </div>

        <div class="form-group animate" style="transition-delay: 0.2s">
          <label
            for="entrega_domicilio"
            class="button-radio button-radio--size-2 mt-lg-3"
            [ngClass]="(f.typeOfRecibe.value == deliveryMehod.Domicilio) ? 'focused' : 'noFocused'"
          >
            <div class="button-radio__img">
              <img src="assets/images/icons/domicilio.svg" alt="" />
            </div>
            <span class="button-radio__text">Domicilio</span>
            <input
              type="radio"
              class="button-radio__input fix-position"
              id="typeOfRecibe"
              formControlName="typeOfRecibe"
              name="typeOfRecibe"
              [value]="deliveryMehod.Domicilio"
            />
            <span class="button-radio__circle"></span>
          </label>
        </div>

        <div class="form-group animate" style="transition-delay: 0.3s">
          <label
            id="entregaretira"
            for="entrega_otra"
            class="button-radio button-radio--size-2 mt-lg-3"
            [ngClass]="(f.typeOfRecibe.value == deliveryMehod.OtraDireccion) ? 'focused' : 'noFocused'"
          >
            <div class="button-radio__img">
              <img src="assets/images/icons/location.svg" alt="" />
            </div>
            <span class="button-radio__text">Otra dirección</span>
            <input
              type="radio"
              class="button-radio__input fix-position"
              id="typeOfRecibe"
              formControlName="typeOfRecibe"
              name="typeOfRecibe"
              [value]="deliveryMehod.OtraDireccion"
            />
            <span class="button-radio__circle"></span>
          </label>
          <div *ngIf="f.typeOfRecibe.invalid" class="form-error">
            {{ textError }}
          </div>
        </div>
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </form>
    </div>
  </div>
</div>
