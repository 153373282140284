import { Address } from '../../../swagger-client/model/address';

export class AddressModel implements Address { 
    /**
     * Departamento del domicilio
     */
    department?: string;
    /**
     * Localidad del domicilio
     */
    locality?: string;
    /**
     * Dirección del domicilio
     */
    address?: string;
    /**
     * Número de puerta del domicilio
     */
    doorNumber?: string;
    /**
     * Apartamento del domicilio
     */
    apartment?: string;
    /**
     * Torre del domicilio
     */
    tower?: string;
    /**
     * Manzana del domicilio
     */
    square?: string;
    /**
     * Solar del domicilio
     */
    plot?: string;
    /**
     * Datos extra del domicilio
     */
    extraData?: string;
}