import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-solicitud-btn',
  templateUrl: './solicitud-btn.component.html',
  styleUrls: ['./solicitud-btn.component.css'],
})
export class SolicitudBtnComponent {
  @Input()
  type: string = '';

  urlparams = '';

  get description() {
    return this.type.toLowerCase() === 'oca' ? 'OCA' : 'VISA';
  }

  get image() {
    return this.type.toLowerCase() === 'oca'
      ? 'assets/images/landing/oca_card.png'
      : 'assets/images/landing/visa_card.png';
  }

  get alternative() {
    return this.type.toLowerCase() === 'oca' ? 'OCA Card' : 'Visa de OCA';
  }
}
