import { Component, OnInit } from '@angular/core';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { FormHandlerService } from 'src/solicitud-online/services/form-handler.service';
import { LoaderService } from 'src/solicitud-online/services/loader.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-error-section',
  templateUrl: './error-section.component.html',
  styleUrls: ['./error-section.component.css']
})
export class ErrorSectionComponent implements OnInit {
  imgOca = 'assets/images/cards/tarjeta-oca-error.svg';
  imgVisa = 'assets/images/cards/tarjeta-visa-error.svg';
  visa = false;
  constructor(
    private analytics: AnalyticsService,
    private apiService: ApiService,
    private formService: FormHandlerService,
    public loaderService: LoaderService,
  ) {}
  
  ngOnInit(): void {
    this.getType();
    this.blockToPrevious();
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.Error);
    this.apiService.apiPatch(this.buildBodyErrorData());
  }

  getType() {
    this.visa = this.formService.getCardName() == 'visa';
  }
  
  buildBodyErrorData() {
    return {
      CurrentScreen: "PANTALLA-ERROR",
      IsEndFlow: true
    }
  }

  blockToPrevious() {
    history.forward();
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    };
  }

  retry() {
    this.formService.sendRequestCompleteData();
  }

}