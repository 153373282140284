import { Injectable } from '@angular/core';
import { CardDataFlagDescription } from './services/api-enums.model';
import { FormHandlerService } from './services/form-handler.service';

export var AnalyticsPages = {
  Bienvenido : {
    id: '',
    name: 'Bienvenido',
    step: ''
  },
  DatosPersonalesyContacto : {
    id: 'P1',
    name: 'DatosPersonalesyContacto',
    step: 'transactionstep01'
  },
  TipoCedula : {
    id: 'P2',
    name: 'TipoCedula',
    step: 'transactionstep02'
  },
  DatosCedulaTradicional: {
    id: 'P3',
    name: 'DatosCedulaChip',
    step: 'transactionstep03'
  },
  DatosCedulaChip : {
    id: 'P4',
    name: 'DatosCedulaChip',
    step: 'transactionstep03'
  },
  EstadoCivil : {
    id: 'P5',
    name: 'EstadoCivil',
    step: 'transactionstep04'
  },
  DatosOcupacion : {
    id: 'P6',
    name: 'DatosOcupacion',
    step: 'transactionstep05'
  },
  DatosDomicillo : {
    id: 'P7',
    name: 'DatosDomicillo',
    step: 'transactionstep06'
  },
  ComoRecibirLaTarjeta : {
    id: 'P8',
    name: 'ComoRecibirLaTarjeta',
    step: 'transactionstep07'
  },
  RetiroDeTarjetaSucursal : {
    id: 'P9',
    name: 'RetiroDeTarjeta',
    step: 'transactionstep08'
  },
  EnvioDeTarjeta : {
    id: 'P10',
    name: 'EnvioDeTarjeta',
    step: 'transactionstep08'
  },
  RetiroDeTarjetaConfirmacion : {
    id: 'P11',
    name: 'RetiroDeTarjeta',
    step: 'transactioncheckout'
  },
  RetiroDeTarjetaExito : {
    id: 'P12',
    name: 'RetiroDeTarjeta',
    step: 'transactioncomplete'
  },
  ExitoOca : {
    id: 'P13',
    name: 'ExitoOca',
    step: ''
  },
  ExitoVisa : {
    id: 'P14',
    name: 'ExitoVisa',
    step: ''
  },
  Error : {
    id: '',
    name: 'ErrorSolicitudNoProcesada',
    step: ''
  },
};

export var AnalyticsButtons = {
  Exito : {
    customCategory: 'Body | Button | Center',
    customAction: 'Click',
    customLabel: 'BotonExito'
  },
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private formService: FormHandlerService,) {}

  updateAnalytics(analyticsLib: any, analyticsPage: any) {
    var custom = {
      events: [analyticsPage.step],
      errorname: '',
    };
    
    var cardCategory = CardDataFlagDescription.get(this.formService.cardData.flag);
    var transaction = {
      details: {
        cardcategory: cardCategory ? (new String(cardCategory)).toUpperCase() : '',
        transactionstep: analyticsPage.step
      }
    }

    var page = {
      name: 'E2E:OCA:PF:NL:Tarjeta:Credito:Solicitar:' + analyticsPage.name,
      family: "Tarjetas",
      subfamily: "Credito",
      action: "Solicitar",
      pageid: analyticsPage.id
    }

    analyticsLib.trackStateComplete(
      'E2E:OCA:PF:NL:Tarjeta:Credito:Solicitar:' + analyticsPage.name,
      custom,
      transaction,
      page
    );
  }

  trackAction(analyticsLib: any, analyticsButton: any) {
    analyticsLib.trackAction(
      analyticsButton
    );
  }
}
