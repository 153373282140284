import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button-arrow',
  templateUrl: './back-button-arrow.component.html',
  styleUrls: ['./back-button-arrow.component.css'],
})
export class BackButtonArrowComponent implements OnInit {
  constructor(private router: Router, private location: Location) {}
  ngOnInit(): void {}

  goBack() {
    this.location.back();
  }
}
