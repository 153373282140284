import { Component } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent {
  readonly mediaQueryBreak1 = 640;
  readonly mediaQueryBreak2 = 425;

  mobile1: boolean = false;
  mobile2: boolean = false;
  show = false;
  toggle() {
    this.show = !this.show;
    this.checkResponsive();
  }

  checkResponsive() {
    const mobile1 = window.innerWidth <= this.mediaQueryBreak1;
    const mobile2 = window.innerWidth <= this.mediaQueryBreak2;
    if (mobile2) {
      this.mobile1 = false;
      this.mobile2 = true;
    } else if (mobile1) {
      this.mobile1 = true;
      this.mobile2 = false;
    } else {
      this.mobile1 = false;
      this.mobile2 = false;
    }
  }

}
