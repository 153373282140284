import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  status = false;

  setStatus(status: boolean) {
    this.status = status;
  }

  getStatus(): boolean {
    return this.status;
  }

  enable() {
    this.setStatus(true);
  }
  
  disable() {
    this.setStatus(false);
  }

}
