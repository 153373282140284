<div class="step content_step step-active in">
  <h1 class="section-step__title animate">
    Necesitamos validar el código
    <br />
    de seguridad de tu documento.
  </h1>

  <div class="row">
    <div class="col-md-6 col-xl-5 position-static">
      <form
        id="chipCardData-data"
        class="form__container"
        [formGroup]="chipCardData"
        (ngSubmit)="saveChipCardData(chipCardData.value)"
      >
        <div class="video-helper animate" style="transition-delay: 0.2s">
          <div class="video-helper__wrapper">
            <video
              src="assets/videos/chip.mp4?v2"
              muted
              loop
              autoplay
              playsinline
              class="video-helper__video"
            ></video>
          </div>
        </div>

        <div class="form-group animate mt-4" style="transition-delay: 0.3s">
          <oca-form-input
            delay="0.30s"
            formControlName="securityCode"
            type="text"
            maxlength="6"
            [error]="error"
          >
            <oca-placeholder>Código de seguridad</oca-placeholder>
            <oca-error *ngIf="securityCode.invalid">{{ textError }}</oca-error>
          </oca-form-input>
        </div>
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </form>
    </div>
  </div>
</div>
