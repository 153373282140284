import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService, AnalyticsPages } from 'src/solicitud-online/analytics.service';
import { UserIdentificationCardType } from 'src/solicitud-online/services/api-enums.model';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { FormatterService } from 'src/solicitud-online/services/formatter.service';

import { cardRequest } from '../../models/user';
import { FormHandlerService } from '../../services/form-handler.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-identification-card-type',
  templateUrl: './identification-card-type.component.html',
  styleUrls: ['./identification-card-type.component.css'],
})
export class IdentificationCardTypeComponent implements OnInit {
  cardTypeData!: FormGroup;
  textDNIerror: string = '';
  textError: string = '';
  nameLastname?: string = '';
  firstTime: boolean = true;
  error: boolean = false;

  userIdentificationCardType = UserIdentificationCardType

  constructor(private formService: FormHandlerService, private analytics: AnalyticsService, private apiService: ApiService, private formatterService: FormatterService) {}

  ngOnInit(): void {
    this.cardTypeData = new FormGroup({
      dni: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(11)]),
      identificationCardType: new FormControl('', Validators.required),
    });

    this.firstTime = this.formService.getFirstTime();

    if (this.formService.getIdentificationCardData()) { 
      let userIdentificationCard = this.formService.getIdentificationCardData();
      var { number, type } = userIdentificationCard;
      if (number && type ) {
        this.cardTypeData.patchValue({
          dni: number,
          identificationCardType: type
        });
      } else {
        this.cardTypeData.patchValue({
          identificationCardType: this.userIdentificationCardType.Tradicional
        });
      }
    } 
    this.nameLastname =  this.formService.getFullName();
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.TipoCedula)
  }

  public get f(){
    return this.cardTypeData.controls;
  }

  clickCard(value: any) {
    this.cardTypeData.patchValue({
      identificationCardType: value
    });
  }

  saveCardType(form: any) {
    if (this.cardTypeData.valid && this.DocumentNumberValidation(form.dni) == true) {
      var cardType = this.getCardTypeData(form);
      this.formService.identificationCardType(cardType);
      this.apiService.apiPatch(this.buildBodyCardType(
        this.formService.getFullName(),
        this.formatterService.unformatCi(this.formService.getIdentificationCardData().number)));
    } else if (this.dni.value.length > 0 && this.DocumentNumberValidation(form.dni) == false) {
      this.error = true
      this.textDNIerror = 'Debe ingresar un documento válido';
    } else {
      this.getDNIerror();
    }

    if(this.dni.invalid) {
      this.error = true
    }
  }

  private getCardTypeData(form: any): cardRequest {
    let userData: any = {
      dni: form.dni,
      identificationCardType: form.identificationCardType,
    };

    return userData;
  }

  get dni(): AbstractControl {
    return this.cardTypeData.controls.dni;
  }

  get identificationCardType(): AbstractControl {
    return this.cardTypeData.controls.identificationCardType;
  }

  getDNIerror() {
    const unformatCi = this.formatterService.unformatCi(this.dni.value);
    this.error = true;
    if(this.identificationCardType.invalid) {
      this.textError = "Debe seleccionar el tipo de documento";
    }
    if(unformatCi.length == 0) {
      this.textDNIerror = 'Debe ingresar su número de documento';
    } else if (unformatCi.length <= 6 || unformatCi.length >= 10) {
      this.textDNIerror = 'Debe ingresar un documento válido';
    }
  }

  notFirstTime(event:any) {
    this.formService.setFirstTime(false);
    this.firstTime = this.formService.getFirstTime()
  }

  DocumentNumberValidation(documentNumber: string): boolean {
    let valid = false;
    documentNumber = documentNumber.replace(/[^0-9]/g, '');
    if (documentNumber) {
      if (documentNumber.length === 7) {
        documentNumber = '0' + documentNumber;
      }
      let chd1;
      let t1, t2;
      let a1, a2, a3, a4, a5, a6, a7, a8;
      let b1, b2, b3, b4, b5, b6, b7;
      if (documentNumber.length === 8) {
        a1 = Math.floor(Number(documentNumber) / 10000000);
        a2 = Math.floor(Number(documentNumber) / 1000000) - (a1 * 10);
        a3 = Math.floor(Number(documentNumber) / 100000) - (a1 * 100 + a2 * 10);
        a4 = Math.floor(Number(documentNumber) / 10000) - (a1 * 1000 + a2 * 100 + a3 * 10);
        a5 = Math.floor(Number(documentNumber) / 1000) - (a1 * 10000 + a2 * 1000 + a3 * 100 + a4 * 10);
        a6 = Math.floor(Number(documentNumber) / 100) - (a1 * 100000 + a2 * 10000 + a3 * 1000 + a4 * 100 + a5 * 10);
        a7 = Math.floor(Number(documentNumber) / 10) - (a1 * 1000000 + a2 * 100000 + a3 * 10000 + a4 * 1000 + a5 * 100 + a6 * 10);
        a8 = Math.
          floor(Number(documentNumber) / 1) - (a1 * 10000000 + a2 * 1000000 + a3 * 100000 + a4 * 10000 + a5 * 1000 + a6 * 100 + a7 * 10);
        b1 = (a1 * 2) % 10;
        b2 = (a2 * 9) % 10;
        b3 = (a3 * 8) % 10;
        b4 = (a4 * 7) % 10;
        b5 = (a5 * 6) % 10;
        b6 = (a6 * 3) % 10;
        b7 = (a7 * 4) % 10;
        t1 = b1 + b2 + b3 + b4 + b5 + b6 + b7;
        t2 = t1 % 10;
        chd1 = Math.abs(t2 - 10);
        chd1 = chd1 % 10;
        valid = chd1 === a8;
      }
    }
    return valid;
  }

  buildBodyCardType(name?: string, documentNumber?: string) {
    return {
      Name: name,
      DocumentNumber: documentNumber,
      CurrentScreen: "PANTALLA-2",
      IsEndFlow: false
    }
  }
}
