import { Component, OnInit } from '@angular/core';
import { FormHandlerService } from 'src/solicitud-online/services/form-handler.service';

@Component({
  selector: 'app-maintenance-section',
  templateUrl: './maintenance-section.component.html',
  styleUrls: ['./maintenance-section.component.css']
})
export class MaintenanceSectionComponent implements OnInit {
  redirectUrl?: string;

  constructor(private formService: FormHandlerService) {
    this.redirectUrl = formService.maintenanceRedirectUrl;
  }

  ngOnInit(): void {
    this.blockToPrevious();
  }

  blockToPrevious() {
    history.forward();
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    };
  }

  close() {
    location.href = this.redirectUrl!;
  }

}