import { Component, OnInit } from '@angular/core';
import { FormHandlerService } from '../../services/form-handler.service';

@Component({
  selector: 'app-form-static-page',
  templateUrl: './form-static-page.component.html',
  styleUrls: ['./form-static-page.component.css']
})
export class FormStaticPageComponent implements OnInit {

  constructor(
    private formService: FormHandlerService
  ) { }

  ngOnInit(): void {
  }

  get image() {
    let src = '';
    if (this.formService.cardData.flag == "3"){
      src = 'https://www.oca.com.uy/imagenes/Banner_adquisicion_tc_app_visa.jpg';
    } else {
      src = 'https://www.oca.com.uy/imagenes/Banner_adquisicion_tc_app.jpg';  
    }
    return src;
  }

  get alternative() {
    let alt = ''
    if (this.formService.cardData.flag == "3"){
      alt = 'Beneficio OCA VISA';
    } else {
      alt = 'Beneficio OCA';  
    }
    return alt;  
  }

}
