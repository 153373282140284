import { Injectable } from '@angular/core';
import { DataKeysEnum } from './enum/data-keys-enum';

@Injectable({
  providedIn: 'root'
})
export class DataStorerService {

  set(value: string | undefined, key: string | DataKeysEnum): void {
    localStorage.setItem(this.getKey(key), this.getValue(value));
  }

  get(key: string | DataKeysEnum): string {
    const value = localStorage.getItem(this.getKey(key));
    return this.getValue(value);
  }

  setBoolean(key: string | DataKeysEnum, value?: boolean): void {
    if (value != undefined) {
      localStorage.setItem(this.getKey(key), value != undefined ? value.toString() : 'false');
    }
  }

  getBoolean(key: string | DataKeysEnum): boolean | undefined {
    const value = localStorage.getItem(this.getKey(key));
    return value != undefined ? (value == 'true' ? true : false) : undefined;
  }

  clear(key: string | DataKeysEnum) {
    localStorage.removeItem(this.getKey(key));
  }

  clearAll() {
    this.clear(DataKeysEnum.CIVIL_STATUS_STATUS);
    this.clear(DataKeysEnum.EMPLOYMENT_OCCUPATION);
    this.clear(DataKeysEnum.EMPLOYMENT_INCOME);
    this.clear(DataKeysEnum.ADDRESS_DEPARTMENT);
    this.clear(DataKeysEnum.ADDRESS_LOCALITY);
    this.clear(DataKeysEnum.ADDRESS_ADDRESS);
    this.clear(DataKeysEnum.ADDRESS_DOOR_NUMBER);
    this.clear(DataKeysEnum.ADDRESS_APARTMENT);
    this.clear(DataKeysEnum.ADDRESS_TOWER);
    this.clear(DataKeysEnum.ADDRESS_SQUARE);
    this.clear(DataKeysEnum.ADDRESS_PLOT);
    this.clear(DataKeysEnum.ADDRESS_EXTRA_DATA);
    this.clear(DataKeysEnum.RECIBE_CARD_TYPE);
    this.clear(DataKeysEnum.OFFICE_RETIREMENT);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_DEPARTMENT);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_LOCALITY);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_ADDRESS);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_DOOR_NUMBER);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_APARTMENT);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_TOWER);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_SQUARE);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_PLOT);
    this.clear(DataKeysEnum.OTHER_RETIREMENT_EXTRA_DATA);
    this.clear(DataKeysEnum.INSURANCE);
  }

  private getValue(value: string | undefined | null) {
    return value != null ? value : '';
  }

  private getKey(key: string | DataKeysEnum) {
    return key.toString();
  }

}
