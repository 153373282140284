/**
 * Specification for OCA Card E2E
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Estado civil del usuario
 */
export interface UserCivilStatus { 
    /**
     * Estado civil de usuario. Valores posibles:   * 1  = Casado/a   * 2  = Concubino/a   * 3  = Divorciado/a   * 4  = Viudo/a   * 5  = Separado/a   * 10 = Soltero/a 
     */
    type?: string;
    /**
     * Número de documento de cónyuge
     */
    spouseIdentificationCard?: string;
}