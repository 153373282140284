import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oca-hint',
  templateUrl: './oca-hint.component.html',
  styleUrls: ['./oca-hint.component.css']
})
export class OcaHintComponent {

}
