import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatterService {

  // Allow alphanumeric values including ñ, áéíóúü and spaces, but remove other special characters
  readonly alphanumericRegExp: RegExp = /[^a-zA-Z0-9ñáéíóúü\s]/gi;

  unformatCi(value?: string): string {
    // CI: 1.111.111-1 => 11111111
    return this.formatToNumber(value != null ? value : '');
  }

  formatCi(value: string): string {
    // CI: 11111111 => 1.111.111-1
    const ci: string = this.unformatCi(value);
    let lastNumber = '';
    let number = ci;
    if (!(ci.length < 2)) {
      lastNumber = ci.charAt(this.getLastPosition(ci));
      number = ci.slice(0, this.getLastPosition(ci));
    }
    let integerDigits = number.split('');
    for (let i = integerDigits.length - 3; i > 0; i -= 3) {
      integerDigits.splice(i, 0, '.');
    }
    return integerDigits.join('') + (lastNumber != '' ? '-' + lastNumber : '') ;
  }

  unformatPhone(value?: string): string {
    // CI: 1.111.111-1 => 11111111
    return this.formatToNumber(value != null ? value : '');
  }

  formatPhone(value: string): string {
    // Phone: 091111111 => 091 111 111
    const result = this.unformatPhone(value);
    const char = ' ';
    const length = result.length;
    if (length <= 9 && length > 6) {
      return result.slice(0,3) + char + result.slice(3, 6) + char + result.slice(6, length);
    } else if (length <= 6 && length > 3) {
      return result.slice(0,3) + char + result.slice(3, length);
    } else {
      return result;
    }
  }

  formatEmail(value: string): string {
    // Email: MAIL@MAIL.COM => mail@mail.com
    return value.toLocaleLowerCase();
  }

  extractAlphanumeric(value: string | undefined): string {
    return value ? value.replace(this.alphanumericRegExp, '') : '';
  }

  private formatToNumber(value: string): string {
    const digit: RegExp = /\D/g;
    return value.replace(digit, '');
  }

  private getLastPosition(value: string): number {
    return value.length - 1;
  }

}
