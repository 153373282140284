import { Component, OnInit } from '@angular/core';
import { AddressDataService } from '../../services/address-data.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cardRequest } from '../../models/user';
import { FormHandlerService } from '../../services/form-handler.service';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { FormatterService } from 'src/solicitud-online/services/formatter.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
})
export class AddressComponent implements OnInit {
  constructor(
    private addressService: AddressDataService,
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService,
    private formatterService: FormatterService,
  ) {}

  addressData!: FormGroup;
  departaments: string[] = [];
  departamentKey: any = '' ;
  localities_departaments: Map<String, string[]> = new Map<string, string[]>();
  localities: any[] = [];
  localityFlag: boolean = false;
  departmentFlag: boolean = false;
  directionFlag: boolean = false;
  doorNumberFlag: boolean = false;
  apartmentFlag: boolean = false;
  towerFlag: boolean = false;
  squareFlag: boolean = false;
  plotFlag: boolean = false;
  extraDataFlag: boolean = false;
  textErrorDireccion: string = '';
  textErrorLocalidad: string = '';
  textErrorDepartamento: string = '';
  textErrorDoorNumber: string = '';
  error: boolean = false;
  errorDoorNumber: boolean = false;
  isMontevideo?: boolean;

  ngOnInit(): void {
    this.departaments = this.addressService.getDepartaments();
    this.localities_departaments = this.addressService.getLocalities();
    this.departmentFlag = false;
    this.localityFlag = false;
    this.directionFlag = false;
    this.doorNumberFlag = false;
    this.apartmentFlag = false;
    this.towerFlag = false;
    this.squareFlag = false;
    this.plotFlag = false;
    this.extraDataFlag = false;
    this.addressData = new FormGroup({
      departament: new FormControl('',Validators.required),
      locality: new FormControl('',Validators.required),
      address_direction: new FormControl('',Validators.required),
      address_door_number: new FormControl('', Validators.required),
      address_apartment: new FormControl(''),
      address_tower: new FormControl(''),
      address_square: new FormControl(''),
      address_plot: new FormControl(''),
      address_extra_data: new FormControl(''),
    });
    this.formService.getLocalStorageData();
    if (this.formService.address.department != null) {
      let dataRequest = this.formService.getAddressData();
      var { department, locality, address, doorNumber, apartment, tower, square, plot, extraData } = dataRequest;
      this.addressData.patchValue({
        departament: department,
        locality: locality,
        address_direction: this.formatterService.extractAlphanumeric(address),
        address_door_number: doorNumber,
        address_apartment: apartment,
        address_tower: this.formatterService.extractAlphanumeric(tower),
        address_square: this.formatterService.extractAlphanumeric(square),
        address_plot: this.formatterService.extractAlphanumeric(plot),
        address_extra_data: this.formatterService.extractAlphanumeric(extraData),
      });
      this.localityFlag = true;
      this.departmentFlag = department !== '';
      this.isMontevideo = department === 'Montevideo';
      this.directionFlag = true;
      this.doorNumberFlag = true;
      this.apartmentFlag = true;
      this.towerFlag = true;
      this.squareFlag = true;
      this.plotFlag = true;
      this.extraDataFlag = true;
      this.departamentKey = department;

      this.localities.push(
        this.localities_departaments.get(this.departamentKey)
      );
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.DatosDomicillo)
  }

  onSelect(departamentName: any): void {
    this.localities = [];
    this.departamentKey = departamentName.target.value;
    this.localityFlag = true;
    this.localities.push(this.localities_departaments.get(this.departamentKey));
    this.isMontevideo = this.departamentKey === 'Montevideo';
    this.departmentFlag = true;
  }

  localityOnSelect(): void {
    this.directionFlag = true;
    this.doorNumberFlag = true;
    this.apartmentFlag = true;
    this.towerFlag = true;
    this.squareFlag = true;
    this.plotFlag = true;
    this.extraDataFlag = true;
  }

  saveAddressData(form: any) {
    if (this.addressData.valid) {
      this.checkForm(form);
      var addressData: cardRequest = this.getAddressData(form);
      this.formService.addressDataHandler(addressData);
      this.apiService.apiPatch(this.buildBodyAdress());
    } else {
      if(this.directionFlag == true && this.f.address_direction.invalid) {
        this.textErrorDireccion = "Debe ingresar la dirección";
        this.error = true;
      }
      if(this.doorNumberFlag == true && this.f.address_door_number.invalid) {
        this.textErrorDoorNumber = "Debe ingresar el número de puerta";
        this.errorDoorNumber = true;
      }
      if(this.localityFlag == true && this.f.locality.invalid) {
        this.textErrorLocalidad = "Debe ingresar la localidad";
      }
      if(this.f.departament.invalid) {
        this.textErrorDepartamento = "Debe ingresar el departamento";
      }
    }
  }

  private getAddressData(form: any): cardRequest {
    let userData: any = {
      departament: form.departament,
      locality: form.locality,
      address_direction: this.formatterService.extractAlphanumeric(form.address_direction),
      address_door_number: form.address_door_number,
      address_apartment: form.address_apartment,
      address_tower: this.formatterService.extractAlphanumeric(form.address_tower),
      address_square: this.formatterService.extractAlphanumeric(form.address_square),
      address_plot: this.formatterService.extractAlphanumeric(form.address_plot),
      address_extra_data: form.address_extra_data,
    };
    return userData;
  }

  public get f(){
    return this.addressData.controls;
  }
  
  buildBodyAdress() {
    return {
      CurrentScreen: "PANTALLA-6",
      IsEndFlow: false
    }
  }

  private checkForm(form: any) {
    if (this.isMontevideo) {
      form.address_square = null;
      form.address_plot = null;
    }
  }
}
