import { DOCUMENT } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Inject } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})

export class LandingComponent {
  urlparams = '';
  opened = false;

  constructor(@Inject(DOCUMENT) private document: any) {
    this.document.body.classList.add('landing');
  }


  displayAccordion(element: number) {
    document.getElementsByClassName("accordion")[element].classList.toggle("active")
    if(document.getElementById(`panel${element}`)!.style.display === "block"){
      document.getElementById(`panel${element}`)!.style.maxHeight = "0px";
      document.getElementById(`panel${element}`)!.style.removeProperty("display") ;
    } else {
      document.getElementById(`panel${element}`)!.style.maxHeight = document.getElementById(`panel${element}`)!.scrollHeight.toString()+'px';
      document.getElementById(`panel${element}`)!.style.display ="block";
      if(element>1){
        let addMaxHeight = document.getElementById(`panel${1}`)!.scrollHeight + document.getElementById(`panel${element}`)!.scrollHeight;
        document.getElementById(`panel${1}`)!.style.maxHeight = addMaxHeight.toString()+'px';
      }
    }
  }
}

