<div class="step content_step step-active in">
  <h1 class="section-step__title animate">
    Podrás retirar tu tarjeta en cualquiera
    <br />
    de estas sucursales:
  </h1>

  <div class="row">
    <div class="col-md-10 position-static">
      <form
        id="officeRetirement-data"
        class="form__container"
        [formGroup]="officeRetirementData"
        (ngSubmit)="saveOfficeRetirementData(officeRetirementData.value)"
      >
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group animate" style="transition-delay: 0.25s">
              <label
                for="sucursal_montevideo"
                class="button-radio button-radio--size-2"
                [ngClass]="(f.officeRetirement.value == 'Sucursal Montevideo') ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Montevideo</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="sucursal_montevideo"
                  name="officeRetirement"
                  formControlName="officeRetirement"
                  value="Sucursal Montevideo"
                  checked
                  (change)="showMontevideoOffices()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group animate" style="transition-delay: 0.35s">
              <label
                for="sucursal_interior"
                class="button-radio button-radio--size-2"
                (click)="notFirstTime($event)"
                [ngClass]="(f.officeRetirement.value == 'Sucursal Interior') ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Interior</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="sucursal_interior"
                  name="officeRetirement"
                  formControlName="officeRetirement"
                  value="Sucursal Interior"
                  (change)="showInteriorOffices()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
        </div>

        <app-office-list [officePlace]="place"></app-office-list>

        <a
          class="link-underlined animate"
          style="transition-delay: 0.3s"
          href="https://www.oca.com.uy/institucional/sucursales.aspx"
          target="_blank"
          >Ver listado de sucursales</a
        >
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </form>
    </div>
  </div>
</div>
