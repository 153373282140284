<div class="step content_step step-active in">
  <h1 class="section-step__title">
    Bien sigue asi, ahora necesitamos los
    <br />
    datos de tu domicilio actual.
  </h1>
  <div class="row">
    <div class="col-md-7 col-xl-6 position-static">
      <!-- add 'focused' when selected -->
      <form id="address-data" class="form__container" [formGroup]="addressData"
        (ngSubmit)="saveAddressData(addressData.value)">
        <div class="form-group">
          <div class="selectric-div animate" style="transition-delay: 0.15s">
            <label class="form-label form-label--select" for="direccionparticular_departamento">Departamento</label>
            <select id="direccionparticular_departamento" name="direccionparticular_departamento" class="form-control"
              (change)="onSelect($event)" formControlName="departament"
              [ngClass]="(textErrorDepartamento && f.departament.invalid) ? 'errorBorder' : 'noFocused'">
              <option selected value="">Seleccionar</option>
              <option *ngFor="let departament of departaments" value="{{ departament }}">
                {{ departament }}
              </option>
            </select>
          </div>
          <div *ngIf="f.departament.invalid" class="form-error">
            {{ textErrorDepartamento }}
          </div>
        </div>
        <ng-template [ngIf]="departmentFlag">
          <ng-template [ngIf]="!isMontevideo">
            <div class="form-group" *ngIf="localityFlag">
              <div class="selectric-div">
                <label class="form-label form-label--select" for="direccionparticular_localidad">Localidad</label>
                <select id="direccionparticular_localidad" name="direccionparticular_localidad" class="form-control"
                  (change)="localityOnSelect()" formControlName="locality"
                  [ngClass]="(textErrorLocalidad && f.locality.invalid) ? 'errorBorder' : 'noFocused'">
                  <option value="">Seleccionar</option>
                  <option *ngFor="let locality of localities[0]" value="{{ locality }}">
                    {{ locality }}
                  </option>
                </select>
              </div>
              <div *ngIf="f.locality.invalid" class="form-error">
                {{ textErrorLocalidad }}
              </div>
            </div>
            <div class="form-group" *ngIf="directionFlag">
              <oca-form-input formControlName="address_direction" delay="0.30s" [error]="error" [id]="'direction'"
                [maxlength]="'60'">
                <oca-placeholder>Calle</oca-placeholder>
                <oca-error *ngIf="f.address_direction.invalid">{{ textErrorDireccion }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="doorNumberFlag">
              <oca-form-input formControlName="address_door_number" delay="0.30s" [error]="errorDoorNumber"
                [id]="'doorNumber'" [type]="'number'" [maxlength]="'9'">
                <oca-placeholder>Número de puerta</oca-placeholder>
                <oca-error *ngIf="f.address_door_number.invalid">{{ textErrorDoorNumber }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="apartmentFlag">
              <oca-form-input formControlName="address_apartment" delay="0.30s"
                [id]="'apartment'" [type]="'number'" [maxlength]="'10'">
                <oca-placeholder>Apartamento <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="towerFlag">
              <oca-form-input formControlName="address_tower" delay="0.30s" [id]="'tower'" [maxlength]="'30'">
                <oca-placeholder>Torre <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group splitted" *ngIf="squareFlag && plotFlag">
              <oca-form-input class="square" formControlName="address_square" delay="0.30s" [id]="'square'"
                [maxlength]="'5'">
                <oca-placeholder>Manzana <span class="optional"></span></oca-placeholder>
              </oca-form-input>
              <oca-form-input class="plot" formControlName="address_plot" delay="0.30s" [id]="'plot'" [maxlength]="'5'">
                <oca-placeholder>Solar <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="extraDataFlag">
              <oca-form-input formControlName="address_extra_data" delay="0.30s" [id]="'extraData'" [maxlength]="'50'">
                <oca-placeholder>Datos adicionales <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
          </ng-template>
          <ng-template [ngIf]="isMontevideo">
            <div class="form-group" *ngIf="localityFlag">
              <div class="selectric-div">
                <label class="form-label form-label--select" for="direccionparticular_localidad">Localidad</label>
                <select id="direccionparticular_localidad" name="direccionparticular_localidad" class="form-control"
                  (change)="localityOnSelect()" formControlName="locality"
                  [ngClass]="(textErrorLocalidad && f.locality.invalid) ? 'errorBorder' : 'noFocused'">
                  <option value="">Seleccionar</option>
                  <option *ngFor="let locality of localities[0]" value="{{ locality }}">
                    {{ locality }}
                  </option>
                </select>
              </div>
              <div *ngIf="f.locality.invalid" class="form-error">
                {{ textErrorLocalidad }}
              </div>
            </div>
            <div class="form-group" *ngIf="directionFlag">
              <oca-form-input formControlName="address_direction" delay="0.30s" [error]="error" [id]="'direction'"
                [maxlength]="'60'">
                <oca-placeholder>Calle</oca-placeholder>
                <oca-error *ngIf="f.address_direction.invalid">{{ textErrorDireccion }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="doorNumberFlag">
              <oca-form-input formControlName="address_door_number" delay="0.30s" [error]="errorDoorNumber"
                [id]="'doorNumber'" [maxlength]="'9'">
                <oca-placeholder>Número de puerta</oca-placeholder>
                <oca-error *ngIf="f.address_door_number.invalid">{{ textErrorDoorNumber }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="apartmentFlag">
              <oca-form-input formControlName="address_apartment" delay="0.30s"
                [id]="'apartment'" [type]="'number'" [maxlength]="'10'">
                <oca-placeholder>Apartamento <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="towerFlag">
              <oca-form-input formControlName="address_tower" delay="0.30s" [id]="'tower'" [maxlength]="'30'">
                <oca-placeholder>Torre <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="extraDataFlag">
              <oca-form-input formControlName="address_extra_data" delay="0.30s" [id]="'extraData'" [maxlength]="'50'">
                <oca-placeholder>Datos adicionales <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
          </ng-template>
        </ng-template>
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </form>
    </div>
  </div>
</div>
