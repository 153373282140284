<section 
  id="section_error"
  class="section-step section-step--finish section-step-active"
>
  <div class="container section-step__container">
    <div class="row">
      <div class="col-lg-8 order-2 order-lg-1 mt-lg-0">
        <div class="step step-active in">
          <div _ngcontent-xij-c223="" class="img-container">
            <img _ngcontent-xij-c223="" src="assets/images/maintenance-try-later-icon.svg" alt="TryLater" class="image">
          </div>
          <h1
            class="section-step__title animate"
            style="transition-delay: 0.15s"
          >
            ¡Estamos mejorando para vos!
          </h1>

          <p class="animate" style="transition-delay: 0.25s">
            Volvé más tarde y solicitá tu tarjeta de crédito.
          </p>

          <p class="animate" style="transition-delay: 0.25s">
            Ante cualquier duda comunicate a través de nuestros canales de ayuda.
          </p>

          <div class="container">
            <div class="row">
              <a class="icon" href="tel:1730">
                <i class="icon-telephone"></i>
                <b>Llamar al 1730</b>
              </a>
            </div>
            <div class="row">
              <a class="icon" href="mailto:contactenos@oca.com.uy">
                <i class="icon-email"></i>
                <b>Enviar correo electrónico</b>
              </a>
            </div>
          </div>
          
          <div class="animate mt-4" style="transition-delay: 0.35s">
            <span class="btn btn-primary" (pointerdown)="close()">
              Cerrar
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>