<header id="header_main" class="header header--show header--in">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-auto">
        <img class="header__logo" src="assets/images/logo.svg" alt="OCA" />
      </div>
      <div class="col position-static">
        <app-progress-bar></app-progress-bar>
      </div>
    </div>
  </div>
</header>
