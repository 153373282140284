import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { OfficeListComponent } from 'src/solicitud-online/components/office-list/office-list.component';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { cardRequest } from '../../models/user';
import { FormHandlerService } from '../../services/form-handler.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-office-retirement',
  templateUrl: './office-retirement.component.html',
  styleUrls: ['./office-retirement.component.css'],
})
export class OfficeRetirementComponent implements OnInit, AfterViewInit {
  place!: boolean;
  officeRetirementData!: FormGroup;
  firstTime: boolean = true;

  constructor(
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
    this.officeRetirementData = new FormGroup({
      officeRetirement: new FormControl('Sucursal Montevideo'),
    });
    this.formService.getLocalStorageData();
    const officeRetirement = this.formService.getOfficeRetirement();
    if (officeRetirement) { 
      if (officeRetirement != null) {
        this.officeRetirementData.patchValue({
          officeRetirement: officeRetirement
        });
      }
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.RetiroDeTarjetaSucursal);
  }

  ngAfterViewInit(): void {
    this.updateList();
    this.changeDetector.detectChanges();
  }

  updateList() {
    const officeRetirement = this.formService.getOfficeRetirement();
    if (officeRetirement.includes('Montevideo')) {
      this.showMontevideoOffices();
    }
    if (officeRetirement.includes('Interior')) {
      this.showInteriorOffices();
    }
  }

  showMontevideoOffices() {
    this.place = true;
  }

  showInteriorOffices() {
    this.place = false;
  }

  saveOfficeRetirementData(form: any) {
    if (this.officeRetirementData.valid) {
      var officeData: cardRequest = this.getOfficeData(form);
      this.formService.officeRetirementHandler(officeData);
      this.apiService.apiPatch(this.buildBodyOfficeRetirement());
    }
  }

  getOfficeData(form: any): cardRequest {
    let userData: any = {
      officeRetirement: form.officeRetirement,
    };
    return userData;
  }

  notFirstTime(event:any) {
    this.formService.setFirstTime2(false);
    this.firstTime = this.formService.getFirstTime2();
  }

  public get f(){
    return this.officeRetirementData.controls;
  }

  buildBodyOfficeRetirement() {
    return {
      CurrentScreen: "PANTALLA-8",
      IsEndFlow: false
    }
  }
}
