import { Component, OnInit } from '@angular/core';
import { FormHandlerService } from '../../services/form-handler.service';
import { AnalyticsButtons, AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { DataStorerService } from 'src/solicitud-online/services/data-storer.service';
import { ParamService } from 'src/swagger-client/api/param.service';
import { Router } from '@angular/router';

declare var analyticsLib: any;

@Component({
  selector: 'app-final-section',
  templateUrl: './final-section.component.html',
  styleUrls: ['./final-section.component.css']
})
export class FinalSectionComponent implements OnInit {
  imgOca = 'assets/images/cards/tarjeta-oca-success.svg';
  imgVisa = 'assets/images/cards/tarjeta-visa-success.svg';
  visa = false;
  href = '';
  constructor(
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private dataStorerService: DataStorerService,
    private paramsService: ParamService,
    private router: Router,
    ) { }
  cardData!: any;

  ngOnInit(): void {
    this.getType();
    this.getBaseUrl();
    this.blockToPrevious();
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.RetiroDeTarjetaExito);
    if (this.formService.cardData.flag == "3"){
      this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.ExitoVisa);
    }
    if (this.formService.cardData.flag == "2"){
      this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.ExitoOca);
    }
    this.dataStorerService.clearAll();
  }

  getType() {
    this.visa = this.formService.getCardName() == 'visa';
  }

  analyticsExito() {
    this.analytics.trackAction(analyticsLib, AnalyticsButtons.Exito);
  }

  get offerblue() {
    let href = this.href.replace('{idE2E}',this.formService.orderId);
    return href;
  }

  getBaseUrl() {
    this.paramsService.getParameterMap().subscribe(
      {
        next: (data) => {
          const result: Map<string,string> = new Map<string,string>();
          data.forEach(element => {
            result.set(element.key!, element.value!);
          });
          this.href = result.get('public.abrecuentas.baseUrl')!;
        },
        error: () => {
          this.router.navigate(['errorSection']);
        }
      }
    );
  }

  blockToPrevious() {
    history.forward();
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    };
  }

  hasFinancialProtection(): boolean {
    if (this.formService.isOca() && this.formService.hireFinancialProtection) {
      return true;
    } else {
      return false;
    }
  }

}
