<app-header></app-header>
<section class="container section-step sections section-step-active">
  <div>
    <div class="container section-step__container">
      <div class="row">
        <div class="col-lg-8">
          <router-outlet name="solicitud"></router-outlet>
        </div>
        <div class="col-lg-4 d-none d-lg-block col-tarjeta">
          <app-form-static-page></app-form-static-page>
        </div>
      </div>
    </div>
  </div>
</section>
<app-full-screen-loading *ngIf="loaderService.getStatus()"></app-full-screen-loading>
