<header id="header_main" class="header header--show header--in">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-auto">
          <img class="header__logo" src="assets/images/logo.svg" alt="OCA" />
        </div>
      </div>
    </div>
  </header>
<section 
  id="section_error"
  class="section-step section-step--finish section-step-active"
>
  <div class="container section-step__container">
    <div class="row">
      <div class="col-lg-8 order-2 order-lg-1 mt-5 mt-lg-0">
        <div class="step step-active in">
          <h1
            class="section-step__title animate"
            style="transition-delay: 0.15s"
          >
            No fue posible procesar tu solicitud.
          </h1>

          <p class="animate" style="transition-delay: 0.25s" class="align-left">
            No te preocupes, algunos de tus datos quedarán guardados para que puedas reintentar sin volver a completarlos.
          </p>

          <div class="animate mt-4" style="transition-delay: 0.35s">
            <span class="btn btn-primary" (pointerdown)="retry()">
              Reintentar
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-4 order-1 order-lg-2">
        <img
          [src]="visa ? imgVisa : imgOca"
          alt="Tarjeta OCA"
          class="oca-card animate"
          style="transition-delay: 0.45s"
        />
      </div>
    </div>
  </div>
</section>
<app-full-screen-loading *ngIf="loaderService.getStatus()"></app-full-screen-loading>