<div
  class="section-step__btn-next-wrapper btn-animated-full animate"
  style="transition-delay: 0.45s"
>
  <button
    class="btn btn-primary section-step__btn-next next_step"
    mat-raised-button
    color="primary"
    [disabled]="btnDisabled"
    (click)="send()"
  >
    {{ buttonName }}
  </button>
</div>
