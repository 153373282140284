import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddressDataService {
  private departaments: string[] = [
    'Artigas',
    'Canelones',
    'Cerro Largo',
    'Colonia',
    'Durazno',
    'Flores',
    'Florida',
    'Lavalleja',
    'Maldonado',
    'Montevideo',
    'Paysandu',
    'Rio Negro',
    'Rivera',
    'Rocha',
    'Salto',
    'San Jose',
    'Soriano',
    'Tacuarembo',
    'Treinta y Tres',
  ];

  private localities: Map<String, string[]> = new Map<string, string[]>();

  constructor() {
    this.localities.set('Artigas', [
      'ARTIGAS',
      'BALTASAR BRUM',
      'BELEN',
      'BELLA UNION',
      'BERNABE RIVERA',
      'BRIG. GRAL DIEGO LAM',
      'CALPICA ITACUMBU',
      'CAMPAMENTO',
      'COLONIA APARICIO SAR',
      'COLONIA JOSE ARTIGAS',
      'COLONIA PALMA',
      'CUARO',
      'JAVIER DE VIANA',
      'MENEZES',
      'MONES QUINTELA',
      'PALMA SOLA',
      'PASO DEL LEON',
      'PASO FARIAS',
      'PINTADO GRANDE',
      'PUEBLO SEQUEIRA',
      'SARANDI DE CUARO',
      'SARANDI DEL CUARO',
      'SEQUEIRA',
      'TAMANDUA',
      'TOMAS GOMENSORO',
      'TOPADOR',
    ]);
    this.localities.set('Canelones', [
      'AEROPARQUE',
      'AEROPUERTO',
      'AGUAS CORRIENTES',
      'ARAMINDA',
      'ATLANTIDA',
      'BALNEARIO ARGENTINO',
      'BALNEARIO SOLIS',
      'BARRA DE CARRASCO',
      'BARROS BLANCOS',
      'BELLO HORIZONTE',
      'BIARRITZ',
      'BOLIVAR',
      'CAMINO LLOVERAS',
      'CAMPO MILITAR',
      'CANELON CHICO',
      'CANELONES',
      'CAP. JUAN A. ARTIGAS',
      'CAPILLA CELLA',
      'CARRASCO DEL SAUCE',
      'CASERIO MURIALDO',
      'CASSARINO',
      'CASTELLANOS',
      'CHAMIZO',
      'CIUDAD DE LA COSTA',
      'COLINAS DE SOLYMAR',
      'COLONIA 33 ORIENTALE',
      'COLONIA NICOLICH',
      'COSTA AZUL',
      'COSTA DE PANDO',
      'COSTA DE PANTANOSO',
      'COSTAS DEL PEDERNAL',
      'CRUZ DE LOS CAMINOS',
      'CUCHILLA ALTA',
      'EL BOSQUE',
      'EL COLORADO',
      'EL DORADO',
      'EL PINAR',
      'EMPALME NICOLICH',
      'EMPALME OLMOS',
      'ESTACION ATLANTIDA',
      'ESTACION MIGUES',
      'ESTACION PEDRERA',
      'ESTACION TAPIA',
      'FORTIN DE SANTA ROSA',
      'FRAY MARCOS',
      'GUAZUVIRA',
      'GUAZUVIRA NUEVO',
      'ING.ANDREONI',
      'JAUREGUIBERRY',
      'JOANICO',
      'JOAQUIN SUAREZ',
      'LA ESCOBILLA',
      'LA FLORESTA',
      'LA PAZ',
      'LA QUERENCIA',
      'LA TUNA',
      'LAGOMAR',
      'LAGOMAR NORTE',
      'LAGOMAR SUR',
      'LAS BRUJAS',
      'LAS PIEDRAS',
      'LAS TOSCAS',
      'LAS VEGAS',
      'LOMAS DE SOLYMAR',
      'LOS AROMOS',
      'LOS CERRILLOS',
      'LOS TITANES',
      'MARINDIA',
      'MEDANOS DE SOLYMAR',
      'MELILLA',
      'MIGUES',
      'MONTES',
      'MONTES DE SOLYMAR',
      'NEPTUNIA',
      'PANDO',
      'PARAJE SAN JUAN',
      'PARQUE DE CARRASCO',
      'PARQUE DE MIRAMAR',
      'PARQUE DEL PLATA',
      'PARQUE SOLYMAR',
      'PASO CARRASCO',
      'PASO DE LA CADENA',
      'PASO DEL BOTE',
      'PASO DEL MEDIO',
      'PASO DEL PACHE',
      'PIEDRA DEL TORO',
      'PIEDRAS DE AFILAR',
      'PINAMAR',
      'PINAMAR SUR',
      'PINAR NORTE',
      'PINAR SUR',
      'PINARES DE SOLYMAR',
      'PINARES DEL ESTE',
      'PINE PARK',
      'POQUITOS',
      'PROGRESO',
      'PUEBLO BOLIVAR',
      'REPRESA CAN.GDE',
      'RINCON COLORADO',
      'SALINAS',
      'SALINAS NORTE',
      'SALINAS SUR',
      'SAN ANTONIO',
      'SAN BAUTISTA',
      'SAN FRANCISCO',
      'SAN JACINTO',
      'SAN JOSE DE CARRASCO',
      'SAN LUIS',
      'SAN RAMON',
      'SANTA ANA',
      'SANTA LUCIA',
      'SANTA LUCIA DEL ESTE',
      'SANTA ROSA',
      'SANTA TERESITA',
      'SAUCE',
      'SHANGRILA',
      'SHANGRILA NORTE',
      'SHANGRILA SUR',
      'SOCA',
      'SOLIS CHICO',
      'SOLYMAR',
      'SOLYMAR NORTE',
      'SOLYMAR SUR',
      'SUAREZ',
      'TALA',
      'TAPIA',
      'TOLEDO',
      'TOLEDO CHICO',
      'TOTORAL',
      'VILLA ARGENTINA',
      'VILLA DEL MAR',
      'VISTA LINDA',
    ]);
    this.localities.set('Cerro Largo', [
      'ACEGUA',
      'ARBOLITO',
      'BANADO DE MEDINA',
      'CERRO DE LAS CUENTAS',
      'EST. APARICIO SARAVI',
      'FRAILE MUERTO',
      'ISIDRO NOBLIA',
      'LA PEDRERA',
      'LAGO MERIN',
      'MARIA ISABEL',
      'MELO',
      'OLIMAR',
      'PALLEROS',
      'PASO DE CENTURION',
      'PLACIDO ROSAS',
      'PUEBLO AREVALO',
      'RIO BRANCO',
      'SARANDI BARCELO',
      'TOLEDO',
      'TRES ISLAS',
      'TUPAMBAE',
      'URUGUAY',
    ]);
    this.localities.set('Colonia', [
      'AGRACIADA',
      'ARTILLEROS',
      'BARKERO',
      'BLANCARENA',
      'BOCA DEL ROSARIO',
      'BUENA HORA',
      'CAMPAMENTO ARTIGAS',
      'CAMPANA',
      'CARDONA',
      'CARMELO',
      'CERRO',
      'CERRO DE LAS ARMAS',
      'COLONIA ARRUE',
      'COLONIA COSMOPOLITA',
      'COLONIA DEL SACRAMEN',
      'COLONIA ESPANOLA',
      'COLONIA ESTRELLA',
      'COLONIA MIGUELETE',
      'COLONIA PIAMONTESA',
      'COLONIA SUIZA',
      'COLONIA VALDENSE',
      'CONCHILLAS',
      'CUFRE',
      'EL CANO',
      'ESTANZUELA',
      'FLORENCIO SANCHEZ',
      'ISMAEL CORTINAS',
      'JUAN GONZALEZ',
      'JUAN LACAZE',
      'LA HORQUETA',
      'LA PAZ',
      'LOS CERROS DE SAN JU',
      'LOS PINOS',
      'MAL ABRIGO',
      'MANANTIALES',
      'MARTIN CHICO',
      'MIGUELETE',
      'MINUANO',
      'NUEVA HELVECIA',
      'NUEVA PALMIRA',
      'OMBUES DE LAVALLE',
      'PLAYA FOMENTO',
      'PUERTO ROSARIO',
      'PUNTA ARTILLEROS',
      'PUNTA DE LOS PATOS',
      'PUNTA DEL ROSARIO',
      'PUNTA FRANCESA',
      'PUNTA HORNOS',
      'PUNTA PEREYRA',
      'PUNTA PIEDRAS',
      'PUNTA SANTA RITA',
      'REAL DE SAN CARLOS',
      'RIACHUELO',
      'ROSARIO',
      'SAN JUAN',
      'SAN PEDRO',
      'SANTA ANA',
      'SEMILLERO',
      'TARARIRAS',
      'TRES ESQUINAS',
      'VIBORAS',
    ]);
    this.localities.set('Durazno', [
      'AGUAS BUENAS',
      'BAYGORRIA',
      'BLANQUILLO',
      'CABALLERO',
      'CARLOS REYLES',
      'CARMEN',
      'CARPINTERIA',
      'CENTENARIO',
      'CERRO CHATO',
      'CERRO CONVENTO',
      'COLONIA ROSSEL Y RIU',
      'CUADRA',
      'CUCHILLA DE RAMIREZ',
      'DURAZNO',
      'EL CEIBAL',
      'EST. ELIAS REGULES',
      'EST. PTAS. DE HERRER',
      'FELICIANO',
      'LA PALOMA',
      'MOLLES',
      'OMBUES DE ORIBE',
      'PALMAR DE PORRUA',
      'PARAJE EL CHILENO',
      'PARISH',
      'PASO REAL DE CARPINT',
      'POLANCO DEL YI',
      'PUEBLO CENTENARIO',
      'PUNTA DE MALBAJAR',
      'SAN JORGE',
      'SANTA BERNARDINA',
      'SARANDI DEL YI',
      'TAPES',
      'VILLASBOAS',
    ]);
    this.localities.set('Flores', [
      'AHOGADOS',
      'ANDRESITO',
      'ARENAL CHICO',
      'ARROYO MALO',
      'CERRO COLORADO',
      'COSTAS DE SAN JOSE',
      'ISMAEL CORTINAS',
      'JUAN JOSE CASTRO',
      'LOS ARRAJANES',
      'LOS CERROS',
      'LOS PUENTES',
      'MANANTIALES',
      'MARINCHO',
      'PASO DE LUGO',
      'PASO HONDO',
      'PUNTAS DE CEBOLLATI',
      'PUNTAS DEL SAUCE',
      'RINCON DEL PALACIO',
      'TRINIDAD',
    ]);
    this.localities.set('Florida', [
      '25 DE AGOSTO',
      '25 DE MAYO',
      'ALEJANDRO GALLINAL',
      'ARROYO DE LA VIRGEN',
      'BATLLE Y ORDONEZ',
      'BERRONDO',
      'CAPILLA DEL SAUCE',
      'CARDAL',
      'CASUPA',
      'CERRO CHATO',
      'CERRO COLORADO',
      'CHAMIZO',
      'CHAMIZO CHICO',
      'COLONIA 33 ORIENTALE',
      'EST. CAPILLA DEL SAU',
      'EST. CO. COLORADO',
      'EST. DR. ALEJANDRO G',
      'EST. HERNANDARIAS',
      'EST. NICO PEREZ',
      'EST. PTAS. DE MACIEL',
      'EST. SARANDI GDE.',
      'EST. STA. TERESA',
      'EST. TALITA',
      'FLORIDA',
      'FRAY MARCOS',
      'GONI',
      'ILLESCAS',
      'INDEPENDENCIA',
      'LA CRUZ',
      'LA MACANA',
      'LOS ARRAYANES',
      'MANSAVILLAGRA',
      'MENDOZA',
      'MENDOZA CHICO',
      'MENDOZA GRANDE',
      'MONTECORAL',
      'NICO PEREZ',
      'PALERMO',
      'PARAJE STA. TERESA',
      'PASO CASTRO',
      'PINTADO',
      'POLANCO DEL YI',
      'PUNTAS CEBOLLATI',
      'PUNTAS DE MACIEL',
      'REBOLEDO',
      'RODRIGUEZ',
      'SAN GABRIEL',
      'SARANDI GRANDE',
      'SAUCE DEL YI',
      'VALENTINES',
      'VILLA VIEJA',
    ]);
    this.localities.set('Lavalleja', [
      'AGUAS BLANCAS',
      'BARRANCAS',
      'BATLLE Y ORDONEZ',
      'CASUPA',
      'COLON',
      'EST. ILLESCAS',
      'EST. MONTES',
      'ESTACION ANDREONI',
      'INGENIERO ANDREONI',
      'J. BATLLE Y ORDONEZ',
      'J. PEDRO VARELA',
      'LA CORONILLA',
      'MARISCALA',
      'MARMARAJA',
      'MINAS',
      'NICO PEREZ',
      'ORTIZ',
      'PEDRO ARAMENDIA',
      'PIRARAJA',
      'POLANCO',
      'POLANCO SUR',
      'SOLIS',
      'SOLIS CHICO',
      'SOLIS DE MATAOJO',
      'VALLE FUENTES',
      'VELENTINES',
      'VILLA ROSARIO',
      'VILLA SERRANA',
      'ZAPICAN',
    ]);
    this.localities.set('Maldonado', [
      'Abra de Perdomo',
      'AIGUA',
      'BALNEARIO LAS FLORES',
      'BALNEARIO SOLIS',
      'Barra de Portezuelo',
      'BELLA VISTA',
      'BUENOS AIRES',
      'Cerros Azules',
      'Chihuahua',
      'El Chorro',
      'El Eden',
      'El Quijote',
      'El Tesoro',
      'FARO JOSE IGNACIO',
      'GARZON',
      'Gerona',
      'GREGORIO AZNAREZ',
      'IZCUA',
      'Jose Ignacio',
      'LA BARRA',
      'La Capuera',
      'La Juanita',
      'Lag.delSauce',
      'LAS CANAS',
      'LAS DELICISA',
      'LAS FLORES',
      'LOS TALAS',
      'MALDONADO',
      'MANANTIALES',
      'Mataojo',
      'NUEVA CARRARA',
      'Ocean Park',
      'PAN DE AZUCAR',
      'PIRIAPOLIS',
      'PLAYA GRANDE',
      'PLAYA HERMOSA',
      'PLAYA VERDE',
      'PORTEZUELO',
      'PUEBLO EDEN',
      'Pueblo Obrero',
      'PUNTA BALLENA',
      'PUNTA COLORADA',
      'PUNTA DEL ESTE',
      'PUNTA FRIA',
      'Punta Negra',
      'PUNTA SOLIS',
      'SAN CARLOS',
      'SAN RAFAEL',
      'SANTA MONICA',
      'SAUCE DE PORTEZUELO',
      'Solis',
    ]);
    this.localities.set('Montevideo', [
      'ABAYUBA',
      'AGUADA',
      'AIRES PUROS',
      'ATAHUALPA',
      'BAÑADOS DE CARRASCO',
      'BARRIO SUR',
      'BELLA ITALIA',
      'BELLA VISTA',
      'BELVEDERE',
      'BOLIVAR',
      'BRAZO ORIENTAL',
      'BUCEO',
      'CAPURRO',
      'CARRASCO',
      'CARRASCO NORTE',
      'CASABO',
      'CASAVALLE',
      'CASTELLANOS',
      'CASTRO',
      'CENTRO',
      'CERRITO',
      'CERRO',
      'CIUDAD VIEJA',
      'COLON CENTRO Y NOROESTE',
      'COLON SURESTE',
      'CONCILIACION',
      'CORDON',
      'FIGURITA',
      'FLOR DE MAROÑAS',
      'ITUZAINGO',
      'JACINTO VERA',
      'JARDINES DEL HIPODROMO',
      'LA BLANQUEADA',
      'LA COMERCIAL',
      'LA PALOMA',
      'LA TEJA',
      'LARRAÑAGA',
      'LAS ACACIAS',
      'LAS CANTERAS',
      'LAVALLEJA',
      'LEZICA',
      'MALVIN',
      'MALVIN NORTE',
      'MANGA',
      'MANGA RURAL',
      'MAROÑAS',
      'MELILLA',
      'MERCADO MODELO',
      'NUEVA SAVONA',
      'NUEVO PARIS',
      'PAJAS BLANCAS',
      'PALERMO',
      'PARQUE BATLLE',
      'PARQUE GUARANI',
      'PARQUE RODO',
      'PASO DE LA ARENA',
      'PASO DE LAS DURANAS',
      'PEÑAROL',
      'PIEDRAS BLANCAS',
      'POCITOS',
      'PRADO',
      'PUEBLO VICTORIA',
      'PUERTO',
      'PUNTA CARRETAS',
      'PUNTA GORDA',
      'PUNTA RIELES',
      'REDUCTO',
      'RETIRO',
      'SAYAGO',
      'TOLEDO CHICO',
      'TOMKINSON',
      'TRES CRUCES',
      'TRES OMBUES',
      'UNION',
      'VILLA BIARRITZ',
      'VILLA DOLORES',
      'VILLA ESPAÑOLA',
      'VILLA GARCIA',
      'VILLA MUÑOZ',
    ]);
    this.localities.set('Paysandu', [
      'ALGORTA',
      'ARBOLITO',
      'ARROYO MALO',
      'BEISSO',
      'CANADA DEL PUEBLO',
      'CASABLANCA',
      'CERRO CHATO',
      'CHAPICUY',
      'CONSTANCIA',
      'CUCHILLA DE SAN JOSE',
      'DAYMAN',
      'EL EUCALIPTUS',
      'ESPERANZA',
      'EST. PORVENIR',
      'FEDERACION',
      'GORGOROSO',
      'GUALEGUAY',
      'GUICHON',
      'KILOMETRO 171',
      'LA TENTACION',
      'LAS DELICIAS',
      'LORENZO GEYRES',
      'MERINOS',
      'MOLLES ESPERANZA',
      'PANDULE',
      'PARADA ESPERANZA',
      'PAYSANDU',
      'PIEDRA SOLA',
      'PIEDRAS COLORADAS',
      'PINERA',
      'PUEBLO GALLINAL',
      'PUEBLO PORVENIR',
      'PUNTAS DE GUALEGUAY',
      'QUEBRACHO',
      'QUEGUAY',
      'QUEGUAY CHICO',
      'QUEGUAYAR',
      'SAN FELIX',
      'SAN JAVIER',
      'TAMBORES',
      'TERMAS DE GUAVIYU',
      'TIATUCURA',
      'TRES ARBOLES',
      'VALDEZ',
    ]);
    this.localities.set('Rio Negro', [
      'ALGORTA',
      'BELLACO',
      'CANITAS',
      'COLONIA KENNEDY',
      'FRANCIA',
      'FRAY BENTOS',
      'GRECCO',
      'LAS CANAS',
      'LAS FLORES',
      'LOS MELLIZOS',
      'MENAFRA',
      'MERINOS',
      'NAVARRO',
      'NUEVO BERLIN',
      'PALMA',
      'PARAJE SAUCE-SANCHEZ',
      'PASAJE TRES BOCAS',
      'PASO DE LA CRUZ',
      'PASO DE LA LAGUNA',
      'PASO LEOPOLDO',
      'PILARES',
      'PORRUA',
      'ROLON',
      'SAN JAVIER',
      'SANCHEZ',
      'SARANDI DE NAVARRO',
      'TRES BOCAS',
      'ULESTE',
      'YOUNG',
    ]);
    this.localities.set('Rivera', [
      'ABROJAL',
      'ADUANA DE BATOVI',
      'AMARILLO',
      'ARROYO BLANCO',
      'ATAQUES',
      'CARAGUATA',
      'CERRILLADA',
      'CERRO PELADO',
      'CORONILLA',
      'CUCHILLA MANGUERAS',
      'HOSPITAL',
      'LA CALERA',
      'LA PUENTE',
      'LAGOS DEL NORTE',
      'LAS FLORES',
      'MANUEL DIAZ',
      'MASOLLER',
      'MINAS DE CORRALES',
      'MOIRONES',
      'PASO DEL ATAQUE',
      'PUNTAS DE CORRALES',
      'RIVERA',
      'TRANQUERAS',
      'TRES PUENTES',
      'VICHADERO',
      'ZANJA HONDA',
    ]);
    this.localities.set('Rocha', [
      'AGUAS DULCES',
      'ANGOSTURA',
      'ARACHANIA',
      'BARRA DE VALIZAS',
      'BARRA DEL CHUY',
      'CABO POLONIO',
      'CABO SANTA MARIA',
      'CASTILLOS',
      'CEBOLLATI',
      'CHUY',
      'COSTA AZUL',
      'DIECINUEVE DE ABRIL',
      'DIECIOCHO DE JULIO',
      'GRAL. ENRIQUE MARTIN',
      'LA AGUADA',
      'LA CORONILLA',
      'LA ESMERALDA',
      'LA PALOMA',
      'LA PEDRERA',
      'LASCANO',
      'LOS INDIOS',
      'LOS TALAS',
      'PASO BARRANCAS',
      'PERALLE',
      'PICADA DE TECHERA',
      'POBLADO 19 DE JUNIO',
      'PUNTA AGUDA',
      'PUNTA CASTILLOS',
      'PUNTA CERRO CHATO',
      'PUNTA DE LA CORONILL',
      'PUNTA DE LA MOZA',
      'PUNTA DE LAS PIEDRAS',
      'PUNTA DEL BARCO',
      'PUNTA DEL DIABLO',
      'PUNTA NAGRO',
      'PUNTA PELOTAS',
      'PUNTA SAN LUIS',
      'ROCHA',
      'SAN LUIS',
      'SAN LUIS AL MEDIO',
      'SANTA TERESA',
      'VELAZQUEZ',
    ]);
    this.localities.set('Salto', [
      'ALBISU',
      'ARENITAS BLANCAS',
      'BELEN',
      'BIASSINI',
      'CAYETANO',
      'CERRO DE VERA',
      'COLONIA ITAPEBI',
      'COLONIA RUBIO',
      'COLONIA SANTA BLANCA',
      'CONSTITUCION',
      'CUCHILLA DE GUAVIYU',
      'CUCHILLA DE SALTO',
      'ESTACION SANTA ANA',
      'FERREIRA',
      'ITAPEBI',
      'LAURELES',
      'LLUVERAS',
      'MATAOJO',
      'OLIVERA',
      'PALOMAS',
      'PUEBLO LAVALLEJA',
      'PUEBLO MATAOJITO',
      'PUNTAS DE VALENTIN',
      'RINCON DE VALENTIN',
      'SALTO',
      'SAN ANTONIO',
      'SARANDI DE ARAPEY',
      'SARANDI DEL YACUI',
      'SAUCEDO',
      'TERMAS DEL ARAPEY',
      'VALENTIN',
    ]);
    this.localities.set('San Jose', [
      '25 DE AGOSTO',
      'AUTODROMO',
      'BOCA DEL CUFRE',
      'CAPURRO',
      'CARRETA QUEMADA',
      'CHAMIZO',
      'Ciudad del Plata',
      'COLONIA AGRARIA DELT',
      'COLONIA AMERICA',
      'COLONIA ETCHEPARE',
      'COLONIA VICENTE PERE',
      'CUCHILLA DE VICHADER',
      'DELTA DEL TIGRE',
      'ECILDA PAULLIER',
      'GONZALEZ',
      'ISMAEL CORTINAS',
      'ITUZAINGO',
      'JUAN SOLER',
      'KIYU',
      'LIBERTAD',
      'MAL ABRIGO',
      'MUNDO AZUL',
      'PLAYA PASCUAL',
      'PUNTA DEL TIGRE',
      'PUNTA SAN GREGORIO',
      'PUNTAS DE CANADA GDE',
      'PUNTAS DE VALDES',
      'RAFAEL PERAZZA',
      'RAIGON',
      'RAPETTI',
      'RINCON DE ALBANO',
      'RINCON DE LA BOLSA',
      'RINCON DE LA TORRE',
      'RINCON DEL PINO',
      'RODRIGUEZ',
      'SAN GREGORIO',
      'SAN JOSE DE MAYO',
      'SCAVINO',
      'TROPA VIEJA',
      'VILLA MARIA',
      'VILLA RODRIGUEZ',
    ]);
    this.localities.set('Soriano', [
      'AGRACIADA',
      'AZOTEA DE VERA',
      'BEQUELO',
      'BIZCOCHO',
      'CANADA NIETO',
      'CANADA PARAGUAYA',
      'CARDONA',
      'CASTILLOS',
      'COLOLO',
      'COLONIA LARRANAGA',
      'CONCORDIA',
      'COQUIMBO',
      'CUCHILLA DEL PERDIDO',
      'DOLORES',
      'DURAZNITO',
      'EGANA',
      'EST. GRITO DE ASENCI',
      'FLORENCIO SANCHEZ',
      'GEYMONAT',
      'ISMAEL CORTINAS',
      'J. ENRIQUE RODO',
      'JUAN J. JACKSON',
      'LA LAGUNA',
      'MERCEDES',
      'MONZON',
      'PALMAR',
      'PALMITAS',
      'PALO SOLO',
      'PASO DE LAS PIEDRAS',
      'PERDIDO',
      'RINCON DE COLOLO',
      'RINCON DEL COLOLO',
      'RISSO',
      'SAN MARTIN',
      'SANTA CATALINA',
      'SORIANO',
      'SUAREZ',
      'UTURBEY',
      'VILLA DARWIN',
      'VILLA SORIANO',
    ]);
    this.localities.set('Tacuarembo', [
      'ACHAR',
      'ALDEA SAN JOAQUIN',
      'BANADO DE ROCHA',
      'BATOVI',
      'BAYGORRIA',
      'BLANQUILLOS',
      'CAPON DE LA YERBA',
      'CARDOZO',
      'CHAMBERLAIN',
      'CLARA',
      'CUCHILLA DE CARAGUAT',
      'CUCHILLA DE PERALTA',
      'CUCHILLA DE RAMIREZ',
      'CUCHILLA DEL OMBU',
      'CURTINA',
      'LA HILERA',
      'LA PEDRERA',
      'LARRAYOS',
      'LAS ARENAS',
      'LAS TOSCAS',
      'LAURELES',
      'LOS ROSANOS',
      'LOS VAZQUEZ',
      'MARTINOTE',
      'MENENDEZ',
      'PAMPA',
      'PARAJE ARRIERA',
      'PASO BONILLA',
      'PASO CEFERINO',
      'PASO DE LAS CARRETAS',
      'PASO DE LAS TOSCAS',
      'PASO DE LOS NOVILLOS',
      'PASO DE LOS TOROS',
      'PASO DEL CERRO',
      'PASO HONDO',
      'PASO MANZANGANO',
      'PERALTA',
      'PICADA DE CUELLO',
      'PIEDRA SOLA',
      'PUEBLO ANSINA',
      'PUEBLO ARBOLITO',
      'PUEBLO CENTENARIO',
      'PUEBLO CLAVIJO',
      'PUEBLO DE ARRIBA',
      'PUEBLO DE BARRO',
      'PUEBLO HERIBERTO',
      'QUIEBRA YUGOS',
      'RINCON DE PEREYRA',
      'RINCON DEL BONETE',
      'SAN GREGORIO DE POLA',
      'TACUAREMBO',
      'TAMBORES',
      'TIERRAS COLORADAS',
      'VALLE EDEN',
      'ZAPARA',
    ]);
    this.localities.set('Treinta y Tres', [
      'ARROZAL TREINTA Y TR',
      'CANADA CHICA',
      'CERRO CHATO',
      'CERROS DE AMARRO',
      'ENRIQUE MARTINEZ',
      'EST. APARICIO SARAVI',
      'ISLA PATRULLA',
      'JULIO MARIA SANZ',
      'MARIA ALBINA',
      'MENDIZABAL',
      'OLIMAR',
      'PICADA DE TECHERA',
      'PLACIDO ROSAS',
      'RINCON',
      'SANTA CLARA OLIMAR',
      'TREINTA Y TRES',
      'TUPAMBAE',
      'VALENTINES',
      'VERGARA',
      'VILLA PASANO',
    ]);
  }

  getDepartaments() {
    return this.departaments;
  }

  getLocalities() {
    return this.localities;
  }
}
