import { AfterContentInit, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { FormHandlerService } from '../../services/form-handler.service';
import { cardRequest } from '../../models/user';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { FormatterService } from 'src/solicitud-online/services/formatter.service';
import { ParamService } from 'src/swagger-client';
import { Router } from '@angular/router';

declare var analyticsLib: any;

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.css'],
})
export class PersonalDataComponent implements OnInit, AfterContentInit {
  constructor(
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService,
    private formatterService: FormatterService,
    private paramsService: ParamService,
    private router: Router,
    ) {}

  personalData!: FormGroup;
  errorNameLastName!: string;
  errorEmail!: string;
  errorPhone!: string;
  stepProgress: number = 8;
  error1: boolean = false;
  error2: boolean = false;
  error3: boolean = false;

  ngAfterContentInit(): void {
    this.getMaintenanceStatus();
  }

  ngOnInit(): void {

    const deleteNode = document.getElementById('help-email');
    if (deleteNode == null) {
      const node = document.createElement('script');
      node.src = '/assets/js/help-email.js';
      node.type = 'text/javascript';
      node.async = false;
      node.id = 'help-email';   
      document.getElementsByTagName('head')[0].appendChild(node);
    }  

    this.personalData = new FormGroup({
      name_lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [
        Validators.required,
        Validators.pattern(`^09[1-9]([\\s]{0,1}[0-9]{3}[\\s]{0,1}[0-9]{3})$`),
      ]),
    });

    if (this.formService.user.fullName != null) {
      let dataRequest = this.formService.getUserData();
      var { fullName, email, phone } = dataRequest;
      this.personalData.patchValue({
        name_lastName: fullName,
        email: email,
        phone: this.formatterService.formatPhone(phone!),
      });
    }

    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.DatosPersonalesyContacto)
  }

  get image() {
    let src = '';
    if (this.formService.cardData.flag == "3"){
      src = 'https://www.oca.com.uy/imagenes/Banner_adquisicion_tc_app_visa.jpg';
    } else {
      src = 'https://www.oca.com.uy/imagenes/Banner_adquisicion_tc_app.jpg';  
    }
    return src;
  }

  get alternative() {
    let alt = ''
    if (this.formService.cardData.flag == "3"){
      alt = 'Beneficio OCA VISA';
    } else {
      alt = 'Beneficio OCA';  
    }
    return alt;  
  }

  savePersonalData(form: any) {
    if (this.personalData.valid) {
      var personalData: cardRequest = this.getPersonalData(form);
      this.formService.personalDataHandler(personalData);
      this.apiService.apiPost(this.buildBodyPersonaData(form));
    } else {
      if (this.name_lastName.invalid) {
        this.error1 = true;
      }

      if (this.email.invalid) {
        this.error2 = true;
      }

      if (this.phone.invalid) {
        this.error3 = true;
      }
      this.getErrors();
    }
  }

  private getPersonalData(form: any): cardRequest {
    let userData: any = {
      name_lastName: form.name_lastName,
      email: form.email,
      phone: form.phone,
    };
    return userData;
  }

  get name_lastName(): AbstractControl {
    return this.personalData.controls.name_lastName;
  }

  get email(): AbstractControl {
    return this.personalData.controls.email;
  }

  get phone(): AbstractControl {
    return this.personalData.controls.phone;
  }

  getErrors() {
    this.errorNameLastName = 'Debe ingresar su nombre';
    this.errorEmail = 'Debe ingresar su email';
    this.errorPhone = 'Debe ingresar su celular';
  }

  buildBodyPersonaData(form:any) {
    return {
      Mail: form.email,
      Phone: form.phone,
      CurrentScreen: "PANTALLA-1",
      IsEndFlow: false
    }
  }

  getMaintenanceStatus() {
    this.paramsService.getParameterMap().subscribe(
      {
        next: (data) => {
          const result: Map<string,string> = new Map<string,string>();
          data.forEach(element => {
            result.set(element.key!, element.value!);
          });
          this.formService.maintenanceStatus = result.get('maintenance.status') !== undefined ? (result.get('maintenance.status')!.toLowerCase()! === 'true' ? true : false) : false;
          this.formService.maintenanceRedirectUrl = result.get('maintenance.redirectUrl')!;
          if (this.formService.maintenanceStatus === true) {
            this.router.navigate(['maintenanceSection']);
          }
        },
        error: () => {
          this.formService.maintenanceStatus = false;
        }
      }
    );
  }
}
