export enum DataKeysEnum {
    COMMON_KEY = <any>'data',
    CIVIL_STATUS_KEY= <any>'civil-status',
    EMPLOYMENT_KEY= <any>'employment',
    ADDRESS_KEY = <any>`address`,
    RECIBE_CARD_KEY = <any>`recibe-card`,
    OTHER_RETIREMENT_KEY = <any>`other-retirement`,
    CIVIL_STATUS_STATUS = <any>`${COMMON_KEY}_${CIVIL_STATUS_KEY}_status`,
    EMPLOYMENT_OCCUPATION = <any>`${COMMON_KEY}_${EMPLOYMENT_KEY}_occupation`,
    EMPLOYMENT_INCOME = <any>`${COMMON_KEY}_${EMPLOYMENT_KEY}_income`,
    ADDRESS_DEPARTMENT = <any>`${COMMON_KEY}_${ADDRESS_KEY}_state`,
    ADDRESS_LOCALITY = <any>`${COMMON_KEY}_${ADDRESS_KEY}_city`,
    ADDRESS_ADDRESS = <any>`${COMMON_KEY}_${ADDRESS_KEY}_address`,
    ADDRESS_DOOR_NUMBER = <any>`${COMMON_KEY}_${ADDRESS_KEY}_door-number`,
    ADDRESS_APARTMENT = <any>`${COMMON_KEY}_${ADDRESS_KEY}_apartment`,
    ADDRESS_TOWER = <any>`${COMMON_KEY}_${ADDRESS_KEY}_tower`,
    ADDRESS_SQUARE = <any>`${COMMON_KEY}_${ADDRESS_KEY}_square`,
    ADDRESS_PLOT = <any>`${COMMON_KEY}_${ADDRESS_KEY}_plot`,
    ADDRESS_EXTRA_DATA = <any>`${COMMON_KEY}_${ADDRESS_KEY}_extra-data`,
    RECIBE_CARD_TYPE = <any>`${COMMON_KEY}_${RECIBE_CARD_KEY}_recibe-card-type`,
    OFFICE_RETIREMENT = <any>`${COMMON_KEY}_office-retirement`,
    OTHER_RETIREMENT_DEPARTMENT = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_state`,
    OTHER_RETIREMENT_LOCALITY = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_city`,
    OTHER_RETIREMENT_ADDRESS = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_address`,
    OTHER_RETIREMENT_DOOR_NUMBER = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_door-number`,
    OTHER_RETIREMENT_APARTMENT = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_apartment`,
    OTHER_RETIREMENT_TOWER = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_tower`,
    OTHER_RETIREMENT_SQUARE = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_square`,
    OTHER_RETIREMENT_PLOT = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_plot`,
    OTHER_RETIREMENT_EXTRA_DATA = <any>`${COMMON_KEY}_${OTHER_RETIREMENT_KEY}_extra-data`,
    INSURANCE = <any>`hireFinancialProtection`,
}
