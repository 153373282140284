<div class="step content_step step-active in">
  <h1 class="section-step__title animate">
    Para empezar tu solicitud vamos a
    <br />
    necesitar algunos datos personales.
  </h1>
  <div class="row">
    <div class="col-md-6 col-xl-5 position-static">
      <form
        id="personal-data"
        class="form__container"
        [formGroup]="personalData"
        (ngSubmit)="savePersonalData(personalData.value)"
      >
        <oca-form-input formControlName="name_lastName" delay="0.15s" [error]="error1">
          <oca-placeholder>¿Cuál es tu nombre y apellido?</oca-placeholder>
          <oca-error *ngIf="name_lastName.invalid">{{
            errorNameLastName
          }}</oca-error>
        </oca-form-input>

        <oca-form-input formControlName="email" delay="0.20s" [id]="'email'" [error]="error2">
          <oca-placeholder>¿Cuál es tu correo electrónico?</oca-placeholder>
          <oca-hint
            >Recordá que este es el mail dónde recibirás tus próximos estados de
            cuenta.</oca-hint
          >
          <oca-error *ngIf="email.invalid">{{ errorEmail }}</oca-error>
        </oca-form-input>

        <oca-form-input formControlName="phone" delay="0.30s" [id]="'phoneNumber'" [maxlength]="'11'" [error]="error3">
          <oca-placeholder>¿Cuál es tu número de celular?</oca-placeholder>
          <oca-error *ngIf="phone.invalid">{{ errorPhone }}</oca-error>
        </oca-form-input>
        <oca-hint>
          Al continuar aceptás nuestra <a href="https://www.oca.com.uy/download/POLITICA_PRIVACIDAD.pdf" target="_blank">Política de Privacidad</a>.
        </oca-hint>
        <img [src]="image" [alt]="alternative" class="mobile-vitrina-oca">
        <div class="section-step__buttons">
          <app-form-button></app-form-button>
        </div>
      </form>
    </div>
  </div>
</div>
