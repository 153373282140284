<form class="form__container">
    <h1 id="title"><img src="assets/images/icons/insurance.svg" id="logo"><div>Asegurá y protegé tu tarjeta</div></h1>
    <div class="options">
        <div class="item" [ngClass]="{'selected': acceptOption}" (pointerdown)="setInsurance(true)">
            <span class="clickable">
                <input type="radio" name="option" id="acceptOption" #accept>
                <label [ngClass]="{'highlighted': acceptOption}">Agregar seguro</label>
            </span>
            <hr>
            <h1 id="text"><img src="assets/images/icons/insurance.svg" id="logo"><div>Protección financiera</div></h1>
            <div>
                <div class="item-data">
                    <img src="assets/images/icons/insurance_check.svg" id="insuranceData">
                    <label>Protección contra fraudes y usos no autorizados.</label>
                </div>
                <div class="item-data">
                    <img src="assets/images/icons/insurance_check.svg" id="insuranceData">
                    <label>Cobertura por robo del contenido del vehículo.</label>
                </div>
                <div class="item-data">
                    <img src="assets/images/icons/insurance_check.svg" id="insuranceData">
                    <label>Por solo ${{value}} al mes</label>
                </div>
            </div>
            <span id="benefits">
                <a (pointerdown)="showModal($event);">Ver todos los beneficios</a>
            </span>
        </div>
        <div class="item" [ngClass]="{'selected': denyOption}" (pointerdown)="setInsurance(false)">
            <span class="clickable">
                <input type="radio" name="option" id="denyOption" #deny>
                <label [ngClass]="{'highlighted': denyOption}">Continuar sin seguro</label>
            </span>
        </div>
    </div>
    <div class="warning" *ngIf="warning">
        <img src="assets/images/icons/warning.svg" alt="!">
        <p>Debés seleccionar una opción para continuar.</p>
    </div>
    <div class="section-step__buttons">
        <app-back-button-arrow></app-back-button-arrow>
        <span (pointerdown)="next()" class="nextButton">
            <app-form-button [btnDisabled]="insuranceStatus == null"></app-form-button>
        </span>
    </div>
</form>
<div id="insuranceBenefits" *ngIf="modal">
    <div id="modal">
        <span id="closeButton" class="clickable" (pointerdown)="closeModal()"><img src="assets/images/icons/close.svg" alt="X"></span>
        <div class="center">
            <img src="assets/images/insurance_logo.png" alt="Metlife">
        </div>
        <div class="center">
            <h1>Asegurá y protegé tu tarjeta</h1>
        </div>
        <div>
            <span>Cobertura:</span>
            <ul>
                <li>
                    Uso no autorizado y/o fraudulento de la tarjeta de crédito, en transacciones físicas o digitales.
                </li>
                <li>
                    Robo de préstamos o extracciones de dinero en sucursales OCA y/o cajeros automáticos.
                </li>
                <li>
                    Robo del contenido del vehículo.
                </li>
            </ul>
        </div>
        <div>
            <span>Período de cobertura</span>
            <ul>
                <li>
                    Un año con renovación automática.
                </li>
            </ul>
        </div>
        <div>
            <span>Costo</span>
            <ul>
                <li>
                    ${{value}} por mes a cobrar en la tarjeta de crédito OCA.
                </li>
            </ul>
        </div>
        <hr>
        <div id="tyc">
            Al confirmar, estás aceptando las <a href="https://www.oca.com.uy/download/SP_OCA_PROTECCION_FINANCIERA_DIGITAL.PDF" target="_blank">condiciones del seguro de protección de fraude, sus exclusiones</a> y <a href="https://www.oca.com.uy/download/Politica_datos_metlife.pdf" target="_blank">la política de usos de datos personales</a> así como el envío de tus datos a una aseguradora.
        </div>
        <div class="button">
            <span (pointerdown)="closeModal()">x</span>
            <button (pointerdown)="closeModal()">Cerrar</button>
        </div>
    </div>
</div>