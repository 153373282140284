import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { DeliveryMethod } from 'src/solicitud-online/services/api-enums.model';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { cardRequest } from '../../models/user';
import { FormHandlerService } from '../../services/form-handler.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-recibe-card',
  templateUrl: './recibe-card.component.html',
  styleUrls: ['./recibe-card.component.css'],
})
export class RecibeCardComponent implements OnInit {
  constructor(private formService: FormHandlerService,
    private analytics: AnalyticsService, private apiService:ApiService) {}

  recibeCardData!: FormGroup;
  textError: string = '';

  deliveryMehod = DeliveryMethod

  ngOnInit(): void {
    this.recibeCardData = new FormGroup({
      typeOfRecibe: new FormControl('', Validators.required),
    });
    this.formService.getLocalStorageData();
    if (this.formService.cardDeliveryMethod.type != null) {
      let cardDeliveryMethod = this.formService.getDeliveryMethod();
      var { type } = cardDeliveryMethod;
      this.recibeCardData.patchValue({
        typeOfRecibe: type,
      });
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.ComoRecibirLaTarjeta)
  }

  saveRecibeType(form: any) {
    if (this.recibeCardData.valid) {
      var recibeData: cardRequest;
      recibeData = this.getRecibeCardData(form);
      this.formService.recibeCardDataHandler(recibeData);
      this.apiService.apiPatch(this.buildBodyRecibeCard());
    } else {
      this.textError = "Debe seleccionar el método de entrega";
    }
  }

  getRecibeCardData(form: any) {
    let userData: any = {
      typeOfRecibe: form.typeOfRecibe,
    };
    return userData;
  }

  public get f(){
    return this.recibeCardData.controls;
  }

  buildBodyRecibeCard() {
    return {
      CurrentScreen: "PANTALLA-7",
      IsEndFlow: false
    }
  }
}
