<app-header></app-header>
<section class="container section-step sections section-step-active">
    <div>
        <div class="container section-step__container">
            <div class="row">
                <div class="col-lg-8">
                    <label class="title">Para empezar tu solicitud, elegí tu tarjeta.</label>
                    <div class="container">
                        <div class="input-container" [ngClass]="{'selected': isOca}">
                            <label for="cardOCA" class="name clickable">
                                <div class="object">
                                    <label for="cardOCA" class="clickable">
                                        <img for="cardOCA" src="assets/images/icons/mastercard_logo.svg">
                                    </label>
                                    <label for="cardOCA" class="clickable margin">Mastercard de OCA</label>
                                </div>
                                <input type="radio" name="card" id="cardOCA" class="clickable" [value]="isOca" (input)="selectOCA()">
                            </label>
                            <div class="name mobile" (click)="toggleInfoOca()">
                                <p style="padding-top: 10px;">Ver beneficios</p>
                                <p [ngClass]="{'hide': !infoOca}">
                                    <img class="imgChar" src="assets/images/icons/down.svg" alt="↓">
                                </p>
                                <p [ngClass]="{'hide': infoOca}">
                                    <img class="imgChar" src="assets/images/icons/up.svg" alt="↑">
                                </p>
                            </div>
                            <hr class="items">
                            <div class="items">
                                <div class="item" *ngFor="let benefit of master_benefits">
                                    <img src="assets/images/icons/check.svg" alt=">">
                                    <p>
                                        {{benefit}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="input-container mobile" [ngClass]="{'hide': !infoOca}">
                            <div class="item" *ngFor="let benefit of master_benefits">
                                <img src="assets/images/icons/check.svg" alt=">">
                                <p>
                                    {{benefit}}
                                </p>
                            </div>
                        </div>
                        <div class="input-container" [ngClass]="{'selected': isVisa}">
                            <label for="cardVISA" class="name clickable">
                                <div class="object">
                                    <label for="cardVISA" class="clickable">
                                        <img for="cardOCA" src="assets/images/icons/visa_logo.svg">
                                    </label>
                                    <label for="cardVISA" class="clickable margin">Visa de OCA</label>
                                </div>
                                <input type="radio" name="card" id="cardVISA" class="clickable" [value]="isVisa" (input)="selectVISA()">
                            </label>
                            <div class="name mobile" (click)="toggleInfoVisa()">
                                <p style="padding-top: 10px;">Ver beneficios</p>
                                <p [ngClass]="{'hide': !infoVisa}">
                                    <img class="imgChar" src="assets/images/icons/down.svg" alt="↓">
                                </p>
                                <p [ngClass]="{'hide': infoVisa}">
                                    <img class="imgChar" src="assets/images/icons/up.svg" alt="↑">
                                </p>
                            </div>
                            <hr class="items">
                            <div class="items">
                                <div class="item" *ngFor="let benefit of oca_benefits">
                                    <img src="assets/images/icons/check.svg" alt=">">
                                    <p>
                                        {{benefit}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="input-container mobile" [ngClass]="{'hide': !infoVisa}">
                            <div class="item" *ngFor="let benefit of oca_benefits">
                                <img src="assets/images/icons/check.svg" alt=">">
                                <p>
                                    {{benefit}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="warning" *ngIf="warning">
                        <img src="assets/images/icons/warning.svg" alt="!">
                        <p>Debés seleccionar una opción para continuar.</p>
                    </div>
                </div>
                <div class="col-lg-4 d-none d-lg-block col-tarjeta">
                    <div>
                        <div class="img-container">
                            <img src="assets/images/cards/tarjeta-oca.svg" class="vitrina-oca">
                            <img src="assets/images/cards/tarjeta-visa.svg" class="vitrina-oca">
                        </div>
                        <ul class="list-icon mt-4">
                            <li class="list-icon__item">
                                <div><img src="assets/images/icons/comercios.svg" class="list-icon__icon"></div>
                                Beneficios y promociones todos los días
                            </li>
                            <li class="list-icon__item">
                                <div><img src="assets/images/icons/telepeaje.svg" class="list-icon__icon"></div>
                                Adherí tu telepeaje
                            </li>
                            <li class="list-icon__item">
                                <div><img src="assets/images/icons/entradas.svg" class="list-icon__icon"></div>
                                Financiá tus compras
                            </li>
                            <li class="list-icon__item">
                                <div><img src="assets/images/icons/beneficios.svg" class="list-icon__icon"></div>
                                Internacional para compras presenciales o por web
                            </li>
                        </ul>
                    
                        <a href="https://www.oca.com.uy/" target="_blank" class="link-conoce-mas d-inline-flex mt-4">Conocé más beneficios acá</a>
                    </div>
                </div>
            </div>
            <div class="next-button">
                <button class="btn" [disabled]="selectedCard === undefined" (click)="next()">
                    Siguiente
                </button>
            </div>
        </div>
    </div>
</section>