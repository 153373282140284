import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddressComponent } from './pages/address/address.component';
import { CivilStatusComponent } from './pages/civil-status/civil-status.component';
import { ConfirmDataComponent } from './pages/confirm-data/confirm-data.component';
import { EmploymentComponent } from './pages/employment/employment.component';
import { ErrorSectionComponent } from './pages/error-section/error-section.component';
import { FinalSectionComponent } from './pages/final-section/final-section.component';
import { IdentificationCardChipComponent } from './pages/identification-card-chip/identification-card-chip.component';
import { IdentificationCardTraditionalComponent } from './pages/identification-card-traditional/identification-card-traditional.component';
import { IdentificationCardTypeComponent } from './pages/identification-card-type/identification-card-type.component';
import { OfficeRetirementOtherAddressComponent } from './pages/office-retirement-other-address/office-retirement-other-address.component';
import { OfficeRetirementComponent } from './pages/office-retirement/office-retirement.component';
import { PersonalDataComponent } from './pages/personal-data/personal-data.component';
import { RecibeCardComponent } from './pages/recibe-card/recibe-card.component';
import { SolicitudOnlineComponent } from './solicitud-online.component';
import { InsuranceComponent } from './pages/insurance/insurance.component';
import { MaintenanceSectionComponent } from './pages/maintenance-section/maintenance-section.component';

const routes: Routes = [
  {
    path: '',
    component: SolicitudOnlineComponent,
    children: [
      {
        path: 'personalData',
        component: PersonalDataComponent,
        outlet: 'solicitud',
      },
      {
        path: 'identificationCardType',
        component: IdentificationCardTypeComponent,
        outlet: 'solicitud',
      },
      {
        path: 'traditionalCard',
        component: IdentificationCardTraditionalComponent,
        outlet: 'solicitud',
      },
      {
        path: 'chipCard',
        component: IdentificationCardChipComponent,
        outlet: 'solicitud',
      },
      {
        path: 'civilStatus',
        component: CivilStatusComponent,
        outlet: 'solicitud',
      },
      {
        path: 'employment',
        component: EmploymentComponent,
        outlet: 'solicitud',
      },
      { path: 'address', component: AddressComponent, outlet: 'solicitud' },
      {
        path: 'recibeCard',
        component: RecibeCardComponent,
        outlet: 'solicitud',
      },
      {
        path: 'officeRetirement',
        component: OfficeRetirementComponent,
        outlet: 'solicitud',
      },
      {
        path: 'confirmData',
        component: ConfirmDataComponent,
        outlet: 'solicitud',
      },
      {
        path: 'otherRetirementDirection',
        component: OfficeRetirementOtherAddressComponent,
        outlet: 'solicitud',
      },
      {
        path: 'insurance',
        component: InsuranceComponent,
        outlet: 'solicitud',
      }
    ],
  },
  { path: 'finalSection', component: FinalSectionComponent },
  { path: 'errorSection', component: ErrorSectionComponent },
  { path: 'maintenanceSection', component: MaintenanceSectionComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SolicitudOnlineRoutingModule {}
