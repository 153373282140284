import { AfterViewInit, Component, ContentChild, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MonthlyEarningDirective } from 'src/solicitud-online/directives/monthly-earning.directive';
import { FormatterService } from 'src/solicitud-online/services/formatter.service';
import { OcaPlaceholderComponent } from '../oca-placeholder/oca-placeholder.component';

@Component({
  selector: 'oca-form-input',
  templateUrl: './oca-form-input.component.html',
  styleUrls: ['./oca-form-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OcaFormInputComponent),
      multi: true,
    },
  ],
})
export class OcaFormInputComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  @ContentChild(OcaPlaceholderComponent) placeholder!: OcaPlaceholderComponent;

  @ContentChild(MonthlyEarningDirective, { static: false })
  formatter!: MonthlyEarningDirective;

  @Input()
  delay = '0.15s';

  @Input()
  id: string = '';

  @Input()
  type: string = 'text';

  @Input()
  error: boolean = false;

  @Input()
  maxlength: string = '50';

  @Input()
  applyFormat: boolean = false;

  value: string = '';
  disabled = false;
  onChange: any = () => {};
  onTouched: any = () => {};
  isFocused = false;
  formattedValue!: string;

  constructor (private formatterService: FormatterService) {}

  ngOnInit(): void {
    this.onFocusOut();
  }

  ngAfterViewInit(): void {
    this.placeholder.clicked.subscribe(() => {
      this.input.nativeElement.focus()
    });
  }

  ngOnDestroy(): void {
    this.placeholder.clicked.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.value == '0' && this.type == 'number') {
      this.value = '';
    }
    if (this.applyFormat) {
      this.formattedValue = this.format(this.value)
    }
    this.onFocusOut();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocus() {
    this.isFocused = true;
  }

  onFocusOut() {
    this.isFocused = this.value === '' ? false : true;
    this.error = false;
  }

  onChanged(event: any) {
    this.value = event.target.value;
    this.onChange(this.value);
  }

  onKeyDown(event: any) {
    if (
      this.isNumber(event.key) &&
      this.type === 'number' &&
      new Number(this.maxlength) <=
        new Number(this.input.nativeElement.value.length)
    ) {
      event.preventDefault();
    }
  }

  onInput(event: any) {
    if (['direction', 'tower', 'square', 'plot', 'extraData'].includes(this.id)) {
      const cleanedValue = this.formatterService.extractAlphanumeric(event.target.value);
      if (event.target.value !== cleanedValue) {
        event.target.value = cleanedValue;
      }
    }
  }

  ciEventModel(event: any, input: HTMLInputElement) {
    this.setValue(event);
    let unformattedValue;
    let maxLength;
    let currentValue;
    switch (this.id) {
      case 'ci':
        maxLength = 8;
        unformattedValue = this.formatterService.unformatCi(event);
        currentValue = unformattedValue.length <= maxLength ? unformattedValue : unformattedValue.slice(0, maxLength);
        this.value = this.formatterService.formatCi(currentValue);
        input.value = this.value;
        break;
      case 'doorNumber':
        maxLength = 9;
        unformattedValue = this.formatterService.unformatCi(event);
        currentValue = unformattedValue.length <= maxLength ? unformattedValue : unformattedValue.slice(0, maxLength);
        this.value = currentValue;
        input.value = this.value;
        break;
      case 'phoneNumber':
        maxLength = 9;
        unformattedValue = this.formatterService.unformatPhone(event);
        currentValue = unformattedValue.length <= maxLength ? unformattedValue : unformattedValue.slice(0, maxLength);
        this.value = this.formatterService.formatPhone(currentValue);
        input.value = this.value;
        break;
      case 'email':
        currentValue = event;
        this.value = this.formatterService.formatEmail(currentValue);
        input.value = this.value;
        break;
      case 'direction':
        maxLength = 60;
        currentValue = this.formatterService.extractAlphanumeric(event);
        this.value = currentValue;
        input.value = this.value;
        break;
      case 'apartment':
        maxLength = 10;
        unformattedValue = this.formatterService.unformatCi(event);
        currentValue = unformattedValue.length <= maxLength ? unformattedValue : unformattedValue.slice(0, maxLength);
        this.value = currentValue;
        input.value = this.value;
        break;
      case 'tower':
        maxLength = 30;
        currentValue = this.formatterService.extractAlphanumeric(event);
        this.value = currentValue;
        input.value = this.value;
        break;
      case 'square':
        maxLength = 5;
        currentValue = this.formatterService.extractAlphanumeric(event);
        this.value = currentValue;
        input.value = this.value;
        break;
      case 'plot':
        maxLength = 5;
        currentValue = this.formatterService.extractAlphanumeric(event);
        this.value = currentValue;
        input.value = this.value;
        break;
      case 'extraData':
        maxLength = 50;
        currentValue = this.formatterService.extractAlphanumeric(event);
        this.value = currentValue;
        input.value = this.value;
        break;
      default:
        break;
    }
  }

  setValue(num: any) {
    if (this.applyFormat) {
      let numValueFormatted = this.format(num);
      this.formattedValue = numValueFormatted;
      this.value = num
      this.onChange(this.formattedValue);
    } else {
      this.value = num
      this.onChange(this.value);
    }
  }

  private isNumber(str: any) {
    return /^\d+$/.test(str);
  }

  private format(numWithOutPoint: any): string {
    let num = numWithOutPoint.toString().replaceAll('.', '');
    if (!num || num == 'NaN') return '-';
    if (num == 'Infinity') return '&#x221e;';
    num = num.toString().replace(/\$|\,/g, '');
    if (isNaN(num)) num = '0';
    num = Math.floor(num * 100 + 0.50000000001);
    let cents: any = num % 100;
    num = Math.floor(num / 100).toString();
    if (cents < 10) cents = '0' + cents;
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        '.' +
        num.substring(num.length - (4 * i + 3));
    return num;
  }

}
