<div class="step content_step step-active in">
  <h1 class="section-step__title animate">¿Cuál es tu ocupación actual?</h1>
  <form
    id="employment-data"
    class="form__container"
    [formGroup]="employmentData"
    (ngSubmit)="saveEmployment(employmentData.value)"
  >
    <div class="row">
      <div class="col-lg-8">
        <div class="row no-gutters">
          <div class="col-lg-6" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_ama_de_casa"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Ama_De_Casa) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Ama de Casa</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_ama_de_casa"
                  name="employment"
                  [value]="userJobCategory.Ama_De_Casa"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_comerciante"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Comerciante) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Comerciante</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_comerciante"
                  name="employment"
                  [value]="userJobCategory.Comerciante"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_empleado_publico"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Empleado_Publico) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Empleado Público</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_empleado_publico"
                  name="employment"
                  [value]="userJobCategory.Empleado_Publico"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_empleado_privado"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Empleado_Privado) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Empleado Privado</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_empleado_privado"
                  name="employment"
                  [value]="userJobCategory.Empleado_Privado"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_empresario"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Empresario) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Empresario</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_empresario"
                  name="employment"
                  [value]="userJobCategory.Empresario"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_estudiante"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Estudiante) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Estudiante</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_estudiante"
                  name="employment"
                  [value]="userJobCategory.Estudiante"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_jubilado"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Jubilado) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Jubilado</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_jubilado"
                  name="employment"
                  [value]="userJobCategory.Jubilado"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_profesional_independiente"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Profesional_Independiente) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Profesional Independiente</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_profesional_independiente"
                  name="employment"
                  [value]="userJobCategory.Profesional_Independiente"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_rentista"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Rentista) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Rentista</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_rentista"
                  name="employment"
                  [value]="userJobCategory.Rentista"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_unipersonal"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Unipersonal) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Unipersonal</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_unipersonal"
                  name="employment"
                  [value]="userJobCategory.Unipersonal"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                for="ocupacion_abogado_contador_escribano"
                class="button-radio button-radio--size-2 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Abogado_Contador_Escribano) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Abogado, Contador o Escribano</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_abogado_contador_escribano"
                  name="employment"
                  [value]="userJobCategory.Abogado_Contador_Escribano"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
            </div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0" >
            <div
              class="form-group mb-0 animate"
              style="transition-delay: 0.15s"
            >
              <label
                id="datospersona_actividadlaboral"
                for="ocupacion_otra_actividad"
                class="button-radio button-radio--size-2 ml-lg-3 mt-lg-3"
                [ngClass]="(employment.value == userJobCategory.Otra_Actividad) ? 'focused' : 'noFocused'"
              >
                <span class="button-radio__text">Otra Actividad</span>
                <input
                  type="radio"
                  class="button-radio__input"
                  id="ocupacion_otra_actividad"
                  name="employment"
                  [value]="userJobCategory.Otra_Actividad"
                  formControlName="employment"
                  (click)="showMonthlyEarning()"
                />
                <span class="button-radio__circle"></span>
              </label>
              <div *ngIf="employment.invalid" class="form-error">
                {{ textError }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="showMonthlyEarningForm">
      <div class="col-md-6 col-xl-5 position-static">
        <!-- Opción Extra de Empleado (remove 'd-none') -->
        <div id="box_datospersona_actividadlaboral" class="mt-5">
          <h1 class="section-step__title animate">
            ¿Cuál es tu ingreso mensual líquido en pesos uruguayos?
          </h1>
          <oca-form-input
            delay="0.15s"
            type="number"
            formControlName="monthlyEarning"
            [error]="error"
            [applyFormat]=true
          >
            >
            <!-- <app-monthly-earning></app-monthly-earning> -->
            <oca-placeholder>Ingreso mensual líquido</oca-placeholder>
            <oca-error *ngIf="error == true || monthlyEarning.invalid">
              <p [innerHTML]="monthlyEarningError" style="font-size: 100%;">
            </p></oca-error>
          </oca-form-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xl-5 position-static">
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </div>
    </div>
  </form>
</div>
