import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent implements OnInit {
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case '/personalData':
            this.progress = 'width: 10%';
            break;
          case '/identificationCardType':
            this.progress = 'width: 20%';
            break;
          case '/traditionalCard':
            this.progress = 'width: 30%';
            break;
          case '/chipCard':
            this.progress = 'width: 30%';
            break;
          case '/civilStatus':
            this.progress = 'width: 40%';
            break;
          case '/employment':
            this.progress = 'width: 50%';
            break;
          case '/address':
            this.progress = 'width: 60%';
            break;
          case '/recibeCard':
            this.progress = 'width: 70%';
            break;
          case '/officeRetirement':
            this.progress = 'width: 80%';
            break;
          case '/otherRetirementDirection':
            this.progress = 'width: 80%';
            break;
          case '/confirmData':
            this.progress = 'width: 90%';
            break;
          default:
            break;
        }
      }
    });
  }

  progress!: string;

  ngOnInit(): void {}
}
