import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { cardRequest } from '../../models/user';
import { FormHandlerService } from '../../services/form-handler.service';
import { UserCivilStatusType } from 'src/solicitud-online/services/api-enums.model';
import { AnalyticsService, AnalyticsPages } from 'src/solicitud-online/analytics.service';
import { ApiService } from 'src/solicitud-online/services/api.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-civil-status',
  templateUrl: './civil-status.component.html',
  styleUrls: ['./civil-status.component.css'],
})
export class CivilStatusComponent implements OnInit {
  constructor(
    private router: Router,
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService
  ) {}

  civilStatusData!: FormGroup;
  dnispouse: Boolean = false;
  textDNIerror: string = '';
  textErrorEstadoCivil: string = '';
  error: boolean = false;

  userCivilStatusType = UserCivilStatusType

  ngOnInit(): void {
    this.civilStatusData = new FormGroup({
      civilStatus: new FormControl('', Validators.required),
      dniSpouse: new FormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(11),
      ]),
    });
    this.formService.getLocalStorageData();
    if (this.formService.userCivilStatus.type != null) {
      let requestData = this.formService.getCivilStatusData();
      this.civilStatusData.patchValue({
        civilStatus: requestData.type,
        dniSpouse: requestData.spouseIdentificationCard != undefined ? requestData.spouseIdentificationCard : '',
      });
      if (requestData.type !== undefined && ['1','2'].includes(requestData.type)){
        this.dnispouse = true;
      }
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.EstadoCivil)
  }

  public get f() {
    return this.civilStatusData.controls;
  }

  get civilStatus(): AbstractControl {
    return this.civilStatusData.controls.civilStatus;
  }

  get dniSpouse(): AbstractControl {
    return this.civilStatusData.controls.dniSpouse;
  }

  saveCivilStatus(form: any) {
    if (
      this.dnispouse == true &&
      this.dniSpouse.value.length > 0 &&
      this.DocumentNumberValidation(form.dniSpouse) == false
    ) {
      this.textDNIerror = 'Debe ingresar un documento válido';
    } else if (this.civilStatusData.valid) {
      var civilStatus: cardRequest = this.getcivilStatus(form);
      this.formService.civilStatusData(civilStatus);
      this.apiService.apiPatch(this.buildBodyCivilStatus());
    } else if (this.civilStatus.valid && this.dnispouse == false) {
      var civilStatus: cardRequest = this.getcivilStatus(form);
      this.formService.civilStatusData(civilStatus);
      this.apiService.apiPatch(this.buildBodyCivilStatus());
    } else {
      this.getDNIerror();
    }

    if (this.dniSpouse.invalid) {
      this.error = true;
    }
  }

  private getcivilStatus(form: any): cardRequest {
    let userData: any = {
      civilStatus: form.civilStatus,
      dniSpouse: ['1','2'].includes(form.civilStatus) ? form.dniSpouse : '',
    };
    return userData;
  }

  showDniSpouse() {
    this.dnispouse = true;
  }

  hideDniSpouse() {
    this.dnispouse = false;
  }

  getDNIerror() {
    if (this.civilStatus.invalid) {
      this.textErrorEstadoCivil = 'Debe ingresar su estado civil';
    }

    if (this.dnispouse) {
      if (this.dniSpouse.value.length == 0) {
        this.textDNIerror = 'Debe ingresar el documento de su cónyuge';
      } else if (
        this.dniSpouse.value.length <= 6 ||
        this.dniSpouse.value.length >= 10
      ) {
        this.textDNIerror = 'Debe ingresar un documento válido';
      }
    }
  }

  DocumentNumberValidation(documentNumber: string): boolean {
    let valid = false;
    documentNumber = documentNumber.replace(/[^0-9]/g, '');
    if (documentNumber) {
      if (documentNumber.length === 7) {
        documentNumber = '0' + documentNumber;
      }
      let chd1;
      let t1, t2;
      let a1, a2, a3, a4, a5, a6, a7, a8;
      let b1, b2, b3, b4, b5, b6, b7;
      if (documentNumber.length === 8) {
        a1 = Math.floor(Number(documentNumber) / 10000000);
        a2 = Math.floor(Number(documentNumber) / 1000000) - a1 * 10;
        a3 = Math.floor(Number(documentNumber) / 100000) - (a1 * 100 + a2 * 10);
        a4 =
          Math.floor(Number(documentNumber) / 10000) -
          (a1 * 1000 + a2 * 100 + a3 * 10);
        a5 =
          Math.floor(Number(documentNumber) / 1000) -
          (a1 * 10000 + a2 * 1000 + a3 * 100 + a4 * 10);
        a6 =
          Math.floor(Number(documentNumber) / 100) -
          (a1 * 100000 + a2 * 10000 + a3 * 1000 + a4 * 100 + a5 * 10);
        a7 =
          Math.floor(Number(documentNumber) / 10) -
          (a1 * 1000000 +
            a2 * 100000 +
            a3 * 10000 +
            a4 * 1000 +
            a5 * 100 +
            a6 * 10);
        a8 =
          Math.floor(Number(documentNumber) / 1) -
          (a1 * 10000000 +
            a2 * 1000000 +
            a3 * 100000 +
            a4 * 10000 +
            a5 * 1000 +
            a6 * 100 +
            a7 * 10);
        b1 = (a1 * 2) % 10;
        b2 = (a2 * 9) % 10;
        b3 = (a3 * 8) % 10;
        b4 = (a4 * 7) % 10;
        b5 = (a5 * 6) % 10;
        b6 = (a6 * 3) % 10;
        b7 = (a7 * 4) % 10;
        t1 = b1 + b2 + b3 + b4 + b5 + b6 + b7;
        t2 = t1 % 10;
        chd1 = Math.abs(t2 - 10);
        chd1 = chd1 % 10;
        valid = chd1 === a8;
      }
    }
    return valid;
  }

  buildBodyCivilStatus() {
    return {
      CurrentScreen: "PANTALLA-4",
      IsEndFlow: false
    }
  }
}
