import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormHandlerService } from './services/form-handler.service';
import { Router } from '@angular/router';
import { ParamService } from 'src/swagger-client';
import { CardDataFlag, CardDataFlagDescription } from './services/api-enums.model';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-solicitud-online',
  templateUrl: './solicitud-online.component.html',
  styleUrls: ['./solicitud-online.component.css'],
})
export class SolicitudOnlineComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private formService: FormHandlerService,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private paramsService: ParamService,
    public loaderService: LoaderService,
  ) {
    this.document.body.classList.remove('landing');
  }

  paramValues = new Map()
    .set('oca', CardDataFlag.OCA)
    .set('visa', CardDataFlag.VISA)

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['card'] && queryParams['origen']) {
        this.formService.setCardName(queryParams['card'].toLowerCase());
        CardDataFlagDescription.get(queryParams['card'])

        let arr = [...CardDataFlagDescription.entries()]
                    .filter( (entry:[number, string]) => { 
                      return entry[1].toUpperCase() === queryParams['card'].toUpperCase()
                    })
                    .map((entry:[number, string]) => entry[0]);;
                    
        this.formService.cardType(arr.length == 1 ? arr[0] : undefined);
        let origin = queryParams['origen'];
        let kpid = queryParams['kpid'] ? "&" + queryParams['kpid'] : "";
        if (kpid.length === 0) {
          kpid = queryParams['KPID'] ? "&" + queryParams['KPID'] : "";
        }
        if (kpid.length > 1) {
          origin += kpid;
        }
        this.formService.origin = origin;
      }
    })

    this.paramsService.getParamRecaptcha().subscribe( response => {
      let key = response.siteKey ? response.siteKey : '';
      this.formService.setCaptchaSiteKey(key)
      this.router.navigate(['solicitud', {
        outlets: {
          'solicitud': 'personalData'
        }
      }]);
    },
    error => {
      this.router.navigate(['errorSection']);
    })

  }
  
}


