<div class="step content_step content_step_10 step-active in" data-step="10" data-stepname="RetiroDeTarjeta"
  data-stephash="RetiroDeTarjeta" data-transactionstep="10" data-percent="80">
  <h1 class="section-step__title animate">¿Cuál es la dirección de entrega?</h1>

  <div class="row">
    <div class="col-md-7 col-xl-6 position-static">
      <form id="otherOffice-data" class="form__container" [formGroup]="otherOfficeRetirement"
        (ngSubmit)="saveOtherDirection(otherOfficeRetirement.value)">
        <div class="form-group">
          <div class="selectric-div animate" style="transition-delay: 0.15s">
            <label class="form-label form-label--select" for="direccionentrega_departamento">¿En qué departamento
              es?</label>
            <select id="direccionentrega_departamento" name="direccionentrega_departamento" class="form-control"
              (change)="onSelect($event)" formControlName="departament"
              [ngClass]="(textErrorDepartamento && f.departament.invalid) ? 'errorBorder' : 'noFocused'">
              <option value="">Seleccionar</option>
              <option *ngFor="let departament of departaments" value="{{ departament }}">
                {{ departament }}
              </option>
            </select>
          </div>
          <div *ngIf="f.departament.invalid" class="form-error">
            {{ textErrorDepartamento }}
          </div>
        </div>
        <ng-template [ngIf]="departmentFlag">
          <ng-template [ngIf]="!isMontevideo">
            <div class="form-group" *ngIf="localityFlag">
              <div class="selectric-div">
                <label class="form-label form-label--select" for="direccionentrega_localidad">¿En qué localidad
                  es?</label>
                <select id="direccionentrega_localidad" name="direccionentrega_localidad" class="form-control"
                  (change)="localityOnSelect()" formControlName="locality"
                  [ngClass]="(textErrorLocalidad && f.locality.invalid) ? 'errorBorder' : 'noFocused'">
                  <option value="">Seleccionar</option>
                  <option *ngFor="let locality of localities[0]" value="{{ locality }}">
                    {{ locality }}
                  </option>
                </select>
              </div>
              <div *ngIf="f.locality.invalid" class="form-error">
                {{ textErrorLocalidad }}
              </div>
            </div>
            <div class="form-group" *ngIf="directionFlag">
              <oca-form-input delay="0.30s" formControlName="retirement_direction" [id]="'direction'"
                [maxlength]="'60'" [error]="error">
                <oca-placeholder>¿Cuál es la calle?</oca-placeholder>
                <oca-error *ngIf="f.retirement_direction.invalid">{{ textErrorDireccion }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="doorNumberFlag">
              <oca-form-input delay="0.30s" formControlName="retirement_door_number" [id]="'doorNumber'" [type]="'number'" [maxlength]="'9'" [error]="errorDoorNumber">
                <oca-placeholder>¿Cuál es el número de puerta?</oca-placeholder>
                <oca-error *ngIf="f.retirement_door_number.invalid">{{ textErrorDoorNumber }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="apartmentFlag">
              <oca-form-input formControlName="retirement_apartment" delay="0.30s" [id]="'apartment'" [type]="'number'" [maxlength]="'10'">
                <oca-placeholder>Apartamento <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="towerFlag">
              <oca-form-input formControlName="retirement_tower" delay="0.30s" [id]="'tower'" [maxlength]="'30'">
                <oca-placeholder>Torre <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group splitted" *ngIf="squareFlag && plotFlag">
              <oca-form-input class="square" formControlName="retirement_square" delay="0.30s" [id]="'square'" [maxlength]="'5'">
                <oca-placeholder>Manzana <span class="optional"></span></oca-placeholder>
              </oca-form-input>
              <oca-form-input class="plot" formControlName="retirement_plot" delay="0.30s" [id]="'plot'" [maxlength]="'5'">
                <oca-placeholder>Solar <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="extraDataFlag">
              <oca-form-input formControlName="retirement_extra_data" delay="0.30s" [id]="'extraData'" [maxlength]="'50'">
                <oca-placeholder>Datos adicionales <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
          </ng-template>
          <ng-template [ngIf]="isMontevideo">
            <div class="form-group" *ngIf="localityFlag">
              <div class="selectric-div">
                <label class="form-label form-label--select" for="direccionentrega_localidad">¿En qué localidad
                  es?</label>
                <select id="direccionentrega_localidad" name="direccionentrega_localidad" class="form-control"
                  (change)="localityOnSelect()" formControlName="locality"
                  [ngClass]="(textErrorLocalidad && f.locality.invalid) ? 'errorBorder' : 'noFocused'">
                  <option value="">Seleccionar</option>
                  <option *ngFor="let locality of localities[0]" value="{{ locality }}">
                    {{ locality }}
                  </option>
                </select>
              </div>
              <div *ngIf="f.locality.invalid" class="form-error">
                {{ textErrorLocalidad }}
              </div>
            </div>
            <div class="form-group" *ngIf="directionFlag">
              <oca-form-input delay="0.30s" formControlName="retirement_direction" [id]="'direction'" [maxlength]="'60'" [error]="error">
                <oca-placeholder>¿Cuál es la dirección?</oca-placeholder>
                <oca-error *ngIf="f.retirement_direction.invalid">{{ textErrorDireccion }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="doorNumberFlag">
              <oca-form-input delay="0.30s" formControlName="retirement_door_number" [id]="'doorNumber'" [maxlength]="'9'" [error]="errorDoorNumber">
                <oca-placeholder>¿Cuál es el número de puerta?</oca-placeholder>
                <oca-error *ngIf="f.retirement_door_number.invalid">{{ textErrorDoorNumber }}</oca-error>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="apartmentFlag">
              <oca-form-input formControlName="retirement_apartment" delay="0.30s" [id]="'apartment'" [type]="'number'" [maxlength]="'10'">
                <oca-placeholder>Apartamento <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="towerFlag">
              <oca-form-input formControlName="retirement_tower" delay="0.30s" [id]="'tower'" [maxlength]="'30'">
                <oca-placeholder>Torre <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
            <div class="form-group" *ngIf="extraDataFlag">
              <oca-form-input formControlName="retirement_extra_data" delay="0.30s" [id]="'extraData'" [maxlength]="'50'">
                <oca-placeholder>Datos adicionales <span class="optional"></span></oca-placeholder>
              </oca-form-input>
            </div>
          </ng-template>
        </ng-template>
        <div class="section-step__buttons">
          <app-back-button-arrow></app-back-button-arrow>
          <app-form-button></app-form-button>
        </div>
      </form>
    </div>
  </div>
</div>
