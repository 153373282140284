import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { CardDataFlagDescription, DeliveryMethod, DeliveryMethodDescription, UserCivilStatusTypeDescription, UserJobCategoryDescription } from 'src/solicitud-online/services/api-enums.model';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { FormHandlerService } from '../../services/form-handler.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-confirm-data',
  templateUrl: './confirm-data.component.html',
  styleUrls: ['./confirm-data.component.css'],
})
export class ConfirmDataComponent implements OnInit {
  @ViewChild('captchaElem', { static: false }) captchaElem!: InvisibleReCaptchaComponent;
  constructor(
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService
  ) {}
  finishBtnFlag: boolean = true;
  siteKey: string = '';
  cardData!: any;
  name_lastName!: any;
  email!: any;
  phone!: any;
  dni!: any;
  civilStatus_data!: any;
  employment!: any;
  monthlyEarning!: any;
  departament!: any;
  locality!: any;
  direction!: any;
  doorNumber!: any;
  apartment!: any;
  tower!: any;
  square!: any;
  plot!: any;
  extraData!: any;
  recibeCard!: any;
  retirementDepartament!: any;
  retirementLocality!: any;
  retirementDirection!: any;
  retirementDoorNumber!: any;
  retirementApartment!: any;
  retirementTower!: any;
  retirementSquare!: any;
  retirementPlot!: any;
  retirementExtraData!: any;
  retirementFlag!: any;
  dniSpouseFlag!: any;
  dniSpouseData!: any;
  hireFinancialProtection?: boolean;

  recaptcha: string = '';
  badge: any;
  type: any;
  theme: any;

  ngOnInit(): void {
    this.siteKey = this.formService.getCaptchaSiteKey();
    this.hireFinancialProtection = this.formService.hireFinancialProtection;
    var { fullName, email, phone } = this.formService.getUserData();
    var { flag } = this.formService.getCardData();
    var { number } = this.formService.getIdentificationCardData();
    var { type, spouseIdentificationCard } =
      this.formService.getCivilStatusData();
    var { category, monthlyIncome } = this.formService.getUserJobData();
    var { department, locality, address, doorNumber, apartment, tower, square, plot, extraData } = this.formService.getAddressData();
    var deliveryMethod = this.formService.getDeliveryMethod();
    var { deliveryAddress } = this.formService.getDeliveryMethod();
    this.dniSpouseFlag = false;
    this.cardData = CardDataFlagDescription.get(flag);
    this.name_lastName = fullName;
    this.email = email;
    this.phone = phone;
    this.dni = number;
    this.civilStatus_data = UserCivilStatusTypeDescription.get(type);
    this.employment = UserJobCategoryDescription.get(category);
    this.monthlyEarning = monthlyIncome;
    this.departament = department;
    this.locality = locality;
    this.direction = address;
    this.doorNumber = doorNumber;
    this.apartment = apartment;
    this.tower = tower;
    this.square = square;
    this.plot = plot;
    this.extraData = extraData;
    this.direction = this.buildDirection();
    this.recibeCard = DeliveryMethodDescription.get(deliveryMethod.type);
    this.dniSpouseData = spouseIdentificationCard;
    if (deliveryMethod.type == DeliveryMethod.OtraDireccion) {
      this.retirementDepartament = deliveryAddress?.department;
      this.retirementLocality = deliveryAddress?.locality;
      this.retirementDirection = deliveryAddress?.address;
      this.retirementDoorNumber = deliveryAddress?.doorNumber;
      this.retirementApartment = deliveryAddress?.apartment;
      this.retirementTower = deliveryAddress?.tower;
      this.retirementSquare = deliveryAddress?.square;
      this.retirementPlot = deliveryAddress?.plot;
      this.retirementExtraData = deliveryAddress?.extraData;
      this.retirementDirection = this.buildRetirementDirection();
      this.retirementFlag = true;
    } else {
      this.retirementFlag = false;
    }
    if (this.dniSpouseData != '') {
      this.dniSpouseData = spouseIdentificationCard;
      this.dniSpouseFlag = true;
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.RetiroDeTarjetaConfirmacion)
  }

  sendRequestData() {
    this.captchaElem.execute();
    this.apiService.apiPatch(this.buildBodyConfirmData());
  }

  handleSuccess(event: any) {
    this.formService.setRecaptchaCode(event);
    this.formService.sendRequestCompleteData();
    this.finishBtnFlag = false;
  }

  handleExpire() {
    this.recaptcha = ''
    this.finishBtnFlag = false;
  }

  handleReset(){
    this.finishBtnFlag = false;
  }

  handleReady(){
    this.finishBtnFlag = true;
  }

  handleError(){
    this.finishBtnFlag = false;
  }

  buildBodyConfirmData() {
    return {
      CurrentScreen: "PANTALLA-FINAL",
      IsEndFlow: true
    }
  }

  private buildDirection(): string {
    let direction = this.direction;
    if (this.doorNumber != null && this.doorNumber.trim() !== '') {
      direction = direction + ' ' + this.doorNumber;
    }
    if (this.apartment != null && this.apartment.trim() !== '') {
      direction = direction + ' ' + this.apartment;
    }
    if (this.tower != null && this.tower.trim() !== '') {
      direction = direction + ' ' + this.tower;
    }
    if (this.square != null && this.square.trim() !== '') {
      direction = direction + ' ' + this.square;
    }
    if (this.plot != null && this.plot.trim() !== '') {
      direction = direction + ' ' + this.plot;
    }
    if (this.extraData != null && this.extraData.trim() !== '') {
      direction = direction + ' ' + this.extraData;
    }
    return direction;
  }

  private buildRetirementDirection(): string {
    let direction = this.retirementDirection;
    if (this.retirementDoorNumber != null && this.retirementDoorNumber.trim() !== '') {
      direction = direction + ' ' + this.retirementDoorNumber;
    }
    if (this.retirementApartment != null && this.retirementApartment.trim() !== '') {
      direction = direction + ' ' + this.retirementApartment;
    }
    if (this.retirementTower != null && this.retirementTower.trim() !== '') {
      direction = direction + ' ' + this.retirementTower;
    }
    if (this.retirementSquare != null && this.retirementSquare.trim() !== '') {
      direction = direction + ' ' + this.retirementSquare;
    }
    if (this.retirementPlot != null && this.retirementPlot.trim() !== '') {
      direction = direction + ' ' + this.retirementPlot;
    }
    if (this.retirementExtraData != null && this.retirementExtraData.trim() !== '') {
      direction = direction + ' ' + this.retirementExtraData;
    }
    return direction;
  }
}
