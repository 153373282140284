import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { FormHandlerService } from '../../services/form-handler.service';
import { AnalyticsService, AnalyticsPages } from 'src/solicitud-online/analytics.service';
import { ApiService } from 'src/solicitud-online/services/api.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-identification-card-chip',
  templateUrl: './identification-card-chip.component.html',
  styleUrls: ['./identification-card-chip.component.css'],
})
export class IdentificationCardChipComponent implements OnInit {
  constructor(
    private router: Router,
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService
  ) {}

  chipCardData!: FormGroup;
  textError: string = '';
  error: boolean = false;

  ngOnInit(): void {
    this.chipCardData = new FormGroup({
      securityCode: new FormControl('', [Validators.required, Validators.maxLength(6)]),
    });
    if (this.formService.userIdentificationCard.securityCode != null) {
      let dataRequest = this.formService.getIdentificationCardData();
      var { securityCode } = dataRequest;
      this.chipCardData.patchValue({
        securityCode: securityCode,
      });
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.DatosCedulaChip)
  }

  saveChipCardData(form: any) {
    if (this.chipCardData.valid) {
      var cardData = this.getChipCardData(form);
      this.formService.chipCardData(cardData);
      this.apiService.apiPatch(this.buildBodyCardChip());
    } else {
      this.error = true;
      this.textError = "Debe ingresar el código de seguridad"
    }
  }

  getChipCardData(form: any) {
    let userData: any = {
      securityCode: form.securityCode,
    };

    return userData;
  }

  get securityCode(): AbstractControl {
    return this.chipCardData.controls.securityCode;
  }

  buildBodyCardChip() {
    return {
      CurrentScreen: "PANTALLA-3",
      IsEndFlow: false
    }
  }
}
