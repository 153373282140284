import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormHandlerService } from 'src/solicitud-online/services/form-handler.service';

@Component({
  selector: 'app-select-card-landing',
  templateUrl: './select-card-landing.component.html',
  styleUrls: ['./select-card-landing.component.css']
})
export class SelectCardLandingComponent implements OnInit {

  constructor(
    private formService: FormHandlerService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['origen']) {
        let origin = queryParams['origen'];
        let kpid = queryParams['kpid'] ? "&" + queryParams['kpid'] : "";
        if (kpid.length === 0) {
          kpid = queryParams['KPID'] ? "&" + queryParams['KPID'] : "";
        }
        if (kpid.length > 1) {
          origin += kpid;
        }
        this.formService.origin = origin;
        console.log(origin);
      }
    });
    this.router.navigate(['card', {
      outlets: {
        'card': 'selectCard'
      }
    }]);
  }

}
