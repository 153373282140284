import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-office-list',
  templateUrl: './office-list.component.html',
  styleUrls: ['./office-list.component.css'],
})
export class OfficeListComponent implements OnInit {
  constructor() {}

  @Input()
  officePlace!: boolean;

  ngOnInit(): void {
    this.officePlace = true;
  }
}
