<div
  class="form-group animate"
  [ngStyle]="{ 'transition-delay': delay }"
  [ngClass]="{ focused: isFocused }"
>
  <ng-content select="oca-placeholder"></ng-content>
  <input
    #input
    class="form-control"
    [type]="type"
    [value]="value"
    [maxlength]="maxlength"
    (focus)="onFocus()"
    (focusout)="onFocusOut()"
    (change)="onChanged($event)"
    [ngModel]="applyFormat ? formattedValue : value"
    (ngModelChange)="ciEventModel($event, input)"
    (keydown)="onKeyDown($event)"
    (input)="onInput($event)"
    required
    [ngClass]="(error == true) ? 'errorBorder' : (value.length > 0) ? 'focused' :'noFocused'"
  />
  <ng-content select="oca-hint"></ng-content>
  <ng-content select="oca-error"></ng-content>
</div>
