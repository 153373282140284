import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormHandlerService } from '../../services/form-handler.service';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  @ViewChild('accept', {static: true}) acceptButton!: ElementRef<HTMLInputElement>;
  @ViewChild('deny', {static: true}) denyButton!: ElementRef<HTMLInputElement>;
  value: number = 185;
  acceptOption = false;
  denyOption = false;
  firstTime = false;
  warning = false;
  modal = false;
  insuranceStatus?: boolean;
  constructor(
    private formService: FormHandlerService,
    ) { }

  ngOnInit(): void {
    this.formService.getLocalStorageData();
    this.setInsurance(this.formService.getInsurance());
  }

  setInsurance(value?: boolean) {
    const value1 = value != undefined ? value : false;
    const value2 = value != undefined ? !value : false;
    this.insuranceStatus = value;
    this.acceptOption = value1;
    this.denyOption = value2;
    this.acceptButton.nativeElement.checked = value1;
    this.denyButton.nativeElement.checked = value2;
    this.formService.firstTimeInsurance = false;
    this.formService.setInsurance(value);
    this.showWarning(false);
  }

  next() {
    if (this.insuranceStatus != null) {
      this.formService.setInsurance(this.insuranceStatus);
      this.formService.performSubNavigationTo('confirmData');
    }
    else {
      this.showWarning(true);
    }
  }

  showWarning(value: boolean) {
    this.warning = value;
  }

  showModal(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.modal = true;
  }

  closeModal() {
    this.modal = false;
  }

}
