import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { FormHandlerService } from '../../services/form-handler.service';
import { AnalyticsService, AnalyticsPages } from 'src/solicitud-online/analytics.service';
import { ApiService } from 'src/solicitud-online/services/api.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-identification-card-traditional',
  templateUrl: './identification-card-traditional.component.html',
  styleUrls: ['./identification-card-traditional.component.css'],
})
export class IdentificationCardTraditionalComponent implements OnInit {
  constructor(
    private router: Router,
    private formService: FormHandlerService,
    private analytics: AnalyticsService,
    private apiService: ApiService
  ) {}

  traditionalCardData!: FormGroup;
  textErrorSerieDocumento: string ='';
  textErrorNumeroFolio: string ='';
  errorSerieDocumento: boolean = false;
  errorFolio: boolean = false;

  ngOnInit(): void {
    this.traditionalCardData = new FormGroup({
      serialNumber: new FormControl('', [Validators.required, Validators.maxLength(1)]),
      folioNumber: new FormControl('', [Validators.required, Validators.maxLength(6)]),
    });
    if (this.formService.userIdentificationCard.serialNumber != null) {
      let dataRequest = this.formService.getIdentificationCardData();
      var { serialNumber, invoiceNumber } = dataRequest;
      this.traditionalCardData.patchValue({
        serialNumber: serialNumber,
        folioNumber: invoiceNumber
      });
    }
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.DatosCedulaTradicional)
  }

  saveTraditionalCardData(form: any) {
    if (this.traditionalCardData.valid) {
      var cardData = this.getTraditionalCardData(form);
      this.formService.traditionalCardData(cardData);
      this.apiService.apiPatch(this.buildBodyCardTradicional());
    } else {
      this.getErrors();
    }
    if(this.serialNumber.invalid) {
      this.errorSerieDocumento = true
    }
    if(this.folioNumber.invalid) {
      this.errorFolio = true
    }
  }

  getTraditionalCardData(form: any) {
    let userData: any = {
      serialNumber: form.serialNumber,
      folioNumber: form.folioNumber,
    };

    return userData;
  }

  get serialNumber(): AbstractControl {
    return this.traditionalCardData.controls.serialNumber;
  }

  get folioNumber(): AbstractControl {
    return this.traditionalCardData.controls.folioNumber;
  }

  getErrors() {
    if(this.serialNumber.invalid) {
      this.textErrorSerieDocumento = 'Debe ingresar la serie del documento'
    }

    if(this.folioNumber.invalid) {
      this.textErrorNumeroFolio = 'Debe ingresar el número de folio'
    }
  }

  buildBodyCardTradicional() {
    return {
      CurrentScreen: "PANTALLA-3",
      IsEndFlow: false
    }
  }
}
