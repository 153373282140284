/**
 * Specification for OCA Card E2E
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Datos del trabajo del usuario
 */
export interface UserJob { 
    /**
     * Ocupación del usuario. Valores posibles:   * C  = Comercio   * E  = Empleado   * I  = Independiente   * J  = Jubilado/pensionista   * P  = Profesional 
     */
    category?: string;
    /**
     * Ingresos mensuales del usuario
     */
    monthlyIncome?: string;
}