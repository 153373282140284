    <div>
    <img [src]="image" [alt]="alternative" class="vitrina-oca">
    <ul class="list-icon mt-4">
        <li class="list-icon__item">
            <div><img src="assets/images/icons/comercios.svg" class="list-icon__icon"></div>
            Beneficios y promociones todos los días
        </li>
        <li class="list-icon__item">
            <div><img src="assets/images/icons/telepeaje.svg" class="list-icon__icon"></div>
            Adherí tu telepeaje
        </li>
        <li class="list-icon__item">
            <div><img src="assets/images/icons/entradas.svg" class="list-icon__icon"></div>
            Financiá tus compras
        </li>
        <li class="list-icon__item">
            <div><img src="assets/images/icons/beneficios.svg" class="list-icon__icon"></div>
            Internacional para compras presenciales o por web
        </li>
    </ul>

    <a href="https://www.oca.com.uy/" target="_blank" class="link-conoce-mas d-inline-flex mt-4">Conocé más beneficios acá</a>
</div>