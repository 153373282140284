import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { AnalyticsPages, AnalyticsService } from 'src/solicitud-online/analytics.service';
import { UserCivilStatusType, UserJobCategory } from 'src/solicitud-online/services/api-enums.model';
import { ApiService } from 'src/solicitud-online/services/api.service';
import { cardRequest } from '../../models/user';
import { FormHandlerService } from '../../services/form-handler.service';

declare var analyticsLib: any;

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css'],
})
export class EmploymentComponent implements OnInit {
  constructor(private formService: FormHandlerService, private analytics: AnalyticsService, private apiService:ApiService) {}

  employmentData!: FormGroup;
  showMonthlyEarningForm: Boolean = false;
  monthlyEarningError: string = '';
  civilStatus?: string = '';
  textError: string = '';
  error: boolean = false;

  userJobCategory = UserJobCategory;

  ngOnInit(): void {
    this.employmentData = new FormGroup({
      employment: new FormControl('', Validators.required),
      monthlyEarning: new FormControl('', [Validators.required]),
    });
    this.showMonthlyEarningForm = false;
    this.formService.getLocalStorageData();
    if (this.formService.userJob.category != null) {
      let dataRequest = this.formService.getUserJobData();
      var { category, monthlyIncome } = dataRequest;
      this.employmentData.patchValue({
        employment: category,
        monthlyEarning: monthlyIncome
      });
      this.showMonthlyEarningForm = true;
    }

    this.civilStatus = this.formService.getCivilStatusData().type;
    this.analytics.updateAnalytics(analyticsLib, AnalyticsPages.DatosOcupacion)
  }

  saveEmployment(form: any) {
    if (this.employmentData.valid || (this.employment.valid && this.showMonthlyEarningForm == false)) {
      if((Number(this.monthlyEarning.value.replaceAll('.', ''))) < 15000) {
        this.error = true
        this.monthlyEarningError = `*Ingresos mensuales mínimos: $15.000 líquidos\nOCA SA se reserva el derecho de solicitar comprobante de ingresos`;
        this.monthlyEarningError = this.monthlyEarningError.split("\n").join("<br />");
      } else {
        var employment: cardRequest;
        employment = this.getemploymentData(form);
        this.formService.employmentData(employment);
        this.apiService.apiPatch(this.buildBodyEmployment());
      }
    } else {
      this.getMonthlyEarningError();
    }
  }

  private getemploymentData(form: any): cardRequest {
    let userData: any = {
      employment: form.employment,
      monthlyEarning: form.monthlyEarning,
    };
    return userData;
  }

  showMonthlyEarning() {
    this.showMonthlyEarningForm = true;
  }

  _keyPress(event: any) {
    var keynum = event ? event.keyCode : event.which;
    return /\d/.test(String.fromCharCode(keynum));
  }

  get employment(): AbstractControl {
    return this.employmentData.controls.employment;
  }

  get monthlyEarning(): AbstractControl {
    return this.employmentData.controls.monthlyEarning;
  }

  getMonthlyEarningError() {
    this.error = true
    if(this.employment.invalid) {
      this.textError = "Debe ingresar su profesión";
    }
    if(this.monthlyEarning.invalid) {
      this.monthlyEarningError = "Debe indicar sus ingresos mensuales";
    }
  }

  buildBodyEmployment() {
    return {
      CurrentScreen: "PANTALLA-5",
      IsEndFlow: false
    }
  }
}
