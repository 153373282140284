<div class="tooltip-container">
    <div class="tooltip-icon" (click)="toggle()">
        <i class="icon-warn"></i>
    </div>
    <div class="alert-container" *ngIf="show">
        <div class="tooltip-triangle"></div>
        <div class="tootip-body" [ngClass]="{'mobile1': mobile1, 'mobile2': mobile2}">
            <div class="tooltip-message">
                Verificá la información proporcionada
                <br>
                para que procesemos correctamente tu solicitud.
            </div>
        </div>
    </div>
</div>