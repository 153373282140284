/**
 * Specification for OCA Card E2E
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Datos de la cédula de identidad del usuario
 */
export interface UserIdentificationCard { 
    /**
     * Número de documento del cliente
     */
    number?: string;
    /**
     * Tipo de Documento del cliente. Valores posibles: * 0 = Cédula tradicional * 1 = Cédula con chip 
     */
    type?: string;
    /**
     * Código de seguridad de la cédula con chip
     */
    securityCode?: string;
    /**
     * Número de serie de la cédula con tradicional
     */
    serialNumber?: string;
    /**
     * Número de folio de la cédula con tradicional
     */
    invoiceNumber?: string;
}