export enum DeliveryMethod {
  Domicilio = 'D',
  OtraDireccion = 'O',
  RetirarPersonalmente = 'P',
}

export const DeliveryMethodDescription = new Map()
  .set(DeliveryMethod.Domicilio, 'Domicilio')
  .set(DeliveryMethod.OtraDireccion, 'Otra dirección')
  .set(DeliveryMethod.RetirarPersonalmente, 'Retirar personalmente');

export enum UserIdentificationCardType {
  Tradicional = '0',
  ConChip = '1',
}

export const UserIdentificationCardTypeDescriptcion = new Map()
  .set(UserIdentificationCardType.Tradicional, 'C.I. tradicional')
  .set(UserIdentificationCardType.ConChip, 'C.I. con chip');

export enum UserCivilStatusType {
  Casado = '1',
  Concubino = '2',
  Divorciado = '3',
  Viudo = '4',
  Separado = '5',
  Soltero = '10',
}

export const UserCivilStatusTypeDescription = new Map()
  .set(UserCivilStatusType.Casado, 'Casado/a')
  .set(UserCivilStatusType.Concubino, 'Concubino/a')
  .set(UserCivilStatusType.Divorciado, 'Divorciado/a')
  .set(UserCivilStatusType.Viudo, 'Viudo/a')
  .set(UserCivilStatusType.Separado, 'Separado/a')
  .set(UserCivilStatusType.Soltero, 'Soltero/a');

export enum UserJobCategory {
  Ama_De_Casa = 'A',
  Comerciante = 'C',
  Empleado_Publico = 'B',
  Empleado_Privado = 'V',
  Empresario = 'M',
  Estudiante = 'S',
  Jubilado = 'J',
  Profesional_Independiente = 'P',
  Rentista = 'R',
  Unipersonal = 'U',
  Abogado_Contador_Escribano = 'X',
  Otra_Actividad = 'O',
}

export const UserJobCategoryDescription = new Map()
  .set(UserJobCategory.Ama_De_Casa, 'Ama de Casa')
  .set(UserJobCategory.Comerciante, 'Comerciante')
  .set(UserJobCategory.Empleado_Publico, 'Empleado Público')
  .set(UserJobCategory.Empleado_Privado, 'Empleado Privado')
  .set(UserJobCategory.Empresario, 'Empresario')
  .set(UserJobCategory.Estudiante, 'Estudiante')
  .set(UserJobCategory.Jubilado, 'Jubilado')
  .set(UserJobCategory.Profesional_Independiente, 'Profesional Independiente')
  .set(UserJobCategory.Rentista, 'Rentista')
  .set(UserJobCategory.Unipersonal, 'Unipersonal')
  .set(UserJobCategory.Abogado_Contador_Escribano, 'Abogado/Contador/Escribano')
  .set(UserJobCategory.Otra_Actividad, 'Otra Actividad');

export enum CardDataFlag {
  OCA = 2,
  VISA = 3,
}

export const CardDataFlagDescription = new Map()
  .set(CardDataFlag.OCA, 'Oca')
  .set(CardDataFlag.VISA, 'Visa');
