import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SolicitudOnlineRoutingModule } from './solicitud-online-routing.module';
import { SolicitudOnlineComponent } from './solicitud-online.component';
import {NgxCaptchaModule} from 'ngx-captcha';

// Pages
import { PersonalDataComponent } from './pages/personal-data/personal-data.component';
import { HomeComponent } from './pages/home/home.component';
import { IdentificationCardTypeComponent } from './pages/identification-card-type/identification-card-type.component';
import { IdentificationCardTraditionalComponent } from './pages/identification-card-traditional/identification-card-traditional.component';
import { IdentificationCardChipComponent } from './pages/identification-card-chip/identification-card-chip.component';
import { CivilStatusComponent } from './pages/civil-status/civil-status.component';
import { EmploymentComponent } from './pages/employment/employment.component';
import { AddressComponent } from './pages/address/address.component';
import { RecibeCardComponent } from './pages/recibe-card/recibe-card.component';
import { OfficeRetirementComponent } from './pages/office-retirement/office-retirement.component';
import { OfficeRetirementOtherAddressComponent } from './pages/office-retirement-other-address/office-retirement-other-address.component';
import { ConfirmDataComponent } from './pages/confirm-data/confirm-data.component';
import { FinalSectionComponent } from './pages/final-section/final-section.component';
import { FormStaticPageComponent } from './pages/form-static-page/form-static-page.component';

// Components
import { OfficeListComponent } from './components/office-list/office-list.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { ErrorComponent } from './components/error/error.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { BackButtonArrowComponent } from './components/back-button-arrow/back-button-arrow.component';
import { OcaFormInputComponent } from './components/oca-form-input/oca-form-input.component';
import { OcaPlaceholderComponent } from './components/oca-placeholder/oca-placeholder.component';
import { OcaErrorComponent } from './components/oca-error/oca-error.component';
import { OcaHintComponent } from './components/oca-hint/oca-hint.component';
import { HeaderComponent } from './components/header/header.component';
import { ErrorSectionComponent } from './pages/error-section/error-section.component';
import { MonthlyEarningDirective } from './directives/monthly-earning.directive';
import { InsuranceComponent } from './pages/insurance/insurance.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FullScreenLoadingComponent } from './components/full-screen-loading/full-screen-loading.component';
import { MaintenanceSectionComponent } from './pages/maintenance-section/maintenance-section.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { SelectCardTypeComponent } from './pages/select-card-type/select-card-type.component';
import { SelectCardLandingComponent } from './pages/select-card-landing/select-card-landing.component';

@NgModule({
  declarations: [
    SolicitudOnlineComponent,
    PersonalDataComponent,
    HomeComponent,
    IdentificationCardTypeComponent,
    IdentificationCardTraditionalComponent,
    IdentificationCardChipComponent,
    CivilStatusComponent,
    EmploymentComponent,
    AddressComponent,
    RecibeCardComponent,
    OfficeRetirementComponent,
    OfficeRetirementOtherAddressComponent,
    ConfirmDataComponent,
    FinalSectionComponent,
    OfficeListComponent,
    FormButtonComponent,
    FormStaticPageComponent,
    ErrorComponent,
    ProgressBarComponent,
    BackButtonArrowComponent,
    OcaFormInputComponent,
    OcaPlaceholderComponent,
    OcaErrorComponent,
    OcaHintComponent,
    HeaderComponent,
    ErrorSectionComponent,
    MonthlyEarningDirective,
    InsuranceComponent,
    SpinnerComponent,
    FullScreenLoadingComponent,
    MaintenanceSectionComponent,
    TooltipComponent,
    SelectCardTypeComponent,
    SelectCardLandingComponent,
  ],
  imports: [
    CommonModule,
    SolicitudOnlineRoutingModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  exports: [SolicitudOnlineComponent],
})
export class SolicitudOnlineModule {}
