/**
 * Specification for OCA Card E2E
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.3
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Address { 
    /**
     * Departamento del domicilio
     */
    department?: string;
    /**
     * Localidad del domicilio
     */
    locality?: string;
    /**
     * Dirección del domicilio
     */
    address?: string;
    /**
     * Número de puerta del domicilio
     */
    doorNumber?: string;
    /**
     * Apartamento del domicilio
     */
    apartment?: string;
    /**
     * Torre del domicilio
     */
    tower?: string;
    /**
     * Manzana del domicilio
     */
    square?: string;
    /**
     * Solar del domicilio
     */
    plot?: string;
    /**
     * Datos extra del domicilio
     */
    extraData?: string;
}