import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cardRequest } from '../models/user';
import { Location } from '@angular/common';
import { CardsService } from '../../swagger-client/api/cards.service';
import { CreateCardOrderRequestPayload } from '../../swagger-client/model/createCardOrderRequestPayload';
import { User } from '../../swagger-client/model/user';
import { UserIdentificationCard } from '../../swagger-client/model/userIdentificationCard';
import { UserCivilStatus } from '../../swagger-client/model/userCivilStatus';
import { UserJob } from '../../swagger-client/model/userJob';
import { Address } from '../../swagger-client/model/address';
import { CardData } from '../../swagger-client/model/cardData';
import { CardDeliveryMethod } from '../../swagger-client/model/cardDeliveryMethod';
import { DeliveryMethod, UserIdentificationCardType } from './api-enums.model';
import { ResultError } from '../../swagger-client/model/resultError';
import { FormatterService } from './formatter.service';
import { DataStorerService } from './data-storer.service';
import { DataKeysEnum } from './enum/data-keys-enum';
import { AddressModel } from './model/address.model';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class FormHandlerService {
  constructor(
    private router: Router,
    private cardsService: CardsService,
    private formatterService: FormatterService,
    private dataStorerService: DataStorerService,
    private loaderService: LoaderService,
  ) {}

  maintenanceStatus?: Boolean;
  maintenanceRedirectUrl?: string;

  createCardOrderRequestPayload: CreateCardOrderRequestPayload = {};
  user: User = {};
  userIdentificationCard: UserIdentificationCard = {};
  userCivilStatus: UserCivilStatus = {};
  userJob: UserJob = {};
  address: Address = {};
  cardData: CardData = {};
  _origin: string = '';
  cardDeliveryMethod: CardDeliveryMethod = {};
  branchOffice: String = '';
  cardName: string = '';

  firstTime: boolean = true;
  firstTime2: boolean = true;
  firstTimeInsurance: boolean = true;
  captchaSiteKey: string = '';

  orderId: string = '';
  hireFinancialProtection?: boolean | undefined;

  getLocalStorageData() {
    this.userCivilStatus.type = this.dataStorerService.get(DataKeysEnum.CIVIL_STATUS_STATUS);
    this.userJob.category = this.dataStorerService.get(DataKeysEnum.EMPLOYMENT_OCCUPATION);
    this.userJob.monthlyIncome = this.dataStorerService.get(DataKeysEnum.EMPLOYMENT_INCOME);
    this.address.department = this.dataStorerService.get(DataKeysEnum.ADDRESS_DEPARTMENT);
    this.address.locality = this.dataStorerService.get(DataKeysEnum.ADDRESS_LOCALITY);
    this.address.address = this.dataStorerService.get(DataKeysEnum.ADDRESS_ADDRESS);
    this.address.doorNumber = this.dataStorerService.get(DataKeysEnum.ADDRESS_DOOR_NUMBER);
    this.address.apartment = this.dataStorerService.get(DataKeysEnum.ADDRESS_APARTMENT);
    this.address.tower = this.dataStorerService.get(DataKeysEnum.ADDRESS_TOWER);
    this.address.square = this.dataStorerService.get(DataKeysEnum.ADDRESS_SQUARE);
    this.address.plot = this.dataStorerService.get(DataKeysEnum.ADDRESS_PLOT);
    this.address.extraData = this.dataStorerService.get(DataKeysEnum.ADDRESS_EXTRA_DATA);
    this.cardDeliveryMethod.type = this.dataStorerService.get(DataKeysEnum.RECIBE_CARD_TYPE);
    this.branchOffice = this.dataStorerService.get(DataKeysEnum.OFFICE_RETIREMENT);
    this.hireFinancialProtection = this.dataStorerService.getBoolean(DataKeysEnum.INSURANCE);
    if (this.cardDeliveryMethod.type == "O") {
      const address = new AddressModel();
      address.department = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_DEPARTMENT);
      address.locality = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_LOCALITY);
      address.address = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_ADDRESS);
      address.doorNumber = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_DOOR_NUMBER);
      address.apartment = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_APARTMENT);
      address.tower = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_TOWER);
      address.square = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_SQUARE);
      address.plot = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_PLOT);
      address.extraData = this.dataStorerService.get(DataKeysEnum.OTHER_RETIREMENT_EXTRA_DATA);
      this.cardDeliveryMethod.deliveryAddress = address;
    }
  }

  cardType(cardTypeData: any) {
    this.cardData.flag = cardTypeData;
  }

  set origin(value: string) {
    this._origin = value;
  }

  personalDataHandler(form: any) {
    this.user.fullName = form.name_lastName;
    this.user.email = form.email;
    this.user.phone = form.phone;
    this.performSubNavigationTo('identificationCardType');
  }

  //identificationCard
  identificationCardType(form: any) {
    this.userIdentificationCard.type = form.identificationCardType;
    this.userIdentificationCard.number = form.dni;
    if (form.identificationCardType == UserIdentificationCardType.Tradicional) {
      this.performSubNavigationTo('traditionalCard');
    } else {
      this.performSubNavigationTo('chipCard');
    }
  }

  traditionalCardData(form: any) {
    this.userIdentificationCard.serialNumber = form.serialNumber;
    this.userIdentificationCard.invoiceNumber = form.folioNumber;
    this.performSubNavigationTo('civilStatus');
  }

  chipCardData(form: any) {
    this.userIdentificationCard.securityCode = form.securityCode;
    this.performSubNavigationTo('civilStatus');
  }

  civilStatusData(form: any) {
    this.userCivilStatus.type = form.civilStatus;
    this.userCivilStatus.spouseIdentificationCard = form.dniSpouse;
    if (
      this.userCivilStatus.type == 'Soltero/a' ||
      this.userCivilStatus.type == 'Separado/a' ||
      this.userCivilStatus.type == 'Divorciado/a' ||
      this.userCivilStatus.type == 'Viudo/a'
    ) {
      this.userCivilStatus.spouseIdentificationCard = '';
    }
    this.dataStorerService.set(this.userCivilStatus.type, DataKeysEnum.CIVIL_STATUS_STATUS);
    this.performSubNavigationTo('employment');
  }

  employmentData(form: any) {
    this.userJob.category = form.employment;
    this.userJob.monthlyIncome = form.monthlyEarning
      .toString()
      .replaceAll('.', '');
    this.dataStorerService.set(this.userJob.category, DataKeysEnum.EMPLOYMENT_OCCUPATION);
    this.dataStorerService.set(this.userJob.monthlyIncome, DataKeysEnum.EMPLOYMENT_INCOME);
    this.performSubNavigationTo('address');
  }

  addressDataHandler(form: any) {
    this.address.department = form.departament;
    this.address.locality = form.locality;
    this.address.address = form.address_direction;
    this.address.doorNumber = form.address_door_number;
    this.address.apartment = form.address_apartment;
    this.address.tower = form.address_tower;
    this.address.square = form.address_square;
    this.address.plot = form.address_plot;
    this.address.extraData = form.address_extra_data;
    this.dataStorerService.set(this.address.department, DataKeysEnum.ADDRESS_DEPARTMENT);
    this.dataStorerService.set(this.address.locality, DataKeysEnum.ADDRESS_LOCALITY);
    this.dataStorerService.set(this.address.address, DataKeysEnum.ADDRESS_ADDRESS);
    this.dataStorerService.set(this.address.doorNumber, DataKeysEnum.ADDRESS_DOOR_NUMBER);
    this.dataStorerService.set(this.address.apartment, DataKeysEnum.ADDRESS_APARTMENT);
    this.dataStorerService.set(this.address.tower, DataKeysEnum.ADDRESS_TOWER);
    this.dataStorerService.set(this.address.square, DataKeysEnum.ADDRESS_SQUARE);
    this.dataStorerService.set(this.address.plot, DataKeysEnum.ADDRESS_PLOT);
    this.dataStorerService.set(this.address.extraData, DataKeysEnum.ADDRESS_EXTRA_DATA);
    this.performSubNavigationTo('recibeCard');
  }

  recibeCardDataHandler(form: any) {
    this.cardDeliveryMethod.type = form.typeOfRecibe;
    this.dataStorerService.set(this.cardDeliveryMethod.type, DataKeysEnum.RECIBE_CARD_TYPE);
    switch (form.typeOfRecibe) {
      case DeliveryMethod.RetirarPersonalmente:
        this.performSubNavigationTo('officeRetirement');
        break;
      case DeliveryMethod.Domicilio:
        this.cardDeliveryMethod.deliveryAddress = this.getAddressData();
        this.checkIfOca();
        break;
      default:
        this.performSubNavigationTo('otherRetirementDirection');
        break;
    }
  }

  officeRetirementHandler(form: any) {
    this.branchOffice = form.officeRetirement;
    this.dataStorerService.set(this.branchOffice.toString(), DataKeysEnum.OFFICE_RETIREMENT);
    this.checkIfOca();
  }

  otherDirectionRetirement(form: any) {
    this.cardDeliveryMethod.deliveryAddress = {};
    this.cardDeliveryMethod.deliveryAddress.department = form.departament;
    this.cardDeliveryMethod.deliveryAddress.locality = form.locality;
    this.cardDeliveryMethod.deliveryAddress.address = form.retirement_direction;
    this.cardDeliveryMethod.deliveryAddress.doorNumber = form.retirement_door_number;
    this.cardDeliveryMethod.deliveryAddress.apartment = form.retirement_apartment;
    this.cardDeliveryMethod.deliveryAddress.tower = form.retirement_tower;
    this.cardDeliveryMethod.deliveryAddress.square = form.retirement_square;
    this.cardDeliveryMethod.deliveryAddress.plot = form.retirement_plot;
    this.cardDeliveryMethod.deliveryAddress.extraData = form.retirement_extra_data;
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.department, DataKeysEnum.OTHER_RETIREMENT_DEPARTMENT);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.locality, DataKeysEnum.OTHER_RETIREMENT_LOCALITY);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.address, DataKeysEnum.OTHER_RETIREMENT_ADDRESS);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.doorNumber, DataKeysEnum.OTHER_RETIREMENT_DOOR_NUMBER);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.apartment, DataKeysEnum.OTHER_RETIREMENT_APARTMENT);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.tower, DataKeysEnum.OTHER_RETIREMENT_TOWER);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.square, DataKeysEnum.OTHER_RETIREMENT_SQUARE);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.plot, DataKeysEnum.OTHER_RETIREMENT_PLOT);
    this.dataStorerService.set(this.cardDeliveryMethod.deliveryAddress.extraData, DataKeysEnum.OTHER_RETIREMENT_EXTRA_DATA);
    this.checkIfOca();
  }

  setRecaptchaCode(value: string) {
    this.createCardOrderRequestPayload.recaptchaResponse = value;
  }

  sendRequestCompleteData() {
    this.loaderService.enable();
    this.userIdentificationCard.number = this.formatterService.unformatCi(this.userIdentificationCard.number);
    this.userCivilStatus.spouseIdentificationCard = this.formatterService.unformatCi(this.userCivilStatus.spouseIdentificationCard);
    this.user.phone = this.formatterService.unformatPhone(this.user.phone);
    this.createCardOrderRequestPayload.user = this.user;
    this.createCardOrderRequestPayload.cardData = this.cardData;
    this.createCardOrderRequestPayload.user.identificationCard =
      this.userIdentificationCard;
    this.createCardOrderRequestPayload.user.civilStatus = this.userCivilStatus;
    this.createCardOrderRequestPayload.user.job = this.userJob;
    this.createCardOrderRequestPayload.user.residenceAddress = this.address;
    this.createCardOrderRequestPayload.user.hireFinancialProtection = this.hireFinancialProtection;
    this.createCardOrderRequestPayload.deliveryMethod = this.cardDeliveryMethod;

    this.cardsService
      .orderCard(this.createCardOrderRequestPayload, this._origin)
      .subscribe(
        (res) => {
          if (res.result != undefined) {
            let response = <ResultError> res.result;
            if (response.detail != undefined) {
              this.setOrderId(response.detail);
            }
          }
          this.loaderService.disable();
          this.router.navigate(['finalSection']);
        },
        (err) => {
          this.loaderService.disable();
          this.router.navigate(['errorSection']);
        }
      );
  }

  performSubNavigationTo(url: string) {
    this.router.navigate([
      'solicitud',
      {
        outlets: {
          solicitud: url,
        },
      },
    ]);
  }

  getCardData() {
    return this.cardData;
  }

  getUserData() {
    return this.user;
  }

  getIdentificationCardData() {
    return this.userIdentificationCard;
  }

  getCivilStatusData() {
    return this.userCivilStatus;
  }

  getUserJobData() {
    return this.userJob;
  }

  getFullName() {
    return this.user.fullName;
  }

  getFirstTime() {
    return this.firstTime;
  }

  getOfficeRetirement() {
    return this.branchOffice;
  }

  setFirstTime(first: boolean) {
    this.firstTime = first;
  }

  getFirstTime2() {
    return this.firstTime2;
  }

  setFirstTime2(first: boolean) {
    this.firstTime2 = first;
  }

  getAddressData() {
    return this.address;
  }

  getDeliveryMethod() {
    return this.cardDeliveryMethod;
  }

  setCaptchaSiteKey(value: string) {
    this.captchaSiteKey = value;
  }

  getCaptchaSiteKey(): string {
    return this.captchaSiteKey;
  }

  setOrderId(value: string) {
    this.orderId = value;
  }

  getOrderId(): string {
    return this.orderId;
  }

  setCardName(name: string) {
    this.cardName = name;
  }

  getCardName(): string {
    return this.cardName;
  }

  setInsurance(value: boolean | undefined) {
    this.dataStorerService.setBoolean(DataKeysEnum.INSURANCE, value);
    this.hireFinancialProtection = value;
  }

  getInsurance() {
    return this.hireFinancialProtection;
  }

  isOca(): boolean {
    if (this.cardData.flag && (this.cardData.flag as any) == 2) {
      return true;
    } else {
      return false;
    }
  }

  private checkIfOca() {
    if (this.isOca()) {
      this.performSubNavigationTo('insurance');
    }
    else {
      this.dataStorerService.clear(DataKeysEnum.INSURANCE);
      this.hireFinancialProtection = undefined;
      this.performSubNavigationTo('confirmData');
    }
  }
}
